import { QuestionEntry } from "../communication/interface";

const updateQuestionEntryById = (questionEntry: QuestionEntry, update: QuestionEntry): QuestionEntry => {

    // no entries? no update!
    if (!questionEntry.entries) {
        return questionEntry;
    }

    // check for equal entryId
    const updatedEntries = questionEntry.entries.map((qe) => {

        if (qe.entryId === update.entryId) { return update; }

        if (qe.entries) { return updateQuestionEntryById(qe, update); }

        return qe;
        
    })

    // return entry with updated entryList
    return {...questionEntry, entries: updatedEntries};

}

const sanitizeQuestionnaireArray = (array: (QuestionEntry | null)[]): QuestionEntry[] => {
    const sanitizedArray = array.filter(question => {
        if (question === null) return false;
        else if (question.entries) return sanitizeQuestionnaireArray(question.entries);
        return true;
    }) as QuestionEntry[]
    return sanitizedArray ;
}

const deleteQuestionEntryById = (questionEntry: QuestionEntry, entryId: number): QuestionEntry =>  {

    // check for entries
    if (!questionEntry.entries) {
        return questionEntry;
    }

    // create new entry list (without 'entryId')
    const entryList: QuestionEntry[] = [];
    for (let entry of questionEntry.entries) {
        if (entry.entryId !== entryId) {
            entry = deleteQuestionEntryById(entry, entryId);
            entryList.push(entry);
        }
    }

    // return (modified) question entry
    return { ...questionEntry, entries: entryList };

}

export {
    updateQuestionEntryById,
    deleteQuestionEntryById,
    sanitizeQuestionnaireArray
}