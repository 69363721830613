/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useState} from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { CatalogEntry } from "../../communication/interface";
import { Input, Title } from "../baseStyles";
import Button from "../Button";
import DropDown from "../DropDown/DropDown";
import { Container, ContentContainer, ButtonContainer, Label } from './components';
import { validEntryTypes, getEntryType } from "../../data/validEntryTypes";


type Props = {
    question: CatalogEntry
    onSave: (question: CatalogEntry) => void;
    onCancel: () => void;
} & WithTranslation

const EditQuestionModal = (props: Props) => {

    const {question, onSave, onCancel, t} = props;

    const [input, setInput] = useState(question.text)
    const [value, setValue] = useState( t("questionType." + question.entryType) as string);

    const questionTypes = validEntryTypes.map<string>( (entry) => t("questionType." + entry) );

    return (
    <Container>
        <Title>{t('catalog.modal.edit.title')}</Title>
        <ContentContainer>
            <Label>{t("catalog.modal.edit.questionText")}</Label>
            <Input value={input} type="textarea" onChange={(e) => setInput(e.currentTarget.value)}/>
            <Label>{t("catalog.modal.edit.questionType")}</Label>
            <DropDown value={value} options={questionTypes} onChange={setValue}/>
        </ContentContainer>
        <ButtonContainer>
        <Button secondary onClick={onCancel}>{t('button.dismiss')}</Button>
        <Button onClick={() => onSave({...question, text: input, entryType: getEntryType(value)})}>{t('button.save')}</Button>
        </ButtonContainer>
    </Container>
    )
};

export default withTranslation()(EditQuestionModal);