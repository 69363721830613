import styled from 'styled-components'

const BackgroundPrimary = styled.div`
    min-width: 10px;
    height: inherit;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: ${props => props.theme.colors.backgroundMain};
`;

export default BackgroundPrimary;
