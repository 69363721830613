import React, { useContext, useState, useEffect } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import styled from "styled-components";
import { Page } from "../types";
import { credentialValidator, verifyMail } from '../helpers/inputValidatior';
import { ReactComponent as Logo } from '../assets/icons/Surveybot_CMYK.svg';
import { AppContext } from "../AppContext";
import Icon from "../components/Icon";
import { Link, useNavigate } from "react-router-dom";
import { AccountLoginResponse, AccountRequestPasswordRequest } from "../communication/interface";


const Background = styled.div`
    box-sizing: border-box;
    height: inherit;
    width: inherit;
    background-color: ${props => props.theme.colors.backgroundMain};
    padding: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const TopFrame = styled.div`
    flex: 0 1 0;
    display: flex;
    flex-direction: column;
`

const LogoRow = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: start;
`

const TitleRow = styled.div`
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px 80px 10px;
`

const FrameBottom = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Title = styled.h1`
    flex: 0 0 auto;
    /* font-size: 80px; */
    font-weight: 700;
    text-align: center;
    padding: 10px 0;
    margin: 0;
`

const SubTitle = styled.h2`
    flex: 0 0 auto;
    /* font-size: 50px; */
    font-weight: 500;
    margin: 0;
    /* padding: 20px 0; */
`

interface PageColumnProps {
    show: boolean,
}
const LoginColumn = styled.div<PageColumnProps>`
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: column;
    background-color: ${props => props.theme.colors.white};
    padding: 30px 20px;
    min-width: 300px;

    border-radius: 10px;
    box-shadow: 0 0 6px rgba(0,0,0,0.4);

`

const LoginTitle = styled.div`

    font-size: 24px;
    font-weight: 600;
    color: ${props => props.theme.colors.main};
    text-align: center;
    padding-bottom: 30px;

`

const ResetPasswordColumn = styled.div<PageColumnProps>`
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: column;
`

interface InputProps {
    empty: boolean;
    error?: boolean;
}
const TextInput = styled.input<InputProps>`
    all: unset;

    flex: 1 1 auto;
    
    /* border: solid 1px ${props => props.error ? props.theme.colors.errorRed : props.theme.colors.lightGrey};
    border-radius: 10px; */
    color: ${props => props.empty ? props.error ? props.theme.colors.errorLightRed : props.theme.colors.grey : props.theme.colors.black};

    /* padding: 5px 0 5px 15px;  */
    padding: 0 5px;
    /* margin: 5px 0;  */

`

interface InputBorderProps {
    error?: boolean;
    focused: boolean;
}
const InputBorder = styled.div<InputBorderProps>`
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    border: solid 1px ${props => !props.focused ? props.theme.colors.lightGrey : props.error ? props.theme.colors.errorLightRed : props.theme.colors.main};
    border-radius: 10px;
    padding: 5px 0 5px 15px; 
    margin: 5px 0; 

    ${ props => props.focused ? "box-shadow: 0 0 3px rgba(0,0,0,0.3)" : ""};

`

const ColumnElement = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    padding: 2px 0;
`

interface ErrorMessageFrameProps {
    show: boolean;
}
const ErrorMessageFrame = styled.div<ErrorMessageFrameProps>`
    position: absolute;
    left: 100%;
    padding-left: 20px; 
    color: ${props => props.theme.colors.errorRed};
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: max-content;
    height: 100%;
`

const ErrorMessageText = styled.div`
    flex: 1 1 auto;
`
const ErrorMessageIcon = styled(Icon)`
    color: ${props => props.theme.colors.errorRed};
    padding: 0 5px; 
    cursor: default;
    &:hover {
        color: ${props => props.theme.colors.errorRed};
    }
`

const StayLoggedInFrame = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 0 20px 0;
    cursor: default;
`

const Checkbox = styled.input`
    flex: 0 1 auto;
`
const CheckboxText = styled.div`
    flex: 0 1 auto;
    color: ${props => props.theme.colors.darkGrey};
    padding-left: 5px;
`

const LoginError = styled.div`
    text-align: center;
    padding: 10px 0 20px 0;
    color: ${props => props.theme.colors.errorRed};
`

const SubmitButton = styled.button`
    all: unset;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    margin: 20px 0 0 0;  
    cursor: pointer;  
    
    border-radius: 100px;
    background-color: ${props => props.theme.colors.main};
    color: ${props => props.theme.colors.white};

    &:hover {
        filter: brightness(1.1);
    }

    &:focus {
        outline: solid 1px ${props => props.theme.colors.light};
    }
` 

interface SwitchPageStateProps {
    right?: boolean,
}
const SwitchPageState = styled.div<SwitchPageStateProps>`
    text-align: ${props => props.right ? 'right' : 'left'};
    color: ${props => props.theme.colors.main};
    font-size: 14px;
    cursor: default;

    &:hover {
        text-decoration-line: underline;
    }
`

const PageStateLink = styled(Link)`
    all: unset;
`

const InformationText = styled.div`
    padding: 10px;
`

interface WarningTextProps {
    show: boolean;
}
const WarningText = styled.div<WarningTextProps>`
    display: ${props => props.show ? 'inline-block' : 'none'};
    text-align: center;
    color: ${props => props.theme.colors.errorRed};
`

const SeeIcon = styled(Icon)`

    padding: 0 5px;
    color: ${props => props.theme.colors.grey};
    cursor: pointer;
    &:hover {
        color: ${props => props.theme.colors.main};
    }

`



interface ErrorObject {
    field: string,      // email, password, login, ...
    message: string,
}


// eslint-disable-next-line @typescript-eslint/no-empty-interface

type Props = {
    pageHandler: (page: Page) => void;
} & WithTranslation;

function Login(props: Props): JSX.Element {

    const [pageState, setPageState] = useState<string>('login') // login, register, reset, resetSuccess, resetFailure
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [stayLoggedIn, setStayLoggedIn] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<ErrorObject>({
        field: '',
        message: '',
    })
    const [passwordRequestError, setPasswordRequestError] = useState<string>('')
    const [loginError, setLoginError] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [focusedInput, setFocusedInput] = useState<string>('none') // email, password, none

    const context = useContext(AppContext)

    const { t } = props;
    const navigate = useNavigate()

    useEffect(() => {

        // context.user.setLogin
        context?.user.checkToken()
            .then( (result: boolean) => {
                if(result) {
                    console.log("token is returned as " + String(result))
                    handleNavigate()
                }
            })
            .catch( () => {
                // do nothing
            })

    }, [context])

    // detect if Enter is pressed
    useEffect(() => {
        const keyDownHandler = (e: KeyboardEvent) => {
            if(e.key === 'Enter') {
                handleLogin()
            }
        }

        document.addEventListener('keydown', keyDownHandler)

        return() => {
            document.removeEventListener('keydown', keyDownHandler)
        }
    })

    return (
        <Background>

            <TopFrame>
                <LogoRow>
                    <Logo />
                </LogoRow>
            </TopFrame>

            <FrameBottom>
                <TitleRow>
                    <Title>{t("login.title")}</Title>
                    <SubTitle>{t("login.subtitle")}</SubTitle>
                </TitleRow>

                <LoginColumn show = {pageState === 'login'}>
                
                    <LoginTitle>{t("login.login")}</LoginTitle>

                    <ColumnElement>
                        <InputBorder focused = {focusedInput === 'email'}>
                            <TextInput 
                                name = "email"
                                type = "text"
                                empty = {email === ''}
                                placeholder = {String(t("login.email"))}
                                value = {email}
                                onChange = {handleInput}
                                error = {errorMessage.field === 'email'}
                                onFocus = {() => handleFocus('email')}
                                onBlur = {() => handleFocus('none')}
                            />
                        </InputBorder>
                        <ErrorMessageFrame show = {errorMessage.field === 'email'}>
                            <ErrorMessageIcon>error</ErrorMessageIcon>
                            <ErrorMessageText>{errorMessage.message}</ErrorMessageText>
                        </ErrorMessageFrame>
                    </ColumnElement>
                    <ColumnElement>
                        <InputBorder focused = {focusedInput === 'password'}>
                            <TextInput 
                                name = "password"
                                type = {showPassword ? "text" : "password"}
                                empty = {password === ''}
                                placeholder = {String(t("login.pass"))}
                                value = {password}
                                onChange = {handleInput}
                                error = {errorMessage.field === 'password'}
                                onFocus = {() => handleFocus('password')}
                                onBlur = {() => handleFocus('none')}
                            />
                            <SeeIcon onClick = {handlePasswordVisibility}>{showPassword ? "visibility_off" : "visibility"}</SeeIcon>
                        </InputBorder>
                        <ErrorMessageFrame show = {errorMessage.field === 'password'}>
                            <ErrorMessageIcon>error</ErrorMessageIcon>
                            <ErrorMessageText>{errorMessage.message}</ErrorMessageText>
                        </ErrorMessageFrame>
                    </ColumnElement>
                    <StayLoggedInFrame onClick = {() => handleStayLoggedIn()}>
                        <Checkbox 
                            name = "checkbox"
                            type = "checkbox"
                            checked = {stayLoggedIn}
                            onChange = {handleInput}
                            onKeyDown = {handleCheckboxKeyDown}
                        />
                        <CheckboxText>
                            {t("login.saveLogin")}
                        </CheckboxText>
                    </StayLoggedInFrame>
                    <SubmitButton onClick = {handleLogin}>
                        {t("login.submit")}
                    </SubmitButton>
                    <LoginError>
                        {loginError ? t("login.failed") : ' '}
                    </LoginError>
                    <SwitchPageState onClick = {() => handleSwitchPageState('register')}>
                        <PageStateLink
                            to = {'../register'}
                        >
                            {t("login.noAccount")}
                        </PageStateLink>
                    </SwitchPageState>
                    <SwitchPageState onClick = {() => handleSwitchPageState('reset')}>
                        {t("login.resetPassword")}
                    </SwitchPageState>
                
                </LoginColumn>

                
                <ResetPasswordColumn show = {pageState === 'reset'}>
                    <ColumnElement>
                        <InputBorder focused = {focusedInput === 'email'}>
                            <TextInput 
                                name = "email"
                                type = "text"
                                empty = {email === ''}
                                placeholder = {String(t("login.email"))}
                                value = {email}
                                onChange = {handleInput}
                                error = {showError && passwordRequestError !== ''}
                                onFocus = {() => handleFocus('email')}
                                onBlur = {() => handleFocus('none')}
                            />
                        </InputBorder>
                        <WarningText show = {showError}>
                            {t(passwordRequestError)}
                        </WarningText>
                    </ColumnElement>
                    <SubmitButton onClick = {handleResetPassword}>
                        {t("login.resetSubmit")}
                    </SubmitButton>
                </ResetPasswordColumn>
                <ResetPasswordColumn show = {pageState === 'resetSuccess'}>
                    <ColumnElement>
                        <InformationText>
                            {t("login.requestPassword.success")}
                        </InformationText>
                    </ColumnElement>
                </ResetPasswordColumn>
                <ResetPasswordColumn show = {pageState === 'resetFailure'}>
                    <ColumnElement>
                        {t(passwordRequestError)}
                    </ColumnElement>
                </ResetPasswordColumn>

            </FrameBottom>
        </Background>
    )

    function handleFocus(field: string): void {
        setFocusedInput(field)
    }

    function handleNavigate(): void {

        const lastLocation = localStorage.getItem('lastLocation')
        props.pageHandler(Page.OVERVIEW)
        const origin = lastLocation || '/SurveyBot/overview'
        navigate(origin)

    }

    function handleSwitchPageState(toState: string): void {
        setPageState(toState)
        setEmail('')
        setPassword('')
    }

    function handleStayLoggedIn(): void {
        setStayLoggedIn(!stayLoggedIn)
    }

    function handleResetPassword(): void {

        if(!verifyMail(email)) {
            setShowError(true)
            setPasswordRequestError('login.requestPassword.emailIncorrect')
        } else {
            setShowError(false)
            putAccountRequestPasswordRequest(email)
                .then((value: boolean) => {
                    if(value) {
                        console.log("reset submit")
                        setPageState("resetSuccess")
    
                    }
                    else {
                        setPageState("resetFailure")
                    }
                })
                .catch((status) => {
                    setShowError(true)
                    if(status === 400) {
                        setPasswordRequestError('login.requestPassword.emailFailure')
                    }
                    else {
                        setPasswordRequestError('login.requestPassword.failure')
                    }
                })
    
            setEmail('')
        }

    }

    function handlePasswordVisibility(): void {
        setShowPassword(!showPassword)
    }

    function handleInput(e: React.ChangeEvent<HTMLInputElement>): void {

        const target = e.currentTarget.name
        const value = e.currentTarget.value

        switch(target) {
            case 'password':
                setPassword(value)
                break;
            case 'checkbox':
                break;
            case 'email':
                setEmail(value)
                break;
        }

    }

    function handleCheckboxKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {

        if(e.key === 'Enter') {
            setStayLoggedIn(!stayLoggedIn)
        }

    }

    function handleErrorUpdate(field: string, message: string) {

        setErrorMessage({
            field: field,
            message: message,
        })

    }

    function handleLogin(): void {

        setLoginError(false)   
        try {

            if (!credentialValidator(email)) {
                // TODO: Set error messages
                handleErrorUpdate('email', 'ungültige Email')
                return
            }
            if (!validatePassword(password)) {
                // TODO: Set error messages
                handleErrorUpdate('password', 'Passwort darf nicht leer sein')
                return
            }

            context.user.setLogin('SurveyBot', email, password, stayLoggedIn)
                .then((result) => {
                    
                    if(result) {
                        console.log("logged In")
                        handleNavigate()
                    }
                    else {
                        setLoginError(true)                        
                        handleErrorUpdate('login', 'Login failed')
                        setEmail('')
                        setPassword('')
                    }
                })

        } 
        catch (error) {

            handleErrorUpdate('login', error as string)
            console.log(error);

        }
    }


    function validatePassword(password: string): boolean {

        if(password.length < 1) return false

        return true

    }

    function postAccountLoginRequest(email: string, password: string): Promise<AccountLoginResponse> {

        return new Promise<AccountLoginResponse> (
            (resolve, reject): void => {

                const request = {
                    email: email,
                    password: password,
                }

                context.restAppService.request_post('account/login', context.user.getToken(), 'text/json', JSON.stringify(request),
                    (data): void => {
                        const response: AccountLoginResponse = JSON.parse(data)
                        resolve(response)
                    },
                    (status, message): void => {
                        reject({
                            status: status,
                            message: message,
                        })
                    }

                )
            }
        )
    }

    function putAccountRequestPasswordRequest(email: string): Promise<boolean> {

        return new Promise<boolean> (
            (resolve, reject) => {

                const request: AccountRequestPasswordRequest = {
                    user: email,
                }

                context.restAppService.request_put('account/requestPassword', "noToken", 'text/json', JSON.stringify(request),
                    (): void => {
                        resolve(true)
                    },
                    (status, text): void => {
                        console.log('failure ' + status + '/' + text)
                        reject(status)
                    } 
                )

            }
        )
    }

}

export default withTranslation()(Login);