import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import BackgroundPrimary from "../containers/BackgroundPrimary";
import { MainHeadline } from "../components/baseStyles";
import FlexContainer from "../components/FlexContainer";
import ViewContainer from "../containers/ViewContainer";
import ExportFilters from "../components/ReportFilters/ExportFilters";
import CompanyHeader from "../components/CompanyHeader";

type Props = WithTranslation;


const ExportPage = (props: Props) => {

  const {t} = props;
    return (
      <BackgroundPrimary>
        <ViewContainer>
          <CompanyHeader/>
          <MainHeadline>{t('export.headline')}</MainHeadline>
          <FlexContainer direction="column">
            <ExportFilters />
          </FlexContainer>
        </ViewContainer>
      </BackgroundPrimary>
    );
  }

export default withTranslation()(ExportPage);
