import styled from "styled-components";

const BackgroundWhite = styled.div`
    flex: 1 1 auto;
    background-color: ${props => props.theme.colors.white};
    border-radius: 8px;
    min-height: 10px;
    overflow-y: auto;
`

export default BackgroundWhite;