import styled from "styled-components";


const Container = styled.div`
  border-radius: 8px;
  margin: 0 15px;
  background-color: ${props => props.theme.colors.white};
  align-self: center;
`

const ContentContainer = styled.div`
  padding: 8px 16px 16px 32px;
    width: 300px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`
const DateDisplay = styled.div`
`
const Label = styled.div`
`

const DateLabel = styled.div`
  color: #ABA7AF
`
const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 15px 0;
`

export {
    Container,
    DateDisplay,
    Label,
    ContentContainer,
    DateContainer,
    DateLabel
}