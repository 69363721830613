enum Page {
    LOGIN = 'LOGIN',
    OVERVIEW = 'OVERVIEW',
    CATALOG = 'CATALOG',
    QUESTIONNAIRE = 'QUESTIONNAIRE',
    CAMPAIGNS = 'CAMPAIGNS',
    SURVEYS = 'SURVEYS',
    REPORT = 'REPORTS',
    EXPORTS = 'EXPORTS',
    CLIENTS = 'CLIENTS',
    REQUESTPASSWORD = 'REQUESTPASSWORD',
    RESETPASSWORD = 'RESETPASSWORD',
    REGISTER = 'REGISTER',
    GREETING = 'GREETING',
    MONITOR = 'MONITOR',
  }

  type Entry = {
    page: Page;
    label: string;
}

export {
    Page
}

export type { Entry }
