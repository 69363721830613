import styled from "styled-components";

const Title = styled.h1`
  font-size: 24px;
`;

const MainHeadline = styled.h1`
  flex: 0 0 auto;
  font-size: 44px;
  margin: 16px 0 16px 0;
`;
const Subline = styled.h2`
  font-size: 18px;
`;

type ModalProps = {
  show: boolean;
};

const Modal = styled.div<ModalProps>`
  z-index: auto;
  display: ${({ show }) => (show ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalContainer = styled.div`
  position: fixed;
  width: 50%;
  height: auto;
  top: 33%;
  left: 40%;
`;

const Input = styled.input`
  all: unset;
  height: 44px;
  font-size: 14px;
  border: ${props => props.theme.borderLight};
  padding-left: 12px;

  &:focus {
    outline: solid 1px ${props => props.theme.colors.main};
  }
`;

export { Title, MainHeadline, Subline, Modal, ModalContainer, Input };
