import React from "react";
import { useTheme } from "styled-components";
import { Wrapper, Label, Input } from "./components";

export type Props = {
  key?: string;
  label: string;
  checked: boolean;
  onChangeHandler: () => void;
  onKeyPress: (input: string) => void;
};

const LabeledCheckbox = (props: Props) => {
  const { key, label, checked, onChangeHandler, onKeyPress } = props;
  const theme = useTheme();

  return (
    <Wrapper>
      <Input
        id={`${key ? key : label}-input`}
        checked={checked}
        onChange={onChangeHandler}
        onKeyPress={(event) => onKeyPress(event.key)}
        theme={theme}
        type="checkbox"
      />
      <Label htmlFor={`${key ? key : label}-input`}>{label}</Label>{" "}
    </Wrapper>
  );
};

export default LabeledCheckbox;
