import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding:0 16px;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
`
const Label = styled.label``;

const Input = styled.input<{hasError: boolean}>`
    height: 44px;
    margin: 0 7px;
    border-color: ${props => props.theme.borderLight};
    width: 100%;
`

const ErrorMessage = styled.div`
align-self: flex-end;
margin-right: 6px;
    color: ${props => props.theme.colors.errorRed}
`

export {
    Container,
    Wrapper,
    Label,
    Input,
    ErrorMessage,
}