import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { ReportResult } from "../../communication/interface";
import styled from "styled-components";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import { ReactComponent as MegaPhone } from '../../assets/icons/Megaphone.svg'

const ReportFrame = styled.div`
    
`

const ReportHeader = styled.div`
`

const ReportQuestion = styled.div`
    
`

const ReportQuestionText = styled.div`
    font-size: large;
`

const ReportGraph = styled.div`
    margin-bottom: 2em;
`

const ReportGraphBackground = styled.div`
    background-color: white;
    border: 1px solid black;
    padding: 2em 2em 1em 2em;
`

const ReportGraphText = styled.div`
    font-style: italic;
`

type NumberValue = {
    name: string,
    value: number,
}

type YesNoProps = {
    answered: number;
    answers: number[];
}
const YesNo: React.FC<YesNoProps> = (props): JSX.Element => {

    let sum = 0;
    const data: NumberValue[] = [];
    data.push( { name: 'Nein', value: props.answers[1] })
    sum += 0 * props.answers[0];
    data.push( { name: 'Ja', value: props.answers[0] })
    sum += 1 * props.answers[1];
    const avg = sum / props.answered;

    return(
        <ReportGraph>
        <ReportGraphBackground>
                <BarChart
                    width={500}
                    height={200}
                    data={data}
                    margin={{
                        top: 2,
                        right: 2,
                        left: 2,
                        bottom: 2,
                    }}
                    
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis allowDecimals={false} />
                        <Tooltip />
                        <Bar dataKey="value" fill="#808070" isAnimationActive={false} />
                    </BarChart>
        </ReportGraphBackground>
        <ReportGraphText>{avg.toString() === "NaN" ? "" : ('Durchschnitt ' + avg.toFixed(2))}</ReportGraphText>
        </ReportGraph>
    )
}

type Grade5Props = {
    answered: number;
    answers: number[];
}
const Grade5: React.FC<Grade5Props> = (props): JSX.Element => {

    const data: NumberValue[] = [];
    let sum = 0;
    for (let x = 0; x < 5; x++) {
        data.push( { name: 'Note ' + (x + 1), value: props.answers[x] })
        sum += (x + 1) * props.answers[x];
    }
    const avg = sum / props.answered;

    return(
        <ReportGraph>
        <ReportGraphBackground>
                <BarChart
                    width={500}
                    height={200}
                    data={data}
                    margin={{
                        top: 2,
                        right: 2,
                        left: 2,
                        bottom: 2,
                    }}
                    
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis allowDecimals={false} />
                        <Tooltip />
                        <Bar dataKey="value" fill="#808070" isAnimationActive={false} />
                    </BarChart>
        </ReportGraphBackground>
        <ReportGraphText>{avg.toString() === "NaN" ? "" : ('Durchschnitt ' + avg.toFixed(2))}</ReportGraphText>
        </ReportGraph>
    )
}

type Grade6Props = {
    answered: number;
    answers: number[];
}
const Grade6: React.FC<Grade6Props> = (props): JSX.Element => {

    let sum = 0;
    const data: NumberValue[] = [];
    for (let x = 0; x < 6; x++) {
        data.push( { name: 'Note ' + (x + 1), value: props.answers[x] })
        sum += (x + 1) * props.answers[x];
    }
    const avg = sum / props.answered;

    return(
        <ReportGraph>
        <ReportGraphBackground>
                <BarChart
                    width={500}
                    height={200}
                    data={data}
                    margin={{
                        top: 2,
                        right: 2,
                        left: 2,
                        bottom: 2,
                    }}
                    
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis allowDecimals={false} />
                        <Tooltip />
                        <Bar dataKey="value" fill="#808070" isAnimationActive={false} />
                    </BarChart>
        </ReportGraphBackground>
        <ReportGraphText>{avg.toString() === "NaN" ? "" : ('Durchschnitt ' + avg.toFixed(2))}</ReportGraphText>
        </ReportGraph>
    )
}

type NPSProps = {
    answered: number;
    answers: number[];
}
const NPS: React.FC<NPSProps> = (props): JSX.Element => {

    const data: NumberValue[] = [];
    for (let x = 0; x <= 10; x++) {
        data.push( { name: x.toString(), value: props.answers[x] })
    }

    let detraktoren = 0;
    let promotoren = 0;
    for (let x = 0; x <= 6; x++) {
        detraktoren += props.answers[x];
    }
    for (let x = 9; x <= 10; x++) {
        promotoren += props.answers[x];
    }
    const nps = 100.0 * (promotoren - detraktoren) / props.answered;

    return(
        <ReportGraph>
        <ReportGraphBackground>
                <BarChart
                    width={500}
                    height={200}
                    data={data}
                    margin={{
                        top: 2,
                        right: 2,
                        left: 2,
                        bottom: 2,
                    }}
                    
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis allowDecimals={false} />
                        <Tooltip />
                        <Bar dataKey="value" fill="#808070" isAnimationActive={false} />
                    </BarChart>
        </ReportGraphBackground>
        <ReportGraphText>{'NPS ' + nps.toFixed(0)}</ReportGraphText>
        </ReportGraph>
    )
}

const CommentEntry = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #f8f8f2;
    padding: 0.2em 1em;
    margin-bottom: 0.2em;
`

const CommentIcon = styled.div`
    margin-right: 1em;
    cursor: pointer;
`

const CommentText = styled.div`
`

type CommentProps = {
    answers: string[];
    handleAudioClick: (event: React.MouseEvent<HTMLSpanElement>) => void;
}

const Comment: React.FC<CommentProps> = (props): JSX.Element => {



    return (
        <ReportGraphBackground>
            {
                props.answers.map( (value, idx):JSX.Element => {

                    const sa = value.split('|');
                    let url;
                    if (window.location.port != "3000") {
                        url = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/SurveyBot/download/" + sa[0];
                    }
                    else {
                        url = "https://rz1.sympalog.net:16209/SurveyBot/download/" + sa[0];
                    }

                    return(
                        <CommentEntry>
                            <CommentIcon data-name='play' data-url={url} onClick={props.handleAudioClick}><MegaPhone /></CommentIcon>
                            <CommentText key={idx}>{sa[1]}</CommentText>
                        </CommentEntry>
                    )
                })
            }
        </ReportGraphBackground>
    )

}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
type Props = {
    setViewMode: (newMode: boolean) => void;
    result?: ReportResult;    
} & WithTranslation;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {
    playStatus: Map<string, number>;
}

class ReportView extends React.Component<Props, State> {

    private audio: HTMLAudioElement | undefined;
    private curAudio: string | undefined;

    public render = (): JSX.Element => {

        return (
            <div>
                {
                    this.props.result ?
                    <ReportFrame>
                        <ReportHeader>{this.props.result.questionaire}</ReportHeader>
                        {
                            this.props.result.questions.map( (question, idx): JSX.Element => {

                                let graph: JSX.Element;
                                switch (question.questionType) {
                                    case 'YesNo': graph = <YesNo answered={question.numberAnswers} answers={question.answers as number[]} />; break;
                                    case 'Grade5': graph = <Grade5 answered={question.numberAnswers} answers={question.answers as number[]} />; break;
                                    case 'Grade6': graph = <Grade6 answered={question.numberAnswers} answers={question.answers as number[]} />; break;
                                    case 'Comment': graph = <Comment answers={question.answers as string[]} handleAudioClick={this.handleAudioClick} />; break;
                                    case 'NPS': graph = <NPS answered={question.numberAnswers} answers={question.answers as number[]} />; break;
                                    default: graph = <ReportGraph>{question.questionType}</ReportGraph>; break;
                                }

                                return(
                                    <ReportQuestion key={idx}>
                                        <ReportQuestionText>{question.questionText}</ReportQuestionText>
                                        <ReportGraph>{graph}</ReportGraph>
                                    </ReportQuestion>
                                )
                            })
                        }
                    </ReportFrame>
                    :
                    null
                }
            </div>
        )
    }

    private handleAudioClick = (event: React.MouseEvent<HTMLSpanElement>): void => {

        const target = event.currentTarget;
        const name = target.dataset.name;
        const url = target.dataset.url;

        console.log('clicked on ' + url);

        if (name === 'play' && url) {

            // check if the same audio button was clicked before
            if (this.audio && this.curAudio && this.curAudio === url) {

                // check status playing
                if (this.state.playStatus.get(url) === 1) {

                    // set new status
                    const playStatus = new Map<string, number>();
                    // this.state.recordings.forEach(record => playStatus.set(record.url, 0));
                    playStatus.set(url, 2);
                    this.setState({ playStatus });

                    // pause audio
                    this.audio.pause();

                }
                // check status paused
                else if (this.state.playStatus.get(url) === 2) {

                    // set new status
                    const playStatus = new Map<string, number>();
                    // this.state.recordings.forEach(record => playStatus.set(record.url, 0));
                    playStatus.set(url, 1);
                    this.setState({ playStatus });

                    // restart audio
                    this.audio.play();

                }
                else {
                    console.log("this should not happen");
                }
            }
            else {

                // stop playing if player is active
                if (this.audio !== undefined) {
                    this.audio.pause();
                    this.audio = undefined;
                }

                // create audio and start playing
                this.audio = new Audio(url);
                this.audio.play();
                this.curAudio = url;

                // set play status
                const playStatus = new Map<string, number>();
                // this.state.recordings.forEach(record => playStatus.set(record.url, 0));
                playStatus.set(url ?? '', 1);
                this.setState({ playStatus });

                // set on end handler
                this.audio.onended = () => {
                    this.audio = undefined;
                    this.curAudio = undefined;
                    const playStatus = new Map<string, number>();
                    // this.state.recordings.forEach(record => playStatus.set(record.url, 0));
                    this.setState({ playStatus });
                }

            }

        }

    }

}

export default withTranslation()(ReportView);