import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 350px;
  border-radius: 8px;
  margin-right: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${props => props.theme.colors.white};
  `

const HeaderContainer = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 25px 25px 10px 25px;
`

const Title = styled.div`
  flex: 0 0 auto;
  font-size: 32px;
  width: 100%;
`
const Description = styled.div`
  flex: 1 1 auto;
  font-size: 18px;
  margin-top: 10px;
  width: 100%;
`

const CopyContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 5px;
` 

export { Container, HeaderContainer, Title, Description, CopyContainer };
