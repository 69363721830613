import React, { useContext, useRef, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import styled from "styled-components";
import { AppContext } from "../AppContext";
import { AccountRegistrationResponse, AccountRegistrationRequest } from "../communication/interface";
import { ReactComponent as Logo } from '../assets/icons/Surveybot_CMYK.svg';
import Icon from "../components/Icon";
import DropDownRestyled from "../components/DropDown/DropDownRestyled";
import { Link } from "react-router-dom";


const LogoContainer = styled.div`
    position: fixed;
    top: 2em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* right: 2em; */
    z-index: 100;
`

const RegisterFrame = styled.div`
    flex: 1 1 auto;
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const RegisterColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 900px;
    margin: 40px 20px;
`

const TitleBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0;
`

const RegisterTitle = styled.div`
    color: ${props => props.theme.colors.main};
    font-size: 24px;
`

const RegisterSubTitle = styled.div`
    padding: 20px 0 7px 0;
`

const RegisterSubTitleText = styled.div`
    padding: 10px 0;
`


const RegistrationForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    
    padding: 20px;
    // border: solid 1px ${props => props.theme.colors.grey};
    border-radius: 20px;
    // background-color: ${props => props.theme.colors.backgroundGrey};
    background-color: ${props => props.theme.colors.violetGrey};
`

const InputRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
`

const PasswordRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const PasswordColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 10px 0;
`

const PaddingRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    padding: 5px 0;
`

interface CenteredRowProps {
    hide?: boolean;
}
const CenteredRow = styled.div<CenteredRowProps>`
    display: ${props => props.hide ? 'none' : 'flex'};
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

interface CheckboxProps {
    error: boolean;
    highlight?: boolean;
}
const Checkbox = styled.input<CheckboxProps>`
    flex: 0 1 auto;
    color: ${props => props.error ? props.theme.colors.errorRed : ''};
`

interface AgbInfoTextProps {
    error?: boolean,
    highlight?: boolean,
}
const AgbInfoText = styled.div<AgbInfoTextProps>`
    font-weight: 400;
    color: ${props => props.error ? props.theme.colors.errorLightRed : props.highlight ? props.theme.colors.main : props.theme.colors.grey};
    padding: 15px 0 15px 10px;
    cursor: default;
`

const AgbLink = styled.a<AgbInfoTextProps>`
    display: inline-block;
    color: ${props => props.error ? props.theme.colors.errorLightRed : props.highlight ? props.theme.colors.main : props.theme.colors.grey};
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: ${props => props.theme.colors.main};
    }
`

const SubmitButton = styled.button`
    all: unset;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    margin-top: 15px;  
    margin-bottom: 5px;
    cursor: pointer;  
    
    border-radius: 100px;
    background-color: ${props => props.theme.colors.main};
    color: ${props => props.theme.colors.white};

    &:hover {
        filter: brightness(1.1);
    }

    &:focus {
        outline: solid 1px ${props => props.theme.colors.light};
    }
` 


const WarningText = styled.div`
    display: inline-block;
    text-align: center;
    color: ${props => props.theme.colors.errorRed};
`

interface InputProps {
    empty: boolean;
    error?: boolean;
}
const TextInput = styled.input<InputProps>`
    all: unset;
    
    flex: 1 1 auto;

    border: solid 1px ${props => props.error ? props.theme.colors.errorRed : props.theme.colors.lightGrey};
    border-radius: 10px;
    color: ${props => props.empty ? props.error ? props.theme.colors.errorLightRed : props.theme.colors.darkGrey : props.theme.colors.black};
    background-color: ${props => props.theme.colors.backgroundMain};


    padding: 5px 0 5px 15px; 
    margin: 0 10px; 

    &:focus {
        border: solid 1px ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.main};
        color: ${props => props.theme.colors.black};
        box-shadow: 0 0 3px rgba(0,0,0,0.3);
    }
`

const BorderlessTextInput = styled.input<InputProps>`
    all: unset;

    flex: 1 1 auto;
    
    /* border: solid 1px ${props => props.error ? props.theme.colors.errorRed : props.theme.colors.lightGrey};
    border-radius: 10px; */
    color: ${props => props.empty ? props.error ? props.theme.colors.errorLightRed : props.theme.colors.darkGrey : props.theme.colors.black};

    /* padding: 5px 0 5px 15px;  */
    /* margin: 5px 0;  */

`

interface InputBorderProps {
    error?: boolean;
    focused: boolean;
}
const InputBorder = styled.div<InputBorderProps>`
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    background-color: ${props => props.theme.colors.backgroundMain};
    border: solid 1px ${props => !props.focused ? props.theme.colors.lightGrey : props.error ? props.theme.colors.errorLightRed : props.theme.colors.main};
    border-radius: 10px;
    padding: 5px 0 5px 15px; 
    margin: 0 10px; 

    ${ props => props.focused ? "box-shadow: 0 0 3px rgba(0,0,0,0.3)" : ""};

`

const SeeIcon = styled(Icon)`

    padding: 0 5px;
    color: ${props => props.theme.colors.grey};
    cursor: pointer;
    &:hover {
        color: ${props => props.theme.colors.main};
    }

`

const DropDownFrame = styled.div`
    margin-left: 10px;
`

// invisible Link for navigation to different domain
const InvisibleLink = styled(Link)`
    display: none;
`


interface ErrorMessageFrameProps {
    hide?: boolean;
    accept: boolean;
    showError: boolean;
}
const ErrorMessageFrame = styled.div<ErrorMessageFrameProps>`
    left: 100%;
    color: ${props => props.accept ? props.theme.colors.acceptGreen : props.showError ? props.theme.colors.errorRed : props.theme.colors.grey};
    display: ${props => props.hide ? 'none' : 'flex'};
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: max-content;
    height: 100%;
`

const ErrorMessageText = styled.div`
    flex: 1 1 auto;
`
const ErrorMessageIcon = styled(Icon)<ErrorMessageFrameProps>`
    color: ${props => props.accept ? props.theme.colors.acceptGreen : props.showError ? props.theme.colors.errorRed : props.theme.colors.grey};
    padding: 0 5px; 
    cursor: default;
    &:hover {
        color: ${props => props.accept ? props.theme.colors.acceptGreen : props.showError ? props.theme.colors.errorRed : props.theme.colors.grey};
    }
`

type Props = Record<string,never> & WithTranslation;

function Register(props: Props): JSX.Element {
    
    const context = useContext(AppContext)
    const { t } = props;

    // reference to Link object for navigation to different domain
    const SuccLinkRef = useRef<HTMLAnchorElement>(null)

    const [company, setCompany] = useState<string>('')
    const [salutation, setSalutation] = useState<string>(String(t("register.salutation.label")))
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const emailRE = /^\S+@\S+\.\S+$/
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [agbConfirmed, setAgbConfirmed] = useState<boolean>(false)
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [focusedInput, setFocusedInput] = useState<string>('none') // none, password
    
    const [passwordHasCaptialChar, setPasswordHasCapitalChar] = useState<boolean>(false) 
    const [passwordHasLength, setPasswordHasLength] = useState<boolean>(false)
    const [passwordHasSpecialChar, setPasswordHasSpecialChar] = useState<boolean>(false)
    const [confirmPasswordEqual, setConfirmPasswordEqual] = useState<boolean>(false)
    
    const [showError, setShowError] = useState<boolean>(false)
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [responseText, setResponseText] = useState<string>('')
    const [responseStatus, setResponseStatus] = useState<number>(0)
    const [submitError, setSubmitError] = useState<string>('')

    return (
        <RegisterFrame>

            <RegisterColumn>

                <TitleBox>
                    <RegisterTitle>
                        {t("register.title")}
                    </RegisterTitle>
                    { submitted ?
                        null
                        :
                        <>
                            <RegisterSubTitle>
                                { t("register.subTitle") }
                            </RegisterSubTitle>
                            <RegisterSubTitleText>
                                { t("register.subTitleText") }
                            </RegisterSubTitleText>
                        </>
                    }
                    
                </TitleBox>

                <RegistrationForm>
                    { submitted ? 
                        <>
                            <RegisterSubTitle>
                                {responseText === 'success' ? t("register.success") : ''}
                            </RegisterSubTitle>
                            <RegisterSubTitleText>
                                {responseText === 'success' ? t("register.successInfo") : responseText}
                            </RegisterSubTitleText>

                        </>
                        :
                        <>
                            <InputRow>
                                <TextInput 
                                    name = "company"
                                    type = "text"
                                    empty = {company === ''}
                                    placeholder = {String(t("register.company"))}
                                    value = {company}
                                    onChange = {handleInput}
                                    error = {company === '' && showError}
                                />
                            </InputRow>
                            <InputRow>
                                <DropDownFrame>
                                    <DropDownRestyled
                                        value = {salutation}
                                        options = {[t("register.salutation.none"), t("register.salutation.mr"), t("register.salutation.mrs")]}
                                        onChange = {handleSelectSalutation}
                                    />
                                </DropDownFrame>
                                {/* <InputSelect placeholder = {String(t("register.contact"))} onChange = {handleSelectSalutation} empty = {salutation === 'none'}>
                                    <SelectOption selected = {salutation === 'none'} disabled>{t("register.salutation.label")}</SelectOption>
                                    <SelectOption>{t("register.salutation.none")}</SelectOption>
                                    <option>{t("register.salutation.mr")}</option>
                                    <option>{t("register.salutation.mrs")}</option>
                                </InputSelect> */}
                                <TextInput 
                                    name = "firstName"
                                    type = "text"
                                    empty = {firstName === ''}
                                    placeholder = {String(t("register.firstName"))}
                                    value = {firstName}
                                    onChange = {handleInput}
                                    error = {firstName === '' && showError}
                                />
                                <TextInput 
                                    name = "lastName"
                                    type = "text"
                                    empty = {lastName === ''}
                                    placeholder = {String(t("register.lastName"))}
                                    value = {lastName}
                                    onChange = {handleInput}
                                    error = {lastName === '' && showError}
                                />
                            </InputRow>
                            <InputRow>
                                <TextInput 
                                    name = "email"
                                    type = "text"
                                    empty = {email === ''}
                                    placeholder = {String(t("register.email"))}
                                    value = {email}
                                    onChange = {handleInput}
                                    error = {(email === '' || !emailRE.test(email)) && showError}
                                />
                            </InputRow>
                            <PasswordRow>
                                <PasswordColumn>
                                    <PaddingRow>
                                        <InputBorder focused = {focusedInput === 'password'}>
                                            <BorderlessTextInput 
                                                name = "password"
                                                type = {showPassword ? "text" : "password"}
                                                empty = {password === ''}
                                                placeholder = {String(t("register.password"))}
                                                value = {password}
                                                onChange = {handleInput}
                                                onFocus = {() => handleFocus('password')}
                                                onBlur = {() => handleFocus('none')}
                                            />
                                            <SeeIcon onClick = {handleShowPassword}>
                                                {showPassword ? 'visibility_off' : 'visibility'}
                                            </SeeIcon>
                                        </InputBorder>
                                    </PaddingRow>
                                    <PaddingRow>
                                        <TextInput 
                                            name = "confirmPassword"
                                            type = {showPassword ? "text" : "password"}
                                            empty = {confirmPassword === ''}
                                            placeholder = {String(t("register.confirmPassword"))}
                                            value = {confirmPassword}
                                            onChange = {handleInput}
                                        />
                                    </PaddingRow>
                                </PasswordColumn>
                                <PasswordColumn>
                                    <ErrorMessageFrame accept = {passwordHasCaptialChar} showError = {showError}>
                                        <ErrorMessageIcon accept = {passwordHasCaptialChar} showError = {showError}>
                                            {passwordHasCaptialChar ? 'done' : 'error'}
                                        </ErrorMessageIcon>
                                        <ErrorMessageText>{t("register.largeChar")}</ErrorMessageText>
                                    </ErrorMessageFrame>
                                    <ErrorMessageFrame accept = {passwordHasSpecialChar} showError = {showError}>
                                        <ErrorMessageIcon accept = {passwordHasSpecialChar} showError = {showError}>
                                            {passwordHasSpecialChar ? 'done' : 'error'}
                                        </ErrorMessageIcon>
                                        <ErrorMessageText>{t("register.specialChar")}</ErrorMessageText>
                                    </ErrorMessageFrame>
                                    <ErrorMessageFrame accept = {passwordHasLength} showError = {showError}>
                                        <ErrorMessageIcon accept = {passwordHasLength} showError = {showError}>
                                            {passwordHasLength ? 'done' : 'error'}
                                        </ErrorMessageIcon>
                                        <ErrorMessageText>{t("register.minLength")}</ErrorMessageText>
                                    </ErrorMessageFrame>
                                    <ErrorMessageFrame accept = {confirmPasswordEqual} showError = {showError}>
                                        <ErrorMessageIcon accept = {confirmPasswordEqual} showError = {showError}>
                                            {confirmPasswordEqual ? 'done' : 'error'}
                                        </ErrorMessageIcon>
                                        <ErrorMessageText>{confirmPasswordEqual ? t("register.identPasswords") : t("register.notIdentPasswords")}</ErrorMessageText>
                                    </ErrorMessageFrame>
                                </PasswordColumn>
                            </PasswordRow>
                        
                            <CenteredRow onClick = {() => {setAgbConfirmed(!agbConfirmed)}}>
                                <Checkbox
                                    type = "checkbox"
                                    name = "agbConfirm"
                                    checked = {agbConfirmed}
                                    onChange = {handleInput}
                                    error = {!agbConfirmed && showError}
                                />
                                <AgbInfoText onClick = {() => {setAgbConfirmed(!agbConfirmed)}} error = {!agbConfirmed && showError} highlight = {agbConfirmed}>
                                    {t("upgrade.agb.text1")}
                                    <AgbLink 
                                        target = "_blank" 
                                        href = "https://www.sympalog.de/surveybot/agb/"
                                        error = {showError && !agbConfirmed}
                                        highlight = {agbConfirmed}
                                    >{t("upgrade.agb.agb")}</AgbLink>
                                    {t("upgrade.agb.text2")}
                                    <AgbLink 
                                        target = "_blank" 
                                        href = "https://www.sympalog.de/datenschutzerklaerung/"
                                        error = {showError && !agbConfirmed}
                                        highlight = {agbConfirmed}
                                    >{t("upgrade.agb.dataPrivacy")}</AgbLink>
                                    {t("upgrade.agb.text3")}
                                </AgbInfoText>
                            </CenteredRow>

                            <CenteredRow>
                                <SubmitButton onClick = {handleClick}>
                                    {t("register.submit")}
                                </SubmitButton>
                            </CenteredRow>
                            <InvisibleLink ref = { SuccLinkRef } to = 'https://www.surveybot.de/video-start/'>testlink</InvisibleLink>

                            <CenteredRow hide = {!showError && submitError === ''}>
                                <WarningText>
                                    {submitError === '' ? t("register.incompleteForm") : submitError}
                                </WarningText>
                            </CenteredRow>
                        </>


                    }
                </RegistrationForm>

            </RegisterColumn>

            <LogoContainer>
                <Logo />
            </LogoContainer>
            
        </RegisterFrame>
    );

    function handleFocus(field: string): void {
        setFocusedInput(field)
    }

    function handleShowPassword(): void {
        setShowPassword(!showPassword)
    }

    function handleInput(event: React.ChangeEvent<HTMLInputElement>): void {

        const target = event.currentTarget;
        const name = target.name;
        const value = target.value;

        
        switch (name) {
            case 'company':
                setCompany(value) 
                break;
            case 'firstName':
                setFirstName(value)
                break;
            case 'lastName':
                setLastName(value)
                break;
            case 'email':
                setEmail(value)
                break;
            case 'password':
                setPassword(value)
                checkPassword(value)
                break;
            case 'confirmPassword':
                setConfirmPassword(value)
                checkConfirmPassword(value)
                break;
            case 'agbConfirm':
                setAgbConfirmed(!agbConfirmed)
                break;
        }

    }

    // function handleSelectSalutation(e: React.ChangeEvent<HTMLSelectElement>): void {
    function handleSelectSalutation(value: string): void {

        setSalutation(value)

    }

    function checkPassword(currentPassword: string): void {

        if(currentPassword.length >= 8) {
            setPasswordHasLength(true)
        }
        else {
            setPasswordHasLength(false)
        }


        const specialCharacters = ['#','!','"','§','$','%','&','/','(',')','=','?',';',':','-','_','*','+','{','[',']','}','<','>','|']
        let includesSpecialCharacter = false
        specialCharacters.forEach((character) => {
            if(currentPassword.includes(character)) includesSpecialCharacter = true
        })
        setPasswordHasSpecialChar(includesSpecialCharacter)

        let includesCapitalLetter = false
        for(let i = 0; i < currentPassword.length; i++) {
            const charCode = currentPassword.charCodeAt(i)
            if(charCode >= 65 && charCode <= 90) includesCapitalLetter = true
        }
        setPasswordHasCapitalChar(includesCapitalLetter)

    }

    function checkConfirmPassword(currentConfirmPassword: string): void {
        if(password === currentConfirmPassword) {
            setConfirmPasswordEqual(true)
        }
        else {
            setConfirmPasswordEqual(false)
        }
    }

    function handleClick(): void {
        
        if(
            email !== '' &&
            emailRE.test(email) &&
            company !== '' &&
            firstName !== '' &&
            lastName !== '' &&
            email !== '' &&
            passwordHasCaptialChar &&
            passwordHasLength &&
            passwordHasSpecialChar &&
            confirmPasswordEqual &&
            agbConfirmed
        ) {
            handleSubmit()            
        }
        else {
            setShowError(true)
        }

    }

    function handleSubmit() {

        console.log("submitted")
        setSubmitError('')

        postAccountRegistration()
            .then((response): void => {
                console.log(response.message)
                setSubmitted(true)
                setResponseText(response.message)
                setResponseStatus(202)
                SuccLinkRef.current?.click()
            })
            .catch((response): void => {
                if(response.status === 405) {
                    console.log("mail already registered")
                    setSubmitError(String(t("register.mailAlreadyRegistered")))
                }
                else {
                    setSubmitError(response.message)
                }
                setResponseStatus(response.status)
            })

    }


    function postAccountRegistration(): Promise<AccountRegistrationResponse> {

        return new Promise<AccountRegistrationResponse> (
            (resolve, reject): void => {

                const request: AccountRegistrationRequest = {
                    company: company,
                    salutation: salutation,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    password: password,
                }

                context.restAppService.request_post('account/registration', 'noToken', 'text/json', JSON.stringify(request),
                    (data): void => {
                        const response = {
                            message: data,
                        } as AccountRegistrationResponse
                        resolve(response)
                    },
                    (status, message): void => {
                        reject({
                            status: status,
                            message: message,
                        })
                    }
                )
            }
        )

    }

}

export default withTranslation()(Register);