import styled from "styled-components";
import theme from "../../theme";

const Container = styled.div`
  z-index: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 33%;
  left: 25%;
  background-color: ${(props) => props.theme.colors.white};
  border: ${(props) => props.theme.border};
  border-radius: 8px;
  padding: 25px;
  width: 500px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 25px;
  > * {
    &:last-child{
      margin-left: 16px;
    }
  }
`;

interface ErrorMessageProps {
  show: boolean;
}
const ErrorMessage = styled.div<ErrorMessageProps>`
  display: ${props => props.show ? 'block' : 'none'};
  padding: 10px 20px;
  color: ${props => props.theme.colors.errorRed};
  text-align: center;
`

export { Container, ButtonContainer, ErrorMessage };
