import React, { Component } from "react";
import BackgroundPrimary from "../containers/BackgroundPrimary";
import ViewContainer from "../containers/ViewContainer";
import { MainHeadline, Modal, ModalContainer } from "../components/baseStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import { AppContext } from "../AppContext";
import {
  CatalogCatalogEntriesRequest,
  CatalogCatalogEntriesResponse,
  CatalogDeleteRequest,
  CatalogDeleteResponse,
  CatalogEntry,
  CatalogSaveRequest,
  CatalogSaveResponse,
} from "../communication/interface";
import { Permission } from "../data/User";
import {
  ActionContainer,
  QuestionTable,
  TableElement,
} from "../components/QuestionnaireTable/components";
import {
  DeleteIcon,
  EditIcon,
} from "../components/QuestionnaireTable/ActionIcon";
import EditQuestionModal from "../components/EditQuestionModal/EditQuestionModal";
import DeletePromptModal from "../components/DeletePromptModal/DeletePromptModal";
import QuesitonInput from "../components/QuestionInput/QuesitonInput";
import BackgroundWhite from "../containers/BackgroundWhite";
import CompanyHeader from "../components/CompanyHeader";

type State = {
  catalogEntries: CatalogEntry[];
  selectedEntry: CatalogEntry | null;
  showEditModal: boolean;
  showDeleteModal: boolean;
};
class CatalogPage extends Component<WithTranslation, State> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  constructor(props: WithTranslation) {
    super(props);
    this.state = {
      catalogEntries: [],
      selectedEntry: null,
      showEditModal: false,
      showDeleteModal: false,
    };
  }

  componentDidMount = async () => {
    const fetchQuestions = async (clientId: number) => {
      const result = await this.getQuestions(clientId);
      this.setState({ catalogEntries: result.catalogEntries });
    };

    const permissions = this.context.user.getPermissionList();
    const mappedPermission = permissions.find(
      (permission: Permission) => permission.permissionType === "clientId"
    );
    if (mappedPermission?.permissionValue)
      await fetchQuestions(+mappedPermission?.permissionValue);
  };

  // HANDLERS
  private handleSave = async(question: CatalogEntry) => {
    try {
      const result = await this.saveQuestions(question)
      this.setState({ showEditModal: false, selectedEntry: null, catalogEntries: result.catalogEntries});

    } catch (error) {
      console.error(error);
    }
  }

  private handleDelete = async(question: CatalogEntry) => {
    try {
      const result = await this.deleteQuestion(question);
      if(!result?.success) throw new Error("DELETE FAILED");
      
       const entries = await this.getQuestions(question.clientId);
       this.setState({
         catalogEntries: entries.catalogEntries,
         showDeleteModal: false,
         selectedEntry: null,
       })
    } catch (error) {
      console.error(error);
    }
  } 
  // REQUESTS
  private getQuestions = (
    clientId: number
  ): Promise<CatalogCatalogEntriesResponse> => {
    return new Promise<CatalogCatalogEntriesResponse>((resolve, reject) => {
      // build request
      const request: CatalogCatalogEntriesRequest = {
        clientId,
      };

      // post request
      this.context.restAppService.request_post(
        "catalog/catalogEntries",
        this.context.user.getToken(),
        "text/json",
        JSON.stringify(request),
        (data: string): void => {
          const response = JSON.parse(data) as CatalogCatalogEntriesResponse;
          resolve(response);
        },
        (status: number, text: string): void => {
          reject("failure " + status + "/" + text);
        }
      );
    });
  };

  private saveQuestions = async(question: CatalogEntry) => {
    return new Promise<CatalogSaveResponse>((resolve, reject) => {
      // build request
      const request: CatalogSaveRequest = {
        clientId: question.clientId,
        catalogEntry: question
      };

      // post request
      this.context.restAppService.request_post(
        "catalog/save",
        this.context.user.getToken(),
        "text/json",
        JSON.stringify(request),
        (data: string): void => {
          const response = JSON.parse(data) as CatalogSaveResponse;
          resolve(response);
        },
        (status: number, text: string): void => {
          reject("failure " + status + "/" + text);
        }
      );
    });

  };
  private deleteQuestion = (question: CatalogEntry) => {
    const newQuestionList = [...this.state.catalogEntries];
    const index = newQuestionList.findIndex(
      (el) => question.catalogId === el.catalogId
    );

    newQuestionList.splice(index, 1);

    try {
      return new Promise<CatalogDeleteResponse>((resolve, reject) => {
        // build request
        const request: CatalogDeleteRequest = {
          clientId: question.clientId,
          catalogId: question.catalogId
        };
  
        // post request
        this.context.restAppService.request_post(
          "catalog/delete",
          this.context.user.getToken(),
          "text/json",
          JSON.stringify(request),
          (data: string): void => {
            const response = JSON.parse(data) as CatalogDeleteResponse;
            resolve(response);
          },
          (status: number, text: string): void => {
            reject("failure " + status + "/" + text);
          }
        );
      });
    } catch (error) {
      console.error(error);
    }

    this.setState({
      catalogEntries: [...newQuestionList],
      showDeleteModal: false,
      selectedEntry: null,
    });
  };
  render(): React.ReactNode {
    const { catalogEntries, selectedEntry, showEditModal, showDeleteModal } =
      this.state;
    const { t } = this.props;
    return (
      <>
        <BackgroundPrimary>
          <ViewContainer>
            <CompanyHeader/>
            <MainHeadline>{t("catalog.headline")}</MainHeadline>
            <BackgroundWhite>
            {catalogEntries.length > 0 ? (
              <>
                <QuestionTable>
                  <TableElement isHeader isText>
                    {t("table.question")}
                  </TableElement>
                  <TableElement isHeader>{t("table.entryType")}</TableElement>
                  <TableElement isHeader>{t("table.actions")}</TableElement>
                  {catalogEntries.sort((a,b) => a.text.localeCompare(b.text)).map((entry) => (
                    <>
                      <TableElement
                        isText
                        onClick={() =>
                          this.setState({
                            selectedEntry: entry,
                            showEditModal: true,
                          })
                        }
                      >
                        {entry.text}
                      </TableElement>
                      <TableElement>{t("questionType." + entry.entryType)}</TableElement>
                      <TableElement>
                        <ActionContainer>
                        <EditIcon
                          onClick={() =>
                            this.setState({
                              selectedEntry: entry,
                              showEditModal: true,
                              showDeleteModal: false,
                            })
                          }
                          />
                        <DeleteIcon
                          onClick={() =>
                            this.setState({
                              selectedEntry: entry,
                              showDeleteModal: true,
                              showEditModal: false,
                            })
                          }
                          />
                          </ActionContainer>
                      </TableElement>
                    </>
                  ))}
                </QuestionTable>
                <QuesitonInput
                  clientId={this.state.catalogEntries[0].clientId}
                  onAdd={this.handleSave}
                />
                </>
            ) : null}
              </BackgroundWhite>
            {selectedEntry && (
              <ModalContainer>
                <Modal show={showEditModal}>
                  <EditQuestionModal
                    question={selectedEntry}
                    onSave={this.handleSave}
                    onCancel={() =>
                      this.setState({
                        selectedEntry: null,
                        showEditModal: false,
                      })
                    }
                  />
                </Modal>
              </ModalContainer>
            )}
            {selectedEntry && (
              <ModalContainer>
                <Modal show={showDeleteModal}>
                  <DeletePromptModal
                    question={t("catalog.modal.delete.title")}
                    onDelete={() => this.handleDelete(selectedEntry)}
                    onCancel={() =>
                      this.setState({
                        selectedEntry: null,
                        showEditModal: false,
                      })
                    }
                  />
                </Modal>
              </ModalContainer>
            )}
          </ViewContainer>
        </BackgroundPrimary>
      </>
    );
  }
}

export default withTranslation()(CatalogPage);
