import styled from 'styled-components';

interface Props {
  hover?: boolean;
}
const Icon = styled.div<Props>`

  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  color: ${props => props.theme.colors.darkGrey};
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  cursor: ${props => props.hover ? 'pointer' : 'default' };

  filter: opacity(0.6);

  &:hover {
    filter: opacity(${props => props.hover ? 1 : 0.6});
  }

`

export default Icon;