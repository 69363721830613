import styled from "styled-components";

type ElementProps = {
  isText?: boolean;
  isHeader?: boolean;
  underlined?: boolean;
  overlined?: boolean;
  minimize?: boolean;
  isID?: boolean;
}

type TableProps = {
  isSubTable?: boolean;
  hasSubEntries?: boolean;
}
const GridTable = styled.div<TableProps>`
  display: grid;
  grid-template-columns: ${props => props.hasSubEntries? '1fr 1fr 2fr 10fr 1fr 2fr' : '1fr 1fr 1fr 10fr 1fr 3fr'};
  border: none;
  background-color: ${(props) => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  border-radius: 3px;
`;
const QuestionTable = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr 1fr;
  border: 'none';
  background-color: ${(props) => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  border-radius: 8px;
  padding: 25px;
  padding-bottom: 0px;
`;

const TableElement = styled.div<ElementProps>`
  text-align: ${(props) => props.isText? 'left' : 'center'};
  align-self: ${(props) => props.isText? 'center' : 'auto'};
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: ${props => props.minimize? 0 : '15px'};
  border-bottom: ${props => props.underlined || props.isHeader? `1px solid ${props.theme.colors.black}` : 'none'};
  border-top: ${props => props.overlined|| props.isHeader? `1px solid ${props.theme.colors.black}` : 'none'};
`;

const EmptyPlaceholder = styled.div`
  color: ${props => props.theme.colors.grey};
`

const Container = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 10px;
  overflow-y: auto;
`
const CellContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`
const EditView = styled.div`
  max-width: 300px;
`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const TitleContainer = styled.div`
  border: none;
  background-color: ${(props) => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  padding: 10px 50px;
  font-weight: bold;
  font-size: 24px;
`

const Title = styled.div`
`

export {
  GridTable,
  TableElement,
  Container,
  CellContainer,
  EditView,
  QuestionTable,
  EmptyPlaceholder,
  ActionContainer,
  TitleContainer,
  Title
}