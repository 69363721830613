import styled, {keyframes} from 'styled-components'
import Icon from './Icon'
import React from 'react'

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const RotatingBlock = styled.div`
    animation: ${rotate} 1s linear infinite;
`

const CycleIcon = styled(Icon)`
    margin-top: 8px;
    color: ${props => props.theme.colors.darkGrey};
`

export default function LoadingIcon() {

    return(
        <RotatingBlock>
            <CycleIcon>cycle</CycleIcon>
        </RotatingBlock>
    )
}