import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { QuestionEntry } from "../../communication/interface";
import { Input, Title } from "../baseStyles";
import Button from "../Button";
import { Container, ButtonContainer, ContentContainer, Label } from './component';
import HeaderInfo from "./HeaderInfo";

type Props = {
    question: QuestionEntry;
    info: HeaderInfo;
    onSave: (question: QuestionEntry, headerInfo: HeaderInfo) => void;
    onCancel: () => void;
} & WithTranslation

const EditQuestionnaireModal = (props: Props) => {

    const {onSave, onCancel, t} = props;

    const [title, setTitle] = useState(props.info.title);
    const [description, setDescription] = useState(props.info.description);
    const [greeting, setGreeting] = useState(props.info.greeting);
    const [nothingSaid, setNothingSaid] = useState(props.info.nothingSaid);
    const [nothingHeard, setNothingHeard] = useState(props.info.nothingHeard);
    const [goodBye, setGoodBye] = useState(props.info.goodBye);

    return (

        <Container>

            <Title>{props.info.title}</Title>

            <ContentContainer>
                <Label>{t("questionnaire.name")}</Label>
                <Input value={title} type="text" onChange={(e) => setTitle(e.currentTarget.value)}/>
                <Label>{t("questionnaire.description")}</Label>
                <Input value={description} type="textarea" onChange={(e) => setDescription(e.currentTarget.value)}/>
                <Label>STANDARD</Label>
                <Input value={greeting} type="text" onChange={(e) => setGreeting(e.currentTarget.value)}/>
                <Input value={nothingSaid} type="text" onChange={(e) => setNothingSaid(e.currentTarget.value)}/>
                <Input value={nothingHeard} type="text" onChange={(e) => setNothingHeard(e.currentTarget.value)}/>
                <Input value={goodBye} type="text" onChange={(e) => setGoodBye(e.currentTarget.value)}/>
            </ContentContainer>

            <ButtonContainer>
                <Button secondary onClick={onCancel}>{t('button.cancel')}</Button>  
                <Button tertiary onClick={ (): void => onSave( props.question, { title, description, greeting, nothingSaid, nothingHeard, goodBye } ) }>{t('button.save')}</Button>
            </ButtonContainer>

        </Container>
        
    )
};

export default withTranslation()(EditQuestionnaireModal);