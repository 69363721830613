const theme = {
    colors: {
        main: '#357178',
        darkMain: "#1E5B62",
        element: '#357178',
        light: '#dfeff4',
        medium: '#438c94',
        content: '#f6f4e4',
        acceptGreen: '#12833a',
        errorRed: '#FF0048',
        errorLightRed: '#FF779D',
        white: '#fff',
        black: '#1A141F',
        hover: '#92ebfc',
        backgroundMain: '#F8F8F2',
        icon: '#ABA7AF',
        backgroundGrey: '#f4eae8',
        violetGrey: '#f2f3f5',
        lightGrey: '#c5c5c5',
        veryLightGrey: '#e5e5e5',
        grey: '#9c9c9c',
        darkGrey: 'rgba(36, 36, 36, 1)',
        disabledButton: '#D4D2D5',

    },
    border: '1px solid #000',
    borderLight: '1px solid #E5E0EB',
    boxShadow: '0 5px 10px 3px rgba(166, 50, 69, 0.1)',
    tableGradient: 'linear-gradient(0deg, rgba(245, 243, 247, 0.4), rgba(245, 243, 247, 0.4)), #FFFFFF'
}

export default theme;