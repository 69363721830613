import React, { Component } from "react";
import OverviewCard from "../components/OverviewCard/Overviewcard";
import BackgroundPrimary from "../containers/BackgroundPrimary";
import ViewContainer from "../containers/ViewContainer";
import GridContainer from "../containers/GridContainer";
import { MainHeadline, Modal, ModalContainer } from "../components/baseStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import { AppContext } from "../AppContext";
/*
import {
    Questionnaire,
    QuestionnaireOverviewRequest,
    QuestionnaireOverviewResponse,
} from "symshare";
*/
import { Questionnaire, QuestionnaireCopyRequest, QuestionnaireCopyResponse, QuestionnaireCreateRequest, QuestionnaireCreateResponse, QuestionnaireOverviewRequest, QuestionnaireOverviewResponse } from "../communication/interface";
import { Permission } from "../data/User";
import QuestionnaireView from "./QuestionnaireView";
import { PermissionList } from "../communication/permissions";
import { PlusIcon } from "../components/QuestionnaireTable/ActionIcon";
import FlexContainer from "../containers/FlexContainer";
import Space from "../containers/Space";
import DeletePromptModal from "../components/DeletePromptModal/DeletePromptModal";
import CompanyHeader from "../components/CompanyHeader";

type State = {
    questionnaires: Questionnaire[];
    selectedQuestionnaire: Questionnaire | undefined;
    showDeletePrompt: boolean;
};

class OverviewPage extends Component<WithTranslation, State> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;
    clientId = 0;

    constructor(props: WithTranslation) {

        super(props);

        this.state = {
            questionnaires: [],
            selectedQuestionnaire: undefined,
            showDeletePrompt: false,
        };

    }

    componentDidMount = async () => {

        const permissions = this.context.user.getPermissionList();
        const mappedPermission = permissions.find(
            (permission: Permission) => permission.permissionType === "clientId"
        );
        if (mappedPermission?.permissionValue) {
            this.clientId = +mappedPermission?.permissionValue;
        }

        const fetchQuestionnaires = async (clientId: number) => {
            const result = await this.getQuestionareOverview(clientId);
            this.setState({ questionnaires: result.questionnaires });
        };

        try {
            await fetchQuestionnaires(this.clientId);
        } catch (error) {
            console.log("it seems that an error has occurred " + error);
            this.setState({ questionnaires: [] })
        }

    };

    private canView = (): boolean => {

        const permissions: PermissionList = this.context.user.getPermissionList();

        let hasPermission = false;

        permissions.forEach((permission) => {
            if (permission.permissionType === "viewQuestionnaire" && permission.permissionValue === '.*') { hasPermission = true; }
        })

        return hasPermission;

    }


    // REQUESTS ================================================================

    private getQuestionareOverview = (clientId: number): Promise<QuestionnaireOverviewResponse> => {

        if (!this.canView()) throw new Error("USER HAS NO PERMISSION TO VIEW OVERVIEW");

        return new Promise<QuestionnaireOverviewResponse>((resolve, reject) => {

            // build request
            const request: QuestionnaireOverviewRequest = {
                clientId,
            };

            // post request
            this.context.restAppService.request_post("questionnaire/overview", this.context.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const response = JSON.parse(data) as QuestionnaireOverviewResponse;
                    resolve(response);
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }
            );

        });

    };

    private postQuestionannaireCreate = (clientId: number): Promise<QuestionnaireCreateResponse> => {

        return new Promise<QuestionnaireCreateResponse>( (resolve, reject): void => {

            // build request
            const request: QuestionnaireCreateRequest = {
                clientId,
            };

            // post request
            this.context.restAppService.request_post("questionnaire/create", this.context.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const response = JSON.parse(data) as QuestionnaireCreateResponse;
                    resolve(response);
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }
            );

        });

    }

    private postQuestionannaireCopy = (clientId: number, entryId: number): Promise<QuestionnaireCopyResponse> => {

        return new Promise<QuestionnaireCopyResponse>( (resolve, reject): void => {

            // build request
            const request: QuestionnaireCopyRequest = {
                clientId,
                entryId,
            };

            // post request
            this.context.restAppService.request_post("questionnaire/copy", this.context.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const response = JSON.parse(data) as QuestionnaireCopyResponse;
                    resolve(response);
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }
            );

        });

    }

    private putQuestionannaireDelete = (clientId: number, entryId: number): Promise<boolean> => {

        return new Promise<boolean>( (resolve, reject): void => {

            // build request
            const request: QuestionnaireCopyRequest = {
                clientId,
                entryId,
            };

            // post request
            this.context.restAppService.request_put("questionnaire/delete", this.context.user.getToken(), "text/json", JSON.stringify(request),
                (): void => {
                    resolve(true);
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }
            );

        });

    }

    handleBackNavigation = async () => {

        const result = await this.getQuestionareOverview(this.clientId);
        this.setState({ questionnaires: result.questionnaires });    

        this.setState({ selectedQuestionnaire: undefined });

    };

    handleCreate = async () => {

        const resultCreate = await this.postQuestionannaireCreate(this.clientId);
        if (!resultCreate) throw new Error("CREATE FAILED");

        const result = await this.getQuestionareOverview(this.clientId);
        
        this.setState({ questionnaires: result.questionnaires });    

    }

    handleCopy = async (entryId: number) => {

        console.log('copy ' + entryId);

        const resultCopy = await this.postQuestionannaireCopy(this.clientId, entryId);
        if (!resultCopy) throw new Error("COPY FAILED");

        const result = await this.getQuestionareOverview(this.clientId);
        this.setState({ questionnaires: result.questionnaires });    

    }
    
    handleDelete = async (entryId: number) => {

        console.log('delete ' + entryId);

        // confirm

        const resultCopy = await this.putQuestionannaireDelete(this.clientId, entryId);
        if (!resultCopy) throw new Error("DELETE FAILED");

        const result = await this.getQuestionareOverview(this.clientId);
        this.setState({ questionnaires: result.questionnaires, showDeletePrompt: false, selectedQuestionnaire: undefined });    

    }
    

    // RENDER ==================================================================

    render(): React.ReactNode {

        const { t } = this.props;

        const { questionnaires, selectedQuestionnaire, showDeletePrompt } = this.state;
        const showEditView = selectedQuestionnaire !== undefined && showDeletePrompt === false;

        if (showEditView) {
            return (
                <QuestionnaireView
                    questionnaire={selectedQuestionnaire}
                    backNavigation={this.handleBackNavigation}
                />
            );
        }
        return (
            <>
                <BackgroundPrimary>
                    <ViewContainer>
                        <CompanyHeader/>
                        {/* <MainHeadline>{this.context.client.name}</MainHeadline> */}
                        <MainHeadline>{t("overview.title")}</MainHeadline>
                        <FlexContainer>
                            <Space />
                            <PlusIcon onClick = { () => { this.handleCreate() } } />
                        </FlexContainer>
                        {questionnaires.length > 0 ? 
                            <GridContainer columns={3} gap={25}>
                                {questionnaires.sort((a,b) => a.entryId - b.entryId).map((questionnaire) => (
                                    <OverviewCard
                                        key={questionnaire.entryId}
                                        title={questionnaire.text}
                                        entryId={questionnaire.entryId}
                                        descriptions={questionnaire.description}
                                        editable={questionnaire.editable}
                                        viewHandler={() => this.setState({ selectedQuestionnaire: questionnaire }) }
                                        editHandler={() => null}
                                        copyHandler={this.handleCopy}
                                        deleteHandler={ () => this.setState({ selectedQuestionnaire: questionnaire, showDeletePrompt: true }) }
                                    />
                                ))}
                            </GridContainer>
                        : undefined}
                    </ViewContainer>

                    {selectedQuestionnaire && showDeletePrompt && (
                    <ModalContainer>
                        <Modal show={showDeletePrompt}>
                            <DeletePromptModal
                                question={t("questionnaires.delete")}
                                onDelete={() => this.handleDelete(selectedQuestionnaire.entryId)}
                                onCancel={() =>
                                    this.setState({
                                        showDeletePrompt: false,
                                        selectedQuestionnaire: undefined,
                                    })
                                }
                            />
                        </Modal>
                    </ModalContainer>

                )}

                </BackgroundPrimary>
            </>
        );
    }
}

export default withTranslation()(OverviewPage);
