import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation_en from './locales/en.json';
import translation_de from './locales/de.json';

export const resources = {
    en: { 
        translation: translation_en 
    },
    de: { 
        translation: translation_de
    },
} as const;

i18n
    .use(initReactI18next)
    .init({
        lng: 'de',
        resources,
    });

export default i18n;
