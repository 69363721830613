import styled from "styled-components"
import React, { useContext } from "react"
import { AppContext } from "../AppContext"

const HeaderRow = styled.div`
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
    margin-bottom: 10px;
`

const Title = styled.div`
    font-size: 24px;
    color: ${props => props.theme.colors.darkMain};
    font-weight: 600;
`


export default function CompanyHeader(): JSX.Element {

    const context = useContext(AppContext)

    return(
        <HeaderRow>
            <Title>{context.client.name !== "n/a" ? context.client.name : ''}</Title>
        </HeaderRow>
    )


}

