import React, { useState } from "react";
import {
  Container,
  DropDownContainer,
  DropDownList,
  DropDownListContainer,
  HeaderContainer,
  ListItem,
} from "./components";
import {ReactComponent as ChevronUp} from '../../assets/icons/chevron-up.svg';
import {ReactComponent as ChevronDown} from '../../assets/icons/chevron-down.svg';
import { WithTranslation, withTranslation } from "react-i18next";

type Props<T> = {
  value: T;
  options: T[];
  onChange: (option: T) => void;
  // whether the dropdown should open upwards
  openUpwards?: boolean;
  // if exists translate using i18n
  translate?: string;
} & WithTranslation;

const DropDown = <T extends string>(props: Props<T>) => {
  const { value, options, onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const { t } = props

  const handleItemClick = (option: T) => {
    setIsOpen(false);
    onChange(option);
  };
  return (
    <Container>
      <DropDownContainer>
          <HeaderContainer onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <ChevronUp fill="#000"/> : <ChevronDown fill="#000"/>}
            {props.translate ? t(props.translate + value) : value}
          </HeaderContainer>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList openUpwards = {props.openUpwards}>
              {options.map((option) => (
                <ListItem key={JSON.stringify(option)} onClick={() => handleItemClick(option)}>
                  {props.translate ? t(props.translate + option) : option}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </Container>
  );
};

export default withTranslation()(DropDown);
