import React, { useContext, useEffect, useState } from "react"
import BackgroundPrimary from "../containers/BackgroundPrimary"
import styled from "styled-components"
import { ReactComponent as Logo } from '../assets/icons/Surveybot_CMYK.svg';
import { WithTranslation, withTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../AppContext";
import { ContractGetRequest, ContractResponse, ContractUpdateRequest, ContractUpdateResponse, ContractUpgradeRequest, ContractUpgradeResponse } from "../communication/interface";
import { verifyPhoneNumber } from "../helpers/inputValidatior";
import Icon from "../components/Icon";
import LoadingIcon from "../components/loadingIcon";


const PageFrame = styled.div`
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
    margin-top: 2em;
`

const ColumnContainer = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 800px;
    min-height: 10px;
`

const Title = styled.h2`
    font-size: 24px;
    font-weight: 600;
    color: ${props => props.theme.colors.primary};
    margin: 10px 0;
`

const SubTitle = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: ${props => props.theme.colors.grey};
    margin: 5px 0;
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: start;
`

const InputRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;
`

const DefaultText = styled.div`
    font-weight: 400;
    color: ${props => props.theme.colors.grey};
    padding-right: 15px;
`

interface TextInputProps {
    stretch?: boolean,
    error?: boolean,
}
const TextInput = styled.input<TextInputProps>`
    all: unset;

    flex: ${props => props.stretch ? "1" : "0"} 1 auto;
    border: 1px solid ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.lightGrey};
    padding: 5px 15px;
    margin: 0 3px;

    &:focus {
        border: 1px solid ${props => props.theme.colors.main};
    }
`

const CheckBoxInput = styled.input<TextInputProps>`
    border: 1px solid ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.lightGrey};
    
`

const Spacing = styled.div`
    height: 40px;
`

const GridRow = styled.div`
    display: grid;
    grid-template-columns: 50px 2fr 1fr;
    grid-gap: 10px;
    padding: 5px 0;
`

const RadioButton = styled.input`
    width: 20px;
    height: 100%;
    margin: auto 0;
`

interface PaymentTextProps {
    clickable?: boolean,
}
const PaymentText = styled.div<PaymentTextProps>`
    margin: auto 0;
    cursor: ${props => props.clickable ? "pointer" : "default"};
`

const PlanText = styled.div`
    font-size: 20px;
    font-weight: 500;
    margin: 5px 0;
    border-bottom: solid 1px ${props => props.theme.colors.darkGrey};    
    text-align: center;
`

const PriceText = styled.div`
    font-size: 14px;
    font-weight: 500;
    padding: 0 20px;
    text-align: right;
`

const InfoTextFrame = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`

const ErrorInfoTextFrame = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
`


const CommercialInfoText = styled.div`
    padding: 10px 0;
    text-align: center;
`

interface AgbInfoTextProps {
    error?: boolean,
}
const AgbInfoText = styled.div<AgbInfoTextProps>`
    font-size: 14px;
    font-weight: 400;
    color: ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.grey};
    padding: 15px 0 15px 10px;
    cursor: default;
`

const AgbLink = styled.a<AgbInfoTextProps>`
    display: inline-block;
    font-size: 14px;
    color: ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.grey};
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: ${props => props.theme.colors.main};
    }
`

const CheckBoxText = styled.div`
    color: ${props => props.theme.colors.grey};
    font-size: 14px;
    font-weight: 400;
    padding: 0 10px;
    cursor: default;
`

const BackButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
    color: ${props => props.theme.colors.grey};

    &:hover {
        color: ${props => props.theme.colors.darkGrey};
    }
`

const ButtonFrame = styled.div`
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: start;
    padding: 50px 0;
`
interface ButtonProps {
    main?: boolean,
}
const Button = styled.div<ButtonProps>`
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    margin: 5px 0;
    border-radius: 10px;
    background-color: ${props => props.main ? props.theme.colors.main : props.theme.colors.veryLightGrey};
    color: ${props => props.main ? props.theme.colors.white : props.theme.colors.darkGrey};
    font-weight: ${props => props.main ? '600' : '400'};
    border: solid ${props => props.main ? '0' : '1'}px ${props => props.theme.colors.grey};
    cursor: pointer;

    &:hover {
        filter: opacity(0.9);
    }

    &:active {
        filter: opacity(0.8);
    }
`

const StatusFrame = styled.div`
    flex: 1 1 auto;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 30px;
`

const StatusText = styled.div`
    padding: 20px 0;
`

type Props = {
    children?: React.ReactNode
} & WithTranslation
function UpgradePage(props: Props): JSX.Element {

    const { plan } = useParams() // basic, advanced
    const [email, setEmail] = useState<string>("")
    const [company, setCompany] = useState<string>("")
    const [salutation, setSalutation] = useState<string>("")
    const [department, setDepartment] = useState<string>("")
    const [forename, setForename] = useState<string>("")
    const [surname, setSurname] = useState<string>("")
    const [street, setStreet] = useState<string>("")
    const [postCode, setPostCode] = useState<string>("")
    const [city, setCity] = useState<string>("")
    const [country, setCountry] = useState<string>("")
    const [internOrderNumber, setInternOrderNumber] = useState<string>("")
    const [phone, setPhone] = useState<string>("")
    const [agb, setAgb] = useState<boolean>(false)
    const [authorization, setAuthorization] = useState<boolean>(false)
    const [yearlyPayment, setYearlyPayment] = useState<boolean>(false)
    const [billingMail, setBillingMail] = useState<string>("")
    const [useBillingMail, setUseBillingMail] = useState<boolean>(false)

    const [showError, setShowError] = useState<boolean>(false)
    const [missingFields, setMissingFields] = useState<string[]>([])
    const [pageState, setPageState] = useState<string>("contract") // contract, loading, success, error

    const { t } = props
    const context = useContext(AppContext)
    const navigate = useNavigate()

    const [loadingCounter, setLoadingCounter] = useState<number>(0)
    useEffect(() => {
        if(loadingCounter !== -1) {

            setTimeout(() => {
                if(context.client.clientId !== 0) {
                    setLoadingCounter(-1)
                    getContractData(context.client.clientId)
                }
                else {
                    setLoadingCounter(loadingCounter + 1)
                }
                
            }, 200)
        }
    }, [loadingCounter])

    return (
        <BackgroundPrimary>

            <PageFrame>
                <BackButton onClick = {handleNavigateHome}>
                    <Icon>chevron_left</Icon>
                    {t("upgrade.back")}
                </BackButton>
                <Logo onClick = {handleNavigateHome}/>
                { pageState === "contract" ?
                    <>{
                        plan === "basic" || plan === "advanced" ? 
                            <ColumnContainer>
                                <Title>{t("upgrade.pageTitle")}</Title>
                                <SubTitle>{t("upgrade.subTitle1")}</SubTitle>
                                <SubTitle>{t("upgrade.subTitle2")}</SubTitle>
                                <Spacing/>
                                <BorderContainer padding title = {t("upgrade.account")}>
                                    <InnerContainer>
                                        <InputRow>
                                            <DefaultText>{t("upgrade.email") + ": " + (email === "" ? '...' : email)}</DefaultText>
                                        </InputRow>
                                    </InnerContainer>
                                </BorderContainer>
                                <BorderContainer padding title = {t("upgrade.paymentData")}>
                                    <InnerContainer>
                                        <InputRow>
                                            <TextInput 
                                                name = "company"
                                                type = "text"
                                                value = {company}
                                                placeholder = {String(t("upgrade.company"))} 
                                                onChange = {handleInput}
                                                stretch
                                                error = {showError && missingFields.includes("company")}
                                            />
                                        </InputRow>
                                        <InputRow>
                                            <TextInput 
                                                name = "salutation"
                                                type = "text"
                                                value = {salutation}
                                                placeholder = {String(t("upgrade.salutation"))} 
                                                onChange = {handleInput}
                                                error = {showError && missingFields.includes("salutation")}
                                            />
                                            <TextInput 
                                                name = "department"
                                                type = "text"
                                                value = {department}
                                                placeholder = {String(t("upgrade.department"))} 
                                                stretch
                                                onChange = {handleInput}
                                                error = {showError && missingFields.includes("department")}
                                            />
                                        </InputRow>
                                        <InputRow>
                                            <TextInput 
                                                name = "forename"
                                                type = "text"
                                                value = {forename}
                                                placeholder = {String(t("upgrade.forename"))} 
                                                stretch
                                                onChange = {handleInput}
                                                error = {showError && missingFields.includes("forename")}
                                            />
                                            <TextInput 
                                                name = "surname"
                                                type = "text"
                                                value = {surname}
                                                placeholder = {String(t("upgrade.surname"))} 
                                                stretch
                                                onChange = {handleInput}
                                                error = {showError && missingFields.includes("surname")}
                                            />
                                        </InputRow>
                                        <InputRow>
                                            <TextInput 
                                                name = "postCode"
                                                type = "text"
                                                value = {postCode}
                                                placeholder = {String(t("upgrade.postCode"))} 
                                                onChange = {handleInput}
                                                error = {showError && missingFields.includes("postCode")}
                                            />
                                            <TextInput 
                                                name = "city"
                                                type = "text"
                                                value = {city}
                                                placeholder = {String(t("upgrade.city"))} 
                                                stretch
                                                onChange = {handleInput}
                                                error = {showError && missingFields.includes("city")}
                                            />
                                        </InputRow>
                                        <InputRow>
                                            <TextInput 
                                                name = "street"
                                                type = "text"
                                                value = {street}
                                                placeholder = {String(t("upgrade.street"))} 
                                                stretch
                                                onChange = {handleInput}
                                                error = {showError && missingFields.includes("street")}
                                            />
                                            <TextInput 
                                                name = "country"
                                                type = "text"
                                                value = {country}
                                                placeholder = {String(t("upgrade.country"))} 
                                                onChange = {handleInput}
                                                error = {showError && missingFields.includes("country")}
                                            />
                                        </InputRow>
                                        <InputRow>
                                            <TextInput 
                                                name = "internOrderNumber"
                                                type = "text"
                                                value = {internOrderNumber}
                                                placeholder = {String(t("upgrade.internOrderNumber"))} 
                                                stretch
                                                onChange = {handleInput}
                                                error = {showError && missingFields.includes("internOrderNumber")}
                                            />
                                            <TextInput 
                                                name = "phone"
                                                type = "text"
                                                value = {phone}
                                                placeholder = {String(t("upgrade.phone"))} 
                                                stretch
                                                onChange = {handleInput}
                                                error = {showError && missingFields.includes("phone")}
                                            />
                                        </InputRow>
                                        <InputRow>
                                            <CheckBoxInput
                                                type = "checkbox"
                                                name = "useBillingMail"
                                                onChange = {handleInput}
                                                checked = {useBillingMail}
                                                error = {false}
                                            />
                                            <CheckBoxText onClick = {() => setUseBillingMail(!useBillingMail)}>{t("upgrade.useBillingMail")}</CheckBoxText>
                                            <TextInput 
                                                name = "billingMail"
                                                type = "text"
                                                value = {billingMail}
                                                placeholder = {String(t("upgrade.billingMail"))} 
                                                stretch
                                                onChange = {handleInput}
                                                error = {showError && missingFields.includes("street") && useBillingMail}
                                            />
                                        </InputRow>
                                    </InnerContainer>
                                </BorderContainer>
                                <BorderContainer title = {t("upgrade.paymentMethod")}>
                                    <InnerContainer>
                                        <GridRow>
                                            <div/>
                                            <div/>
                                            <PlanText>
                                                { plan === "basic" ? 
                                                t("services.basic.title")
                                                :    
                                                t("services.advanced.title")
                                                }
                                            </PlanText>
                                        </GridRow>
                                        <GridRow>
                                            <RadioButton 
                                                type = "radio"
                                                name = "yearly"
                                                checked = {yearlyPayment}
                                                onChange = {(e) => handleRadioClick(e.target.name)}
                                            />
                                            <PaymentText clickable onClick = {() => handleRadioClick("yearly")}>{t("upgrade.paymentMethod.yearly")}</PaymentText>
                                            <PriceText>
                                                {(plan === "basic" ? t("services.basic.price.yearly") : t("services.advanced.price.yearly")) + " " + t("upgrade.euro")}
                                                <br/>
                                                {t("upgrade.netto") + " *"}
                                            </PriceText>
                                        </GridRow>
                                        <GridRow>
                                            <div/>
                                            <PaymentText>{t("upgrade.or")}</PaymentText>
                                        </GridRow>
                                        <GridRow>
                                            <RadioButton 
                                                type = "radio"
                                                name = "monthly"
                                                checked = {!yearlyPayment}
                                                onChange = {(e) => handleRadioClick(e.target.name)}
                                            />
                                            <PaymentText clickable onClick = {() => handleRadioClick("monthly")}>{t("upgrade.paymentMethod.monthly")}</PaymentText>
                                            <PriceText>
                                                {(plan === "basic" ? t("services.basic.price") : t("services.advanced.price")) + " " + t("upgrade.euro")}
                                                <br/>
                                                {t("upgrade.netto") + " *"}
                                            </PriceText>
                                        </GridRow>
                                    </InnerContainer>
                                </BorderContainer>
                                <InfoTextFrame>
                                    <CheckBoxInput
                                        type = "checkbox"
                                        name = "agb"
                                        onChange = {handleInput}
                                        checked = {agb}
                                        error = {showError && !agb}
                                    />
                                    <AgbInfoText onClick = {handleAgbClick} error = {showError && !agb}>
                                        {t("upgrade.agb.text1")}
                                        <AgbLink 
                                            target = "_blank" 
                                            href = "https://www.sympalog.de/surveybot/agb/"
                                            error = {showError && !agb}
                                        >{t("upgrade.agb.agb")}</AgbLink>
                                        {t("upgrade.agb.text2")}
                                        <AgbLink 
                                            target = "_blank" 
                                            href = "https://www.sympalog.de/datenschutzerklaerung/"
                                            error = {showError && !agb}
                                        >{t("upgrade.agb.dataPrivacy")}</AgbLink>
                                        {t("upgrade.agb.text3")}
                                    </AgbInfoText>
                                </InfoTextFrame>
                                <InfoTextFrame>
                                    <CheckBoxInput
                                        type = "checkbox"
                                        name = "authorization"
                                        onChange = {handleInput}
                                        checked = {authorization}
                                        error = {showError && !authorization}
                                    />
                                    <AgbInfoText onClick = {handleAuthorizationClick} error = {showError && !authorization}>
                                        {t("upgrade.authorization.text")}
                                    </AgbInfoText>
                                </InfoTextFrame>
                                <ButtonFrame>
                                    <CommercialInfoText>
                                        {"* " + t("upgrade.commercialInfo")}
                                    </CommercialInfoText>
                                    <Button main onClick = {handleSubmit}>
                                        {t("upgrade.submit1") + (plan === "basic" ? t("services.basic.price" + (yearlyPayment ? ".yearly" : "")) : t("services.advanced.price" + (yearlyPayment ? ".yearly" : ""))) + " " + t("upgrade.euro") + t(yearlyPayment ? "upgrade.perYear" : "upgrade.perMonth") + t("upgrade.submit2")}
                                    </Button>
                                    <Button onClick = {handleNavigateHome}>
                                        {t("upgrade.cancel")}
                                    </Button>
                                </ButtonFrame>
                            </ColumnContainer>
                            
                        :
                            <ErrorInfoTextFrame>{t("upgrade.unknown")}</ErrorInfoTextFrame>
                    }</>
                    : pageState === "loading" ?
                    <StatusFrame>
                        <LoadingIcon/>
                    </StatusFrame>
                    : pageState === "success" ?
                    <StatusFrame>
                        <StatusText>
                            {t("upgrade.success")}
                        </StatusText>
                        <Button main onClick = {handleNavigateHome}>
                            {t("upgrade.home")}
                        </Button>    
                    </StatusFrame>
                    :
                    <StatusFrame>
                        <StatusText>
                            {t("upgrade.error")}
                        </StatusText>
                        <Button main onClick = {handleNavigateHome}>
                            {t("upgrade.home")}
                        </Button>   
                    </StatusFrame>
                }

            </PageFrame>

        </BackgroundPrimary>
    )

    function handleNavigateHome(): void {
        navigate('../overview')
    }

    function handleSubmit(): void {
        if(checkCompleteness()) {
            console.log("submitting")
            setPageState("loading")
            postContactUpdateRequest()
                .then(() => {
                    console.log("successfully updated contact data")
                    
                    postContractUpgrade()
                        .then(() => {
                            console.log("successfully upgraded contract")
                            setPageState("success")
                        })
                        .catch((error: string) => {
                            console.log(error)
                        })
                    
                })
                .catch((error: string) => {
                    console.log(error)
                    setPageState("error")
                })
        }
    }

    function checkCompleteness(): boolean {
        const newMissingFields = []
        if(!agb) {
            newMissingFields.push("agb")
        }
        if(!authorization) {
            newMissingFields.push("authorization")
        }
        if(company === "") {
            newMissingFields.push("company")
        }
        if(forename === "") {
            newMissingFields.push("forename")
        }
        if(surname === "") {
            newMissingFields.push("surname")
        }
        if(street === "") {
            newMissingFields.push("street")
        }
        if(postCode === "") {
            newMissingFields.push("postCode")
        }
        if(city === "") {
            newMissingFields.push("city")
        }
        if(country === "") {
            newMissingFields.push("country")
        }
        if(phone !== "" && !verifyPhoneNumber(phone)) {
            newMissingFields.push("phone")
        }

        setMissingFields(newMissingFields)
        if(newMissingFields.length > 0) {
            setShowError(true)
            return false
        }
        else {
            return true
        }
    }

    function handleRadioClick(name: string): void {
        if(name === "yearly") {
            setYearlyPayment(true)
        }
        else {
            setYearlyPayment(false)
        }
    }

    function handleAgbClick(): void {
        setAgb(!agb)
        if(showError) {
            checkCompleteness()
        }
    }

    function handleAuthorizationClick(): void {
        setAuthorization(!authorization)
        if(showError) {
            checkCompleteness()
        }
    }

    function handleInput(e: React.ChangeEvent<HTMLInputElement>): void {

        const target = e.target
        const name = target.name
        const value = target.value

        switch(name) {
            case "email":
                if(value == " ") {
                    setEmail("")
                }
                else {
                    setEmail(value)
                }
                break
            case "company":
                if(value == " ") {
                    setCompany("")
                }
                else {
                    setCompany(value)
                }
                break
            case
             "salutation":
                if(value == " ") {
                    setSalutation("")
                }
                else {
                    setSalutation(value)
                }
                break
            case "department":
                if(value == " ") {
                    setDepartment("")
                }
                else {
                    setDepartment(value)
                }
                break
            case "forename":
                if(value == " ") {
                    setForename("")
                }
                else {
                    setForename(value)
                }
                break
            case "surname":
                if(value == " ") {
                    setSurname("")
                }
                else {
                    setSurname(value)
                }
                break
            case "street":
                if(value == " ") {
                    setStreet("")
                }
                else {
                    setStreet(value)
                }
                break
            case "postCode":
                if(value == " ") {
                    setPostCode("")
                }
                else {
                    setPostCode(value)
                }
                break
            case "city":
                if(value == " ") {
                    setCity("")
                }
                else {
                    setCity(value)
                }
                break
            case "country":
                if(value == " ") {
                    setCountry("")
                }
                else {
                    setCountry(value)
                }
                break
            case "internOrderNumber":
                if(value == " ") {
                    setInternOrderNumber("")
                }
                else {
                    setInternOrderNumber(value)
                }
                break
            case "phone":
                if(value == " ") {
                    setPhone("")
                }
                else {
                    setPhone(value)
                }
                break
            case "agb":
                setAgb(!agb)
                break
            case "authorization":
                setAuthorization(!authorization)
                break
            case "billingMail":
                if(value == " ") {
                    setBillingMail("")
                }
                else {
                    setBillingMail(value)
                }
                break
            case "useBillingMail":
                setUseBillingMail(!useBillingMail)
                break
        }
        
        if(showError) {
            checkCompleteness()
        }
    }


    function getContractData(clientId: number): void {
        postContractGet(clientId)
            .then((response: ContractResponse) => {
                setCompany(response.contract.company.company)
                setStreet(response.contract.company.street)
                setPostCode(response.contract.company.postCode)
                setCity(response.contract.company.city)
                setCountry(response.contract.company.country)
                setForename(response.contract.contact.preName)
                setSurname(response.contract.contact.lastName)
                setDepartment(response.contract.contact.department)
                setPhone(response.contract.contact.telephone)
                setEmail(response.contract.contact.email)
                setInternOrderNumber(response.contract.contact.orderNumber)
                setBillingMail(response.contract.contact.billingEmail)
                setUseBillingMail(response.contract.contact.useBillingEmail)
                setYearlyPayment(response.contract.billing.amountPeriod > 200)
            })
            .catch((error: string) => {
                console.log(error)
            })
    }


    function postContactUpdateRequest(): Promise<ContractUpdateResponse> {

        return new Promise<ContractUpdateResponse>((resolve, reject) => {

            const request: ContractUpdateRequest = {
                clientId: Number(context.client.clientId),
                contractCompany: {
                    company: company,
                    street: street,
                    postCode: postCode,
                    city: city,
                    country: country,
                },
                contractContact: {
                    lastName: surname,
                    preName: forename,
                    department: department,
                    telephone: phone,
                    email: email,
                    orderNumber: internOrderNumber,
                    useBillingEmail: useBillingMail,
                    billingEmail: billingMail,
                }
            }

            context.restAppService.request_post("contract/update", context.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const result = JSON.parse(data) as ContractUpdateResponse
                    resolve(result)
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text)
                }
            )
        })
    }

    function postContractUpgrade(): Promise<ContractUpgradeResponse> {

        return new Promise<ContractUpgradeResponse>((resolve, reject) => {

            const request: ContractUpgradeRequest = {
                clientId: Number(context.client.clientId),
                contractType: String(plan),
                invoicePeriod: yearlyPayment ? "yearly" : "monthly",
            }

            context.restAppService.request_post("contract/upgrade", context.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const result: ContractUpgradeResponse = JSON.parse(data) as ContractUpgradeResponse
                    resolve(result)
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text)
                }
            )
        
        })
    }

    function postContractGet(clientId: number): Promise<ContractResponse> {

        return new Promise<ContractResponse>((resolve, reject) => {
                
            const request: ContractGetRequest = {
                clientId: clientId,
            }

            context.restAppService.request_post("contract/get", context.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const result: ContractResponse = JSON.parse(data) as ContractResponse
                    resolve(result)
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text)
                }
            )
            
        })
    }

}


interface BorderFrameProps {
    padding?: boolean,
}
const BorderFrame = styled.div<BorderFrameProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    border: 1px solid ${props => props.theme.colors.lightGrey};
    border-radius: 20px;
    margin-bottom: ${props => props.padding ? "30px" : "0"};
    background-color: ${props => props.theme.colors.white};
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
`

const BorderFrameTitle = styled.div`
    flex: 0 1 auto;
    padding: 15px 50px;
    border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
    color: ${props => props.theme.colors.darkGrey};
    font-weight: 500;
`

const BorderFrameContent = styled.div`
    flex: 1 1 auto;
    padding: 15px 50px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
`

interface BorderContainerProps {
    title: string,
    children: React.ReactNode,
    padding?: boolean,
}
function BorderContainer(props: BorderContainerProps): JSX.Element {

    return(
        <BorderFrame padding = {props.padding}>
            <BorderFrameTitle>{props.title}</BorderFrameTitle>
            <BorderFrameContent>{props.children}</BorderFrameContent>
        </BorderFrame>
    )
}

export default withTranslation()(UpgradePage) 