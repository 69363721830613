import React from 'react';
import {CheckBoxWrapper, CheckBoxLabel, CheckBox} from './component'

type Props = {
  id: string,
    checked: boolean;
    onChange: () => void;
}

const Toggle = (props: Props) => {
  const {id, checked, onChange} = props;
    return (
        <div>
      <CheckBoxWrapper>
        <CheckBox id={id} type="checkbox" checked={checked} onChange={onChange}/>
        <CheckBoxLabel htmlFor={id} />
      </CheckBoxWrapper>
    </div>
  );
};

export default Toggle;
