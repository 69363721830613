export const credentialValidator = (credential: string) => {
    if(credential) return true;
    return false;
}

export function verifyMail(email: string): boolean {

    const emailRE = /^\S+@\S+\.\S+$/
    return emailRE.test(email)
    
}

export function verifyPhoneNumber(phone: string): boolean {

    const numberRE = /^[0-9\s()-+]+$/
    return numberRE.test(phone);

}