import styled from 'styled-components'

const ViewContainer = styled.div`
    flex: 0 1 auto;
    min-height: 10px;
    padding: 0px 40px 50px 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
`

export const LargeViewContainer = styled.div`
    flex: 1 1 auto;
    min-height: 10px;
    overflow-y: auto;
    padding: 0px 40px 50px 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
`


export default ViewContainer;