import React from "react";
import { WithTranslation, withTranslation } from "react-i18next"
import BackgroundPrimary from "../containers/BackgroundPrimary";
import CompanyHeader from "../components/CompanyHeader";
import styled from "styled-components";
import de from "../locales/de.json";

const PageFrame = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: start;
    overflow: auto;
    margin: 0 50px;
`


const Title = styled.div`
    flex: 0 1 auto;
    font-size: 38px;
    font-weight: 600;
    color: black;
    margin: 20px 0;
`

const PageContainer = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: left;
    min-height: 10px;
    width: 90%;
`

const PageColumn = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    min-height: 10px;
    width: 100%;
    padding: 0 20px;
    overflow: auto;
`

const QuestionContainer = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    max-width: 900px;
    padding: 20px 0;
`

const QuestionRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    padding: 20px 0;
    border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
`

const Question = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: ${props => props.theme.colors.black};
    padding-bottom: 10px;
`

const Anwser = styled.div`
    font-size: 16px;
    color: ${props => props.theme.colors.darkGrey};
`

type Props = {
    children?: React.ReactNode;
} & WithTranslation;
function FAQ(props: Props): JSX.Element {

    const { t } = props;
    return (
        <BackgroundPrimary>
            <PageFrame>
            <CompanyHeader/>
            <PageContainer>
                <Title>{t("FAQ.title")}</Title>
                <PageColumn>
                    <QuestionContainer>
                        {
                            de["FAQ.questions"].map((question) => {
                                return (
                                    <QuestionRow>
                                        <Question>{question[0]}</Question>
                                        <Anwser>{question[1]}</Anwser>
                                    </QuestionRow>
                                )
                            })
                        }
                    </QuestionContainer>
                </PageColumn>
            </PageContainer>
            </PageFrame>
        </BackgroundPrimary>
    )
}



export default withTranslation()(FAQ)