import React, { useContext, useEffect, useState } from "react"
import BackgroundPrimary from "../containers/BackgroundPrimary"
import { MainHeadline } from "../components/baseStyles"
import ViewContainer from "../containers/ViewContainer"
import { WithTranslation, withTranslation } from "react-i18next"
import styled from "styled-components"
import Icon from "../components/Icon"
import { useNavigate } from "react-router-dom"
import { AppContext } from "../AppContext"
import { ContractConditionsResponse, ContractGetRequest, ContractResponse, ServiceCondition } from "../communication/interface"
import CompanyHeader from "../components/CompanyHeader"


const ServiceColumnsFrame = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* flex-wrap: wrap; */

    /* max-width: 900px; */
`

const SingleServiceColumnFrame = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
`

const ServiceColumn = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    min-width: 260px;
    max-width: 400px;
    padding: 20px;
    margin: 30px 10px;
    background-color: ${props => props.theme.colors.white};
    border-radius: 10px;
    box-shadow: 1px 1px 4px rgba(0,0,0,0.2);

    &:hover {
        box-shadow: 3px 3px 5px rgba(0,0,0,0.2);
    }
`

const ServiceHeadingBlock = styled.div`
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    
`

const ServiceHeading = styled.div`
    font-size: 28px;
`
const ServiceSubHeading = styled.div` 
    font-size: 18px;
`

const InfoColumn = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: start;
    padding: 10px 0 40px 0;
`

const InfoListRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 5px 0;
`

const ListIcon = styled(Icon)`
    padding: 0 10px;
`

const ListText = styled.div`
    
`

const OrderColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const OrderPrice = styled.div`
    font-weight: 400;
    font-size: 20px;
    padding: 5px 0;
`

const OrderPriceGreyText = styled.div`
    color: ${props => props.theme.colors.grey};
`

const OrderPriceFrame = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
`

const OrderButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;

    background-color: ${props => props.theme.colors.main};
    color: ${props => props.theme.colors.white};
    border-radius: 10px;

    cursor: pointer;

    &:hover {
        filter: opacity(0.8);
    }
`

const AlreadyOrderedText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
`

type Props = WithTranslation

function ServiceTypes(props: Props): JSX.Element {

    const [currentPlan, setCurrentPlan] = useState<string>('')
    const [planConditions, setPlanConditions] = useState<ServiceCondition[]>([])
    const navigate = useNavigate()
    const { t } = props
    const context = useContext(AppContext)

    const [loadContextIdCounter, setLoadContextIdCounter] = useState(0)
    useEffect(() => {

        if(loadContextIdCounter >= 0) {
            setTimeout(() => {
                
                if(context.client.clientId !== 0) {
                    getContractData(context.client.clientId)
                    loadConditions()
                    setLoadContextIdCounter(-1)
                } 
                else {
                    setLoadContextIdCounter(loadContextIdCounter + 1)
                }
    
            }, 200)
        }
    
    }, [loadContextIdCounter])

    return(
        <BackgroundPrimary>
            
            <ViewContainer>
                <CompanyHeader/>

                <MainHeadline>{t("services.title")}</MainHeadline>
                
                <ServiceColumnsFrame>
                    
                    {
                        planConditions.filter((c) => c.contractType !== 'free').map((c: ServiceCondition) => {
                            
                            return <SingleServiceColumnFrame>
                                <ServiceColumn>
                                    <ServiceHeadingBlock>
                                        <ServiceHeading>{t("services." + c.contractType + ".title")}</ServiceHeading>
                                        <ServiceSubHeading>{t("services." + c.contractType + ".subTitle")}</ServiceSubHeading>
                                    </ServiceHeadingBlock>
                                    <InfoColumn>
                                        <InfoListRow>
                                            <ListIcon>add</ListIcon>
                                            <ListText>{c.telephoneNumbers + " " + (c.telephoneNumbers > 1 ? t("services.phoneNumbers") : t("services.phopneNumber"))}</ListText>
                                        </InfoListRow>
                                        <InfoListRow>
                                            <ListIcon>add</ListIcon>
                                            <ListText>{c.telephoneLines + " " + t("services.channels")}</ListText>
                                        </InfoListRow>
                                        <InfoListRow>
                                            <ListIcon>add</ListIcon>
                                            <ListText>{c.interviews + " " + t("services.feature3") + " " + c.amountInterview + " " + t("general.euro")}</ListText>
                                        </InfoListRow>
                                        <InfoListRow>
                                            <ListIcon>add</ListIcon>
                                            <ListText>{t("services." + c.contractType + ".feature4")}</ListText>
                                        </InfoListRow>
                                        <InfoListRow>
                                            <ListIcon>add</ListIcon>
                                            <ListText>{t("services." + c.contractType + ".feature5")}</ListText>
                                        </InfoListRow>
                                        {
                                            t("services." + c.contractType + ".feature6") === ("services." + c.contractType + ".feature6") ? null :
                                            <InfoListRow>
                                                <ListIcon>add</ListIcon>
                                                <ListText>{t("services." + c.contractType + ".feature6")}</ListText>
                                            </InfoListRow>
                                        }
                                    </InfoColumn>
                                    {
                                        c.contractType !== 'basic' && c.contractType !== 'advanced' ? 
                                            currentPlan === c.contractType ?
                                            <AlreadyOrderedText>{t("services.alreadyUsing")}</AlreadyOrderedText>
                                            :
                                            <OrderButton onClick = {() => handleNavigate("contact-form")}>{t("services.individual.order")}</OrderButton>
                                        :
                                        <OrderColumn>
                                            <OrderPriceFrame>
                                                <OrderPrice>{c.amountPeriod + "€ " + t("services.perMonth")}</OrderPrice>
                                                <OrderPriceGreyText>{t("services.or")}</OrderPriceGreyText>
                                                <OrderPrice>{(c.amountPeriod * 10) + "0€ " + t("services.perYear")}</OrderPrice>
                                            </OrderPriceFrame>
                                            {
                                                currentPlan === c.contractType ?
                                                <AlreadyOrderedText>{t("services.alreadyUsing")}</AlreadyOrderedText>
                                                :
                                                <OrderButton onClick = {() => handleNavigate("upgrade/" + c.contractType)}>{t("services." + c.contractType + ".order")}</OrderButton>
                                            }
                                        </OrderColumn>
                                    }
                                </ServiceColumn>
                            </SingleServiceColumnFrame>
                        })
                    }

                </ServiceColumnsFrame>
            </ViewContainer>
        </BackgroundPrimary>
    )

    
    function handleNavigate(path: string): void {
        navigate("/SurveyBot/" + path)
    }

    function getContractData(clientId: number): void {
        postContractGet(clientId)
            .then((response) => {
                
                setCurrentPlan(response.contract.data.contractType)

            })
            .catch((error: string) => {
                console.log(error)
            })
    }

    function postContractGet(clientId: number): Promise<ContractResponse> {

        return new Promise<ContractResponse>((resolve, reject) => {
                
            const request: ContractGetRequest = {
                clientId: clientId,
            }

            context.restAppService.request_post("contract/get", context.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const result: ContractResponse = JSON.parse(data) as ContractResponse
                    resolve(result)
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text)
                }
            )
            
        })
    }

    function loadConditions(): void {
        getContractConditions()
            .then((response) => {
                setPlanConditions(response.conditions)
            
            })
            .catch((error: string) => {
                console.log(error)
            })
    }
 
    function getContractConditions(): Promise<ContractConditionsResponse> {

        return new Promise<ContractConditionsResponse>((resolve, reject) => {

            context.restAppService.request_get("contract/conditions", context.user.getToken(),
                (data: string): void => {
                    const result: ContractConditionsResponse = JSON.parse(data) as ContractConditionsResponse
                    resolve(result)
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text)
                }
            )
        })
    }
}


export default withTranslation()(ServiceTypes)