import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface HeaderContainerProps {
  open: boolean;
}
const HeaderContainer = styled.div<HeaderContainerProps>`
  /* height: 44px; */
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: solid 1px ${(props) => props.theme.colors.lightGrey};
  border-radius: 10px;
  border-bottom-right-radius: ${props => props.open ? '0' : '10'}px;
  border-bottom-left-radius: ${props => props.open ? '0' : '10'}px;
  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.backgroundMain};
  padding: 5px 15px;
  padding-left: 20px;
  cursor: pointer;
`;

const DropDownContainer = styled("div")`
  width: 100%;
  position: relative;
`;

const DropDownListContainer = styled("div")`
  width: 100%;

`;

const DropDownList = styled("ul")`
  position: absolute;
  z-index: 1;
  width: calc(100% - 2px);
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  border-bottom-left-radius: 10px; 
  border-bottom-right-radius: 10px; 
  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.backgroundMain};
  margin: 0;
  padding: 0;
  text-align: center;
  :first-child {
    border-top: none;
  }

  /* box-shadow: 0 3px 5px rgba(0,0,0,0.5); */
`;
const ListItem = styled("li")`
  list-style: none;
  font-size: 14px;
  padding: 12px;
  margin: 0;
  border-bottom: 1px solid #E5E0EB;
  text-align: left;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.colors.main};
    color: ${(props) => props.theme.colors.white} 
  }

  :last-child {
    border-bottom: none;
  }
`;

export {
  Container,
  HeaderContainer,
  DropDownContainer,
  DropDownListContainer,
  DropDownList,
  ListItem,
};
