import React, { FunctionComponent, useContext } from "react";
import SideBar from "../components/Sidebar/Sidebar";
import { AppContext } from "../AppContext";
import { Entry, Page } from "../types";
import { useNavigate } from "react-router-dom";

interface Props {
  entries: Entry[];
  pageHandler: (page: Page) => void;
  children?: React.ReactNode,
}


const PageContainer: FunctionComponent<Props> = (props) => {
  const { entries, children } = props;
  const context = useContext(AppContext);

  const navigate = useNavigate()

  return (
    <>
      <SideBar entries={entries} handlePageClick={(page = Page.OVERVIEW) => handlePageSwitch(page)} handleLogout={async () => await context.user.logout()}>{children}</SideBar>
    </>
  );


  function handlePageSwitch(page: string): void {

    const url = page.toLowerCase()
    navigate('../' + url)

  }

};

export default PageContainer;
