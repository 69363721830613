import React, { useContext, useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import BackgroundPrimary from "../containers/BackgroundPrimary";
import styled from "styled-components";
import ShadowContainer from "../components/Container/ShadowContainer";
import { AppContext } from "../AppContext";
import { Contract, ContractGetRequest, ContractResponse, ContractUpdateRequest, ContractUpdateResponse } from "../communication/interface";
import Icon from "../components/Icon";
import { useNavigate } from "react-router-dom";
import { deepCopy } from "../util/DeepCopy";
import CompanyHeader from "../components/CompanyHeader";
import AccountPlan from "../components/AccountPlans/AccountPlan";

const PageFrame = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: start;
    overflow: auto;
    margin: 0 50px;
`

const ContainerRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: stretch;
`

const Title = styled.div`
    font-size: 38px;
    font-weight: 600;
    color: black;
    margin: 30px 0;
`

const ContainerPadding = styled.div`
    padding-right: 30px;
`


const ContainerTitle = styled.div`
    font-size: 32px;
    font-weight: 500;
    margin: 5px 0;
`
const ContainerSubtitle = styled.div`
    font-size: 16px;
    font-weight: 500;
    margin: 0;
`


const ContainerContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    margin: 30px 0;
`

interface ContainerGridRowProps {
    hide?: boolean;
}
const ContainerGridRow = styled.div<ContainerGridRowProps>`
    display: ${props => props.hide ? "none" : "grid"};
    grid-template-columns: 1fr 1fr;
    padding: 7px 0;
`

const ContainerFlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0;
`

const CheckBoxText = styled.div`
    color: ${props => props.theme.colors.grey};
    font-size: 14px;
    font-weight: 400;
    padding: 0 10px;
    cursor: default;
`

const ContainerPaymentData = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    padding: 7px 0;
`

const ContainerPaymentDataColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    /* padding-left: 30px; */
`

const ContainerLabel = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-weight: 600;
    color: ${props => props.theme.colors.main};
`
const ContainerInfoText = styled.div`
    padding-left: 20px;
`

const AbsoluteRow = styled.div`
    position: absolute;
    top: 100%;
    right: 0;
    padding: 10px;
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
const HoverText = styled.div`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`
const HoverIcon = styled(Icon)`
    text-decoration: none;
    color: ${props => props.theme.colors.grey};
    &:hover {
        color: ${props => props.theme.colors.grey};
    }
`
const TextContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`

const ButtonContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`

interface TextInputProps {
    stretch?: boolean;
    error?: boolean;
}
const TextInput = styled.input<TextInputProps>`
    all: unset;

    flex: ${props => props.stretch ? "1" : "0"} 1 auto;
    border: 1px solid ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.lightGrey};
    padding: 5px 15px;
    margin: 2px 3px;
    max-width: ${props => props.stretch ? "100%" : "150px"};

    &:focus {
        border: 1px solid ${props => props.theme.colors.main};
    }
`

const CheckBoxInput = styled.input<TextInputProps>`
    border: 1px solid ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.lightGrey};
    
`

type Props = {
    children?: React.ReactNode;
} & WithTranslation;
function CustomerDataPage(props: Props): JSX.Element {

    const [contractData, setContractData] = useState<Contract>({
        clientId: 0,
        company: {
            company: "",
            street: "",
            postCode: "",
            city: "",
            country: "",
        },
        contact: {
            lastName: "",
            preName: "",
            department: "",
            telephone: "",
            email: "",
            orderNumber: "",
            useBillingEmail: false,
            billingEmail: "",
        },
        billing: {
            contractBilling: "",
            amountPeriod: 0,
            amountInterview: 0,
            maxAmountPeriod: 0,
            lastInvoiceDate: "",
            invoicePeriod: "monthly",
        },
        data: {
            contractType: "",
            telephoneNumbers: 0,
            telephoneLines: 0,
            interviews: 0,
            limitInterviews: false,
            created: "",
            cancelled: "",
            validUntil: "",
        }
    })
    const [edtedContractData, setEditedContractData] = useState<Contract>(deepCopy(contractData))
    const [mode, setMode] = useState<string>("view") // view, edit

    const { t } = props;
    const context = useContext(AppContext)
    const navigate = useNavigate()
    
    const [loadContextIdCounter, setLoadContextIdCounter] = useState(0)
    useEffect(() => {

        if(loadContextIdCounter >= 0) {
            setTimeout(() => {
                
                if(context.client.clientId !== 0) {
                    getContractData(context.client.clientId)
                    setLoadContextIdCounter(-1)
                } 
                else {
                    setLoadContextIdCounter(loadContextIdCounter + 1)
                }
    
            }, 200)
        }    
    
    }, [loadContextIdCounter])

    return(
        <BackgroundPrimary>
            <CompanyHeader />
            <PageFrame>
                <Title>{t("customerData.title")}</Title>
                <ContainerRow>
                    <ContainerPadding>
                        {mode === "edit" ?
                            <ShadowContainer>
                                <ButtonContainer>
                                    <Icon hover onClick = {handleSave}>done</Icon>
                                    <Icon hover onClick = {handleDiscard}>close</Icon>
                                </ButtonContainer>
                                <ContainerTitle>{contractData.company.company}</ContainerTitle>
                                <ContainerSubtitle>{contractData.data.contractType + " Account"}</ContainerSubtitle>
                                <ContainerContent>
                                    <ContainerFlexRow>
                                        <TextInput
                                            name = "company"
                                            type = "text"
                                            value = {edtedContractData.company.company}
                                            placeholder = {String(t("customerData.company"))} 
                                            onChange = {handleInput}
                                        />
                                        <TextInput
                                            name = "department"
                                            type = "text"
                                            value = {edtedContractData.contact.department}
                                            placeholder = {String(t("upgrade.department"))} 
                                            onChange = {handleInput}
                                            stretch
                                        />
                                    </ContainerFlexRow>
                                    <ContainerFlexRow>
                                        <TextInput
                                            name = "preName"
                                            type = "text"
                                            value = {edtedContractData.contact.preName}
                                            placeholder = {String(t("upgrade.forename"))} 
                                            onChange = {handleInput}
                                            stretch
                                        />
                                        <TextInput
                                            name = "lastName"
                                            type = "text"
                                            value = {edtedContractData.contact.lastName}
                                            placeholder = {String(t("upgrade.surname"))} 
                                            onChange = {handleInput}
                                            stretch
                                        />
                                    </ContainerFlexRow>

                                    <ContainerFlexRow>
                                        <TextInput
                                            name = "telephone"
                                            type = "text"
                                            value = {edtedContractData.contact.telephone}
                                            placeholder = {String(t("customerData.phoneNumber"))} 
                                            onChange = {handleInput}
                                            stretch
                                        />
                                    </ContainerFlexRow>

                                    <ContainerFlexRow>
                                        <CheckBoxInput
                                            type = "checkbox"
                                            name = "useBillingMail"
                                            onChange = {handleInput}
                                            checked = {edtedContractData.contact.useBillingEmail}
                                            error = {false}
                                        />
                                        <CheckBoxText onClick = {() => handleCheckBoxInput("useBillingMail")}>{t("upgrade.useBillingMail")}</CheckBoxText>
                                        <TextInput
                                            name = "billingMail"
                                            type = "text"
                                            value = {edtedContractData.contact.billingEmail}
                                            placeholder = {String(t("upgrade.billingMail"))} 
                                            onChange = {handleInput}
                                            stretch
                                        />
                                    </ContainerFlexRow>

                                    <ContainerPaymentData>
                                        <ContainerLabel>{t("customerData.paymentAdress") + ":"}</ContainerLabel>
                                        <ContainerFlexRow>
                                            <TextInput
                                                name = "city"
                                                type = "text"
                                                value = {edtedContractData.company.city}
                                                placeholder = {String(t("customerData.city"))} 
                                                onChange = {handleInput}
                                            />
                                            <TextInput
                                                name = "street"
                                                type = "text"
                                                value = {edtedContractData.company.street}
                                                placeholder = {String(t("customerData.street"))} 
                                                onChange = {handleInput}
                                                stretch
                                            />
                                        </ContainerFlexRow>
                                        <ContainerFlexRow>
                                            <TextInput
                                                name = "postCode"
                                                type = "text"
                                                value = {edtedContractData.company.postCode}
                                                placeholder = {String(t("customerData.postCode"))} 
                                                onChange = {handleInput}
                                            />
                                            <TextInput
                                                name = "country"
                                                type = "text"
                                                value = {edtedContractData.company.country}
                                                placeholder = {String(t("customerData.country"))} 
                                                onChange = {handleInput}
                                                stretch
                                            />
                                        </ContainerFlexRow>
                                    </ContainerPaymentData>
                                </ContainerContent>
                            </ShadowContainer>
                            :
                            <ShadowContainer>
                                <ButtonContainer>
                                    <Icon hover onClick = {handleChangeMode}>edit</Icon>
                                </ButtonContainer>
                                <ContainerTitle>{contractData.company.company}</ContainerTitle>
                                <ContainerSubtitle>{contractData.data.contractType + " Account"}</ContainerSubtitle>
                                <ContainerContent>
                                    <ContainerGridRow>
                                        <ContainerLabel>{t("customerData.interviewsUsed") + ":"}</ContainerLabel>
                                        <ContainerInfoText>1999 #backend todo</ContainerInfoText>
                                    </ContainerGridRow>
                                    { contractData.data.validUntil === "" ? null :
                                        <ContainerGridRow>
                                            <ContainerLabel>{t("customerData.validUntil") + ":"}</ContainerLabel>
                                            <ContainerInfoText>{new Date(contractData.data.validUntil).toLocaleDateString()}</ContainerInfoText>
                                        </ContainerGridRow>
                                    }
                                    <ContainerGridRow>
                                        <ContainerLabel>{t("customerData.paymentMethod") + ":"}</ContainerLabel>
                                        <ContainerInfoText>{contractData.data.contractType !== 'free' ? contractData.billing.amountPeriod === 0 ? t("customerData.yearlyPayment") : t("customerData.monthlyPayment") : null}</ContainerInfoText>
                                    </ContainerGridRow>
                                    <ContainerGridRow>
                                        <ContainerLabel>{t("customerData.contactPerson") + ":"}</ContainerLabel>
                                        <ContainerInfoText>{contractData.contact.preName + " " + contractData.contact.lastName}</ContainerInfoText>
                                    </ContainerGridRow>
                                    <ContainerGridRow>
                                        <ContainerLabel>{t("customerData.email") + ":"}</ContainerLabel>
                                        <ContainerInfoText>{contractData.contact.email}</ContainerInfoText>
                                    </ContainerGridRow>
                                    <ContainerGridRow>
                                        <ContainerLabel>{t("customerData.phoneNumber") + ":"}</ContainerLabel>
                                        <ContainerInfoText>{contractData.contact.telephone}</ContainerInfoText>
                                    </ContainerGridRow>

                                    <ContainerGridRow>
                                        <ContainerLabel>{t("customerData.internOrderNumber") + ":"}</ContainerLabel>
                                        <ContainerInfoText>{contractData.contact.orderNumber}</ContainerInfoText>
                                    </ContainerGridRow>
                                    {(contractData.company.city === "" && contractData.company.street === "" && contractData.company.country === "" && contractData.company.postCode === "") ? null :
                                        <ContainerPaymentData>
                                            <ContainerLabel>{t("customerData.paymentAdress") + ":"}</ContainerLabel>
                                            <ContainerGridRow>
                                                <div></div>
                                                <ContainerPaymentDataColumn>
                                                    <ContainerInfoText>{contractData.company.city}</ContainerInfoText>
                                                    <ContainerInfoText>{contractData.company.street}</ContainerInfoText>
                                                    <ContainerInfoText>{contractData.company.postCode}</ContainerInfoText>
                                                    <ContainerInfoText>{contractData.company.country}</ContainerInfoText>
                                                </ContainerPaymentDataColumn>
                                            </ContainerGridRow>
                                        </ContainerPaymentData>
                                    }
                                </ContainerContent>
                            </ShadowContainer>
                        }

                    </ContainerPadding>
                    <ShadowContainer>
                        <ContainerTitle>{t("services.services")}</ContainerTitle>
                        {
                            contractData.data.contractType === "advanced" ||
                            contractData.data.contractType === "basic" ||
                            contractData.data.contractType === "free" ||
                            contractData.data.contractType === "" ?
                            <AccountPlan
                                type = {contractData.data.contractType === "" ? "free" : contractData.data.contractType}
                                phoneNumbers = {contractData.data.telephoneNumbers} 
                                phoneLines = {contractData.data.telephoneLines}
                                interviews = {contractData.data.interviews}
                                amountInterview = {contractData.billing.amountInterview}
                            />
                            :
                            "plan not available"
                        }
                        <AbsoluteRow>
                            <HoverText onClick = {() => handleNavigate("cancellation")}>{t("customerData.cancelSubscription")}</HoverText>
                            <TextContainer onClick = {() => handleNavigate("service-types")}>
                                <HoverIcon>upgrade</HoverIcon>
                                <HoverText>{t("customerData.upgrade")}</HoverText>
                            </TextContainer>
                        </AbsoluteRow>
                    </ShadowContainer>
                </ContainerRow>
            </PageFrame>
        </BackgroundPrimary>
    )

    function handleNavigate(destination: string): void {
        navigate("../" + destination)
    }

    function handleInput(event: React.ChangeEvent<HTMLInputElement>): void {
        const name = event.target.name
        const value = event.target.value

        switch(name) {
            case "preName":
                if(value === " ") {
                    setEditedContractData({...edtedContractData, contact: {...edtedContractData.contact, preName: ""}})
                }
                else {
                    setEditedContractData({...edtedContractData, contact: {...edtedContractData.contact, preName: value}})
                }
                break
            case "lastName":
                if(value === " ") {
                    setEditedContractData({...edtedContractData, contact: {...edtedContractData.contact, lastName: ""}})
                }
                else {
                    setEditedContractData({...edtedContractData, contact: {...edtedContractData.contact, lastName: value}})
                }
                break
            case "telephone":
                if(value === " ") {
                    setEditedContractData({...edtedContractData, contact: {...edtedContractData.contact, telephone: ""}})
                }
                else {
                    setEditedContractData({...edtedContractData, contact: {...edtedContractData.contact, telephone: value}})
                }
                break
            case "city":
                if(value === " ") {
                    setEditedContractData({...edtedContractData, company: {...edtedContractData.company, city: ""}})
                }
                else {
                    setEditedContractData({...edtedContractData, company: {...edtedContractData.company, city: value}})
                }
                break
            case "street":
                if(value === " ") {
                    setEditedContractData({...edtedContractData, company: {...edtedContractData.company, street: ""}})
                }
                else {
                    setEditedContractData({...edtedContractData, company: {...edtedContractData.company, street: value}})
                }
                break
            case "postCode":
                if(value === " ") {
                    setEditedContractData({...edtedContractData, company: {...edtedContractData.company, postCode: ""}})
                }
                else {
                    setEditedContractData({...edtedContractData, company: {...edtedContractData.company, postCode: value}})
                }
                break
            case "country":
                if(value === " ") {
                    setEditedContractData({...edtedContractData, company: {...edtedContractData.company, country: ""}})
                }
                else {
                    setEditedContractData({...edtedContractData, company: {...edtedContractData.company, country: value}})
                }
                break
            case "company":
                if(value === " ") {
                    setEditedContractData({...edtedContractData, company: {...edtedContractData.company, company: ""}})
                }
                else {
                    setEditedContractData({...edtedContractData, company: {...edtedContractData.company, company: value}})
                }
                break
            case "department":
                if(value === " ") {
                    setEditedContractData({...edtedContractData, contact: {...edtedContractData.contact, department: ""}})
                }
                else {
                    setEditedContractData({...edtedContractData, contact: {...edtedContractData.contact, department: value}})
                }
                break
            case "billingMail":
                if(value === " ") {
                    setEditedContractData({...edtedContractData, contact: {...edtedContractData.contact, billingEmail: ""}})
                }
                else {
                    setEditedContractData({...edtedContractData, contact: {...edtedContractData.contact, billingEmail: value}})
                }
                break
        }

    }

    function handleCheckBoxInput(name: string): void {
        switch(name) {
            case "useBillingMail":
                setEditedContractData({...edtedContractData, contact: {...edtedContractData.contact, useBillingEmail: !edtedContractData.contact.useBillingEmail}})
                break
        }
    }

    function handleSave(): void {
        if(context.client.clientId !== 0) {
            postContactUpdateRequest()
                .then((result: ContractUpdateResponse) => {
                    console.log("contact updated")
                    setMode("view")
                    setContractData(result.contract)
                })
                .catch((error: string) => {
                    console.log(error)
                })
        }
    }

    function handleDiscard(): void {
        setEditedContractData(deepCopy(contractData))
        setMode("view")
    }

    function handleChangeMode(): void {
        if(mode === "view") {
            setEditedContractData(deepCopy(contractData))
        }
        setMode(mode === "view" ? "edit" : "view")
    }

    function getContractData(clientId: number): void {
        postContractGet(clientId)
            .then((response) => {
                
                setContractData(response.contract)   

            })
            .catch((error: string) => {
                console.log(error)
            })
    }

    function postContractGet(clientId: number): Promise<ContractResponse> {

        return new Promise<ContractResponse>((resolve, reject) => {
                
            const request: ContractGetRequest = {
                clientId: clientId,
            }

            context.restAppService.request_post("contract/get", context.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const result: ContractResponse = JSON.parse(data) as ContractResponse
                    resolve(result)
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text)
                }
            )
            
        })
    }

    function postContactUpdateRequest(): Promise<ContractUpdateResponse> {

        return new Promise<ContractUpdateResponse>((resolve, reject) => {

            const request: ContractUpdateRequest = {
                clientId: context.client.clientId,
                contractCompany: edtedContractData.company,
                contractContact: edtedContractData.contact,
            }

            context.restAppService.request_post("contract/update", context.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const result: ContractUpdateResponse = JSON.parse(data) as ContractUpdateResponse
                    resolve(result)
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text)
                }
            )
        })
    }

}



export default withTranslation()(CustomerDataPage);