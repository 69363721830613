import React from 'react';
import { useTheme } from 'styled-components';
import {Container, Wrapper, Label, Input, ErrorMessage} from './components';

export type Props = {
  key?: string;
  label: string;
  value: string;
  errorMessage: string;
  type?: string;
  onChangeHandler: (input: string) => void;
  onKeyPress: (input: string) => void;
};

const LabeledInput = (props: Props) => {
  const { key, label, value, onChangeHandler, onKeyPress, errorMessage, type } = props;
  const theme = useTheme();

  return (
    <Container>
    <Wrapper>
      <Label htmlFor={`${key ? key : label}-input`}>{label}</Label>{" "}
      <Input
        id={`${key ? key : label}-input`}
        value={value}
        type={type? type : "text"}
        onChange={(event) => onChangeHandler(event.target.value)}
        onKeyPress={(event) => onKeyPress(event.key)}
        hasError={!!errorMessage && errorMessage !== ''}
        theme={theme}
      />
    </Wrapper>
    <ErrorMessage>{errorMessage}</ErrorMessage>
    </Container>
  );
};

export default LabeledInput;
