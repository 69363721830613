import styled from "styled-components"
import React, { useContext, useState } from "react"
import { AppContext } from "../AppContext"
import { WithTranslation, withTranslation } from "react-i18next"
import Icon from "./Icon"

const HeaderRow = styled.div`
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
    margin-bottom: 10px;
    position: relative;
`

const Title = styled.div`
    width: inherit;
    position: absolute;
    z-index: 1;
    font-size: 24px;
    color: ${props => props.theme.colors.darkMain};
    font-weight: 600;
    text-align: center;
`


const BackArrowContainer = styled.div<BackArrowProps>`
    flex: 0 1 auto;
    position: relative;
    z-index: 10;
    width: min-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    justify-content: start;
    padding: 15px 0;
    color: ${props => props.hover ? props.theme.colors.main : props.theme.colors.grey};
`

interface BackArrowProps {
    hover: boolean;
}
const BackArrowIcon = styled(Icon)<BackArrowProps>`
    padding: 0 10px;
    color: ${props => props.hover ? props.theme.colors.main : props.theme.colors.grey};

    &:hover {
        color: ${props => props.theme.colors.main};
    }
`

type Props = {
    onBack: () => void;
} & WithTranslation;

function CompanyBackHeader(props: Props): JSX.Element {
    
    const [backArrowHover, setBackArrowHover] = useState<boolean>(false)
    const context = useContext(AppContext)

    const { t } = props

    return(
        <HeaderRow>
            <BackArrowContainer 
                onClick = {props.onBack} 
                onMouseEnter = {() => handleHover(true)} 
                onMouseLeave = {() => handleHover(false)}
                hover = {backArrowHover}
            >
                <BackArrowIcon hover = {backArrowHover}>
                    arrow_back
                </BackArrowIcon>
                {t('navigation.back')}
            </BackArrowContainer>
            <Title>{context.client.name !== "n/a" ? context.client.name : ''}</Title>
        </HeaderRow>
    )

    function handleHover(hover: boolean) {
        setBackArrowHover(hover)
    }
}


export default withTranslation()(CompanyBackHeader);

