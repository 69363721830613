import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { AppContext } from "../../AppContext";
import {
  Questionnaire,
  QuestionnaireOverviewRequest,
  QuestionnaireOverviewResponse,
  ReportReportRequest,
  ReportReportResponse,
  ReportResult,
} from "../../communication/interface";
import { Permission } from "../../data/User";
import Button from "../Button";
import DropDown from "../DropDown/DropDown";
import { Label } from "../LabeledInput/components";
import { Container, GridContainer, DropDownWidthFrame } from "./components";
import DateTimePicker from "react-datetime-picker";
import { Title } from "../baseStyles";

type Props = {
  setResult: (result: ReportResult) => void;
  setMode: (newMode: boolean) => void;
} & WithTranslation;

type State = {
  questionnaires: Questionnaire[];
  phoneNumbers: string[];
  selectedQuestionnaire: string;
  selectedTimeFrame: TimeFrame;
  selectedStartDate: Date;
  selectedEndDate: Date;
  selectedPhoneNumber: string;
};

enum TimeFrame {
  TODAY = "export.today",
  YESTERDAY = "export.yesterday",
  THISWEEK = "export.thisWeek",
  LASTWEEK = "export.lastWeek",
  THISMONTH = "export.thisMonth",
  LASTMONTH = "export.lastMonth",
  CUSTOM = "export.custom",
}
const selectableTimeFrames = [
  TimeFrame.TODAY,
  TimeFrame.YESTERDAY,
  TimeFrame.THISWEEK,
  TimeFrame.LASTWEEK,
  TimeFrame.THISMONTH,
  TimeFrame.LASTMONTH,
  TimeFrame.CUSTOM,
];

class ReportFilters extends Component<Props, State> {

  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  constructor(props: Props) {
    super(props);

    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(0, 0, 0, 0);
    endDate.setDate(endDate.getDate() + 1);

    this.state = {
      questionnaires: [],
      selectedQuestionnaire: "",
      selectedTimeFrame: TimeFrame.TODAY,
      selectedStartDate: startDate,
      selectedEndDate: endDate,
      phoneNumbers: [],
      selectedPhoneNumber: "",
    };
  }
  componentDidMount = async () => {
    const { t } = this.props;

    const result = await this.getQuestionnaireOverview(this.getClientId());

    this.setState({
      questionnaires: result.questionnaires,
      selectedQuestionnaire: result.questionnaires.length > 0 ? result.questionnaires[0].text : '',
      phoneNumbers: this.context.client.telephoneList.concat(String(t("report.allPhones"))),
      selectedPhoneNumber: String(t("report.allPhones")),
    });
  };

  private getClientId = () => {
    const permissions = this.context.user.getPermissionList();
    const mappedPermission = permissions.find(
      (permission: Permission) => permission.permissionType === "clientId"
    );
    return mappedPermission ? +mappedPermission.permissionValue : 0;
  };
  
  private handleSave = async () => {
    try {

      const { startDate, endDate } = this.matchTimeFrameToDate();
      const { selectedPhoneNumber } = this.state;

      const payload: ReportReportRequest = {
        clientId: this.getClientId(),
        entryId: this.matchQuestionnaireToId(),
        reportFilter: {
          telephone: selectedPhoneNumber === "Alle Telefonnummern" ? "" : selectedPhoneNumber,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          timezone: '',
          content: '',
          fileFormat: '',
        }
        
      };

      const result = await this.getReport(payload);

      if (result && result.report) { 
        this.props.setResult(result.report);
        this.props.setMode(true);
      }


    } catch (error) {
      console.error(error);
    }

  };

  private matchQuestionnaireToId = () => {
    const { questionnaires, selectedQuestionnaire } = this.state;
    const { t } = this.props;
    if (selectedQuestionnaire === t("export.allQuestionnaires")) return 0;
    const questionnaire = questionnaires.find(
      (entry) => entry.text === selectedQuestionnaire
    );
    if (!questionnaire) return 0;
    return questionnaire.entryId;
  };
  private matchTimeFrameToDate = () => {

    const { selectedTimeFrame, selectedStartDate, selectedEndDate } = this.state;

    const startDate = new Date(); 
    startDate.setHours(0, 0, 0, 0);

    let endDate = new Date(); 
    endDate.setHours(0, 0, 0, 0);

    const prevMonday = new Date(); 
    prevMonday.setHours(0, 0, 0, 0);
    prevMonday.setDate(
      startDate.getDate() - ((startDate.getDay() + 6) % 7)
    );

    /*
    if (prevMonday.getDay() === startDate.getDay())
      prevMonday.setDate(startDate.getDate() - 7);
    */

    switch (selectedTimeFrame) {

      case TimeFrame.TODAY:
        endDate.setDate(endDate.getDate() + 1);
        return {
          startDate: startDate,
          endDate: endDate,
        };

      case TimeFrame.YESTERDAY:
        startDate.setDate(startDate.getDate() - 1);
        return {
          startDate,
          endDate,
        };
        
      case TimeFrame.THISWEEK:
        endDate.setDate(endDate.getDate() + 1);
        return {
          startDate: prevMonday,
          endDate: endDate,
        };

      case TimeFrame.LASTWEEK:
        prevMonday.setDate(prevMonday.getDate() - 7);
        endDate = new Date(prevMonday);
        endDate.setDate(prevMonday.getDate() + 7);
        return {
          startDate: prevMonday,
          endDate,
        };

      case TimeFrame.THISMONTH:
        startDate.setDate(1);
        endDate.setDate(endDate.getDate() + 1);
        return {
          startDate,
          endDate,
        };

      case TimeFrame.LASTMONTH:
        startDate.setDate(1);
        startDate.setMonth(startDate.getMonth() - 1);
        endDate.setDate(1);
        return {
          startDate,
          endDate,
        };

      default:
        return {
          startDate: selectedStartDate,
          endDate: selectedEndDate,
        };
    }
  };
  // REQUESTS
  private getQuestionnaireOverview = (
    clientId: number
  ): Promise<QuestionnaireOverviewResponse> => {
    return new Promise<QuestionnaireOverviewResponse>((resolve, reject) => {
      // build request
      const request: QuestionnaireOverviewRequest = {
        clientId,
      };

      // post request
      this.context.restAppService.request_post(
        "questionnaire/overview",
        this.context.user.getToken(),
        "text/json",
        JSON.stringify(request),
        (data: string): void => {
          const response = JSON.parse(data) as QuestionnaireOverviewResponse;
          resolve(response);
        },
        (status: number, text: string): void => {
          reject("failure " + status + "/" + text);
        }
      );
    });
  };

  private getReport(
    request: ReportReportRequest
  ): Promise<ReportReportResponse> {
    return new Promise<ReportReportResponse>((resolve, reject) => {
      // post request
      this.context.restAppService.request_post(
        "report/report",
        this.context.user.getToken(),
        "text/json",
        JSON.stringify(request),
        (data: string): void => {
          const response = JSON.parse(data) as ReportReportResponse;
          resolve(response);
        },
        (status: number, text: string): void => {
          reject("failure " + status + "/" + text);
        }
      );
    });
  }

  render(): React.ReactNode {
    const { t } = this.props;
    const {
      selectedQuestionnaire,
      selectedTimeFrame,
      selectedStartDate,
      selectedEndDate,
      phoneNumbers,
      questionnaires,
      selectedPhoneNumber,
    } = this.state;
    const mappedQuestionnaires = questionnaires.map(
      (questionnaire) => questionnaire.text
    );

    // mappedQuestionnaires.splice(0, 0, t("export.allQuestionnaires"));
    const timeFrameList = selectableTimeFrames.map((timeframe) => t(timeframe));
    const maxDate = new Date();
    maxDate.setHours(0, 0, 0, 0);
    maxDate.setDate(maxDate.getDate() + 1);
    return (
      <Container>
        <Title>{t("export.title")}</Title>
        <GridContainer>
          <Label>{t("export.dropdown.label")}</Label>
          <DropDownWidthFrame>
            <DropDown
              value={selectedQuestionnaire}
              options={mappedQuestionnaires}
              onChange={(value) =>
                this.setState({ selectedQuestionnaire: value })
              }
            />
          </DropDownWidthFrame>
          <Label>{t("export.phoneNumber.label")}</Label>
          <DropDownWidthFrame>
            <DropDown
              value={selectedPhoneNumber}
              options={phoneNumbers}
              onChange={(value) =>
                this.setState({ selectedPhoneNumber: value })
              }
            />
          </DropDownWidthFrame>
          <Label>{t("export.preSelection.label")}</Label>
          <DropDownWidthFrame>
            <DropDown
              value={t(selectedTimeFrame)}
              options={timeFrameList}
              onChange={(value) => {
                const newTimeFrame = selectableTimeFrames.find(
                  (frame) => t(frame) === value
                );
                if (newTimeFrame)
                  this.setState({ selectedTimeFrame: newTimeFrame });
              }}
            />
          </DropDownWidthFrame>
          {selectedTimeFrame === TimeFrame.CUSTOM && (
            <>
              <Label>{t("export.startDate.label")}</Label>
              <DateTimePicker
                value={selectedStartDate}
                onChange={(date) => this.setState({ selectedStartDate: date })}
                disableClock={true}
                locale="de-DE"
                showLeadingZeros={true}
                maxDate={selectedEndDate}
                clearIcon={null}
              />

              <Label>{t("export.endDate.label")}</Label>
              <DateTimePicker
                value={selectedEndDate}
                onChange={(date) => this.setState({ selectedEndDate: date })}
                disableClock={true}
                locale="de-DE"
                showLeadingZeros={true}
                maxDate={maxDate}
                minDate={selectedStartDate}
                clearIcon={null}
              />
            </>
          )}

        </GridContainer>
        <Button
          onClick={ () => {
            console.log(JSON.stringify(this.matchTimeFrameToDate()));
            this.handleSave();
            }
          }
        >
          {t("export.getReport")}
        </Button>
      </Container>
    );
  }
}

export default withTranslation()(ReportFilters);
