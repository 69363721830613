import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 32px;
  border-radius: 8px;
  align-self: center;
  justify-self: center;
  padding: 25px 15px;
  align-items: flex-start;
  justify-content: space-around;
  background-color: ${(props) => props.theme.colors.white};
  align-self: center;
  color: black;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 24px;
  column-gap: 100px;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

const DropDownWidthFrame = styled.div`
  min-width: 200px;
`


export { Container, GridContainer, ToggleContainer, DropDownWidthFrame };
