import React, { FC, useState, useEffect, useRef, useContext } from "react";
import styled, { useTheme } from "styled-components";
import { Page } from "../../types";
import {
    Container,
    EntryContainer,
    SidebarEntry,
    SidePanel,
    MainContainer,
    HeaderContainer,
    Label
} from "./components";
import { ClickableLogo } from "../Button";
import { Entry } from "../../types";
import logo from '../../assets/img/Surveybot_400px.png'
import { ReactComponent as Survey } from '../../assets/icons/calendar.svg'
import { ReactComponent as Client } from '../../assets/icons/report.svg'
import Icon from "../Icon";
import { Link, useLocation } from "react-router-dom";
import { Permission } from "../../data/User";
import { AppContext } from "../../AppContext";

const SidebarIcon = styled(Icon)`
    color: ${props => props.color};
    font-size: 32px;

    &:hover {
        color: ${props => props.color};
    }

`

const NavContainerFrame = styled.div`
    position: absolute;
    z-index: 15;
    top: 0;
    right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    padding: 20px 0px;
`

const NavContainerRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 10;
`

const NavMenuIcon = styled(Icon)`
    padding-right: 20px;
`

const NavContainerListItem = styled(Link)`
    all: unset;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.colors.light};
    }
`

const ExpandedNavContainerListItem = styled(NavContainerListItem)`
    padding-left: 20px;
`

const ExpandableNavContainerList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
`

const ExpandableNavContainerListItemButton = styled.div`
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.colors.light};
    }
`

interface ExpandedNavContainerProps {
    show: boolean;
}
const ExpandedNavContainer = styled.div<ExpandedNavContainerProps>`
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
`

interface NavContainerProps {
    show: boolean;
}
const NavContainer = styled.div<NavContainerProps>`
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    margin-top: 10px;

    background-color: ${props => props.theme.colors.white};
    box-shadow: 0 0 3px rgba(0,0,0,0.4);
`

const LogoImg = styled.img`
    height: 30px;
`


type Props = {
    entries: Array<Entry>;
    handlePageClick: (page: Page) => void;
    handleLogout: () => void;
    children: React.ReactNode;
};

const getIconByLabel = (page: Page, isActive: boolean) => {
    switch (page) {
        case Page.OVERVIEW: return <SidebarIcon color={isActive ? '#4B3A5A' : '#D9D1E0'}>grid_view</SidebarIcon>
        case Page.CATALOG: return <SidebarIcon color={isActive ? '#4B3A5A' : '#D9D1E0'}>format_list_bulleted</SidebarIcon>
        case Page.CAMPAIGNS: return <SidebarIcon color={isActive ? '#4B3A5A' : '#D9D1E0'}>campaign</SidebarIcon>
        case Page.SURVEYS: return <Survey fill={isActive ? '#4B3A5A' : '#D9D1E0'} />
        case Page.REPORT: return <SidebarIcon color={isActive ? '#4B3A5A' : '#D9D1E0'}>pie_chart</SidebarIcon>
        case Page.EXPORTS: return <SidebarIcon color={isActive ? '#4B3A5A' : '#D9D1E0'}>download</SidebarIcon>
        case Page.CLIENTS: return <Client fill={isActive ? '#4B3A5A' : '#D9D1E0'} />
        case Page.MONITOR: return <SidebarIcon color={isActive ? '#4B3A5A' : '#D9D1E0'}>monitoring</SidebarIcon>

        default:
            break;
    }
}

const Sidebar: FC<Props> = ({ entries, handlePageClick, handleLogout, children }) => {

    const [showNavMenu, setShowNavMenu] = useState<boolean>(false);
    const [showHelpContainer, setShowHelpContainer] = useState<boolean>(false);
    const navMenuRef = useRef<HTMLDivElement>(null)
    const theme = useTheme();
    const context = useContext(AppContext);
    const location = useLocation();

    const logoutWrapperFunction = async () => {
        try {
            await handleLogout();
            handlePageClick(Page.LOGIN)
        } catch (error) {
            console.log(error)
        }
    }

    const pageClickWrapperFunction = (page: Page) => {
        handlePageClick(page);
    }

    useEffect(() => {
        
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        
    }, [navMenuRef]);

    // check clientId (do not show menu for clientId 12 || 13)
    let bShowMenu = true;
    const permissions = context.user.getPermissionList();
    const mappedPermission = permissions.find(
      (permission: Permission) => permission.permissionType === "clientId"
    );
    if (mappedPermission?.permissionValue) {
      const clientId = mappedPermission?.permissionValue;
      if (clientId === '12' || clientId === '13') {
        bShowMenu = false;
      }
    }
  
    return (
        <MainContainer>
            <Container>
                <SidePanel theme={theme}>
                    <HeaderContainer>
                        <ClickableLogo onClick={() => pageClickWrapperFunction(Page.OVERVIEW)} ><LogoImg src={logo} alt="logo" /></ClickableLogo>
                    </HeaderContainer>
                    <EntryContainer>
                        {entries.map(({ page, label }: Entry) => (
                            <SidebarEntry
                                key = {page}
                                onClick = {() => pageClickWrapperFunction(page)}
                                isActive = {location.pathname.includes(page.toLocaleLowerCase())}
                            >
                                {getIconByLabel(page, location.pathname.includes(page.toLocaleLowerCase()))}
                                <Label>{label}</Label>
                            </SidebarEntry>
                        ))}
                    </EntryContainer>
                </SidePanel>
                <NavContainerFrame>
                    <NavContainerRow>
                        {
                            bShowMenu ?
                                <NavMenuIcon hover onClick = {() => handleShowNavMenu(!showNavMenu)}>
                                    menu
                                </NavMenuIcon>
                                :
                                null
                        }
                        <Icon hover onClick={async () => await logoutWrapperFunction()}>logout</Icon>
                    </NavContainerRow>
                    <NavContainer show = {showNavMenu} ref = {navMenuRef}>
                        <NavContainerListItem onClick = {() => handleShowNavMenu(false)} to = '../customer-data'>Kundendaten</NavContainerListItem>
                        <NavContainerListItem onClick = {() => handleShowNavMenu(false)} to = '../service-types'>Leistungsmerkmale</NavContainerListItem>
                        <ExpandableNavContainerList onClick = {handleShowHelpContainer}>
                            <ExpandableNavContainerListItemButton>Hilfe</ExpandableNavContainerListItemButton>
                            <ExpandedNavContainer show = {showHelpContainer}>
                                <ExpandedNavContainerListItem onClick = {() => handleShowNavMenu(false)} to = '../videos'>Videos</ExpandedNavContainerListItem>
                                <ExpandedNavContainerListItem onClick = {() => handleShowNavMenu(false)} to = '../FAQ'>FAQ</ExpandedNavContainerListItem>
                                <ExpandedNavContainerListItem onClick = {() => handleShowNavMenu(false)} to = '../contact-form'>Kontakformular</ExpandedNavContainerListItem>
                            </ExpandedNavContainer>
                        </ExpandableNavContainerList>
                        <NavContainerListItem onClick = {() => handleShowNavMenu(false)} to = '../about'>Über</NavContainerListItem>
                    </NavContainer>
                </NavContainerFrame>
                {children}
            </Container>
        </MainContainer>
    );

    function handleShowHelpContainer(): void {
        setShowHelpContainer(!showHelpContainer)
    }

    function handleShowNavMenu(show: boolean): void {
        setShowNavMenu(show)
    }


    function handleClickOutside(event: MouseEvent): void {
          
        if (navMenuRef.current && !navMenuRef.current.contains(event.target as Node)) {
            handleShowNavMenu(false)
        }

    }
};

export default Sidebar;
