import CompanyHeader from "../components/CompanyHeader";
import BackgroundPrimary from "../containers/BackgroundPrimary";
import React, { useContext, useEffect, useRef } from "react";
import ViewContainer from "../containers/ViewContainer";
import { MainHeadline } from "../components/baseStyles";
import { WithTranslation, withTranslation } from "react-i18next";
import { VideoData, VideoListResponse } from "../communication/interface";
import styled from "styled-components";
import { AppContext } from "../AppContext";
import VideoList from "../components/VideoList";
import { useParams } from "react-router-dom";


const VideoBlock = styled.video`
    flex: 1 1 auto;
    min-width: 500px;
    max-width: 900px;
`

const VideoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 10px;
    overflow-y: auto;
`


type Props = WithTranslation

function VideosPage(props: Props): JSX.Element {

    const { t } = props
    const [videos, setVideos] = React.useState<VideoData[]>([])
    const context = useContext(AppContext)
    const {videoId} = useParams()
    const [loadedVideoId, setLoadedVideoId] = React.useState<string>(String(videoId))
    const sourceRef = useRef<HTMLVideoElement>(null)
    const [videoTitle, setVideoTitle] = React.useState<string>("")

    const currentHost = window.location.host

    useEffect(() => {
        getVideos()
    }, [])

    useEffect(() => {
        if(videoId) {
            if(loadedVideoId !== videoId) {
                reloadVideo()
                setLoadedVideoId(videoId)
            }
            const video = videos.find((video: VideoData) => video.videoId === videoId)
            if(video) {
                setVideoTitle(video.title)
            }
        }
    }, [videos, videoId])

    return(
        <BackgroundPrimary>
            <ViewContainer>
                <CompanyHeader/>
                <MainHeadline>{videoId ? videoTitle : t("videos.title")}</MainHeadline>
                
                <VideoContainer>
                    {videoId ?
                        <VideoBlock ref = {sourceRef} controls>
                            <source src = {"https://" + currentHost + "/SurveyBot/video/" + videoId} type="video/mp4"/>
                            Der Browser unterstützt das Video-Tag nicht.
                        </VideoBlock>
                    : null
                    }
                    <VideoList videoDisplayed = {videoId !== undefined} videos = {videos} currentId = {videoId}/>
                </VideoContainer>

            </ViewContainer>
        </BackgroundPrimary>
    )

    function reloadVideo(): void {
        if(sourceRef.current) {
            sourceRef.current.load()
        }
    }

    function getVideos(): void {

        postVideoList()
            .then(
                (response: VideoListResponse): void => {
                    setVideos(response.videoList)
                }
            )
            .catch(
                (message: string): void => {
                    console.log(message);
                }
            );

    }

    function postVideoList(): Promise<VideoListResponse> {
    
        return new Promise<VideoListResponse>(
            (resolve, reject): void => {

                // get request
                context.restAppService.request_get("video/list", context.user.getToken(),
                    (data: string): void => {
                        const response = JSON.parse(data) as VideoListResponse;
                        resolve(response);
                    },
                    (status: number, text: string): void => {
                        reject("failure " + status + "/" + text);
                    }
                );

            }
        );


    }

}


export default withTranslation()(VideosPage)