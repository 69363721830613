import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Title } from "../baseStyles";
import Button from "../Button";
import { Container, ButtonContainer } from './component';

type Props = {
    message: string,
    onOk: () => void;
} & WithTranslation

const MessageModal = (props: Props) => {
    const {message, onOk, t} = props;
    return (
    <Container>
        <Title>{message}</Title>
        <ButtonContainer>
            <Button secondary onClick={onOk}>{t('button.ok')}</Button>
        </ButtonContainer>
    </Container>
    )
};

export default withTranslation()(MessageModal);