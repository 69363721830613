import React, { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import DateTimePicker from 'react-datetime-picker'
import { Campaign, Questionnaire } from "../../communication/interface";
import { Input, Title } from "../baseStyles";
import Button from "../Button";
import { Container, ButtonContainer, GridContainer, Label } from "./component";
import DropDown from "../DropDown/DropDown";

type Props = {
  campaign: Campaign;
  campaignText: string;
  questionnaires: Questionnaire[];
  onEdit: (campaign: Campaign, campaignText: string) => void;
  onCancel: () => void;
} & WithTranslation;

const CampaignModal = (props: Props) => {

  const { campaign, questionnaires, onEdit, onCancel, t } = props;

  const [startDateDate, setStartDate] = useState(campaign.startDateDate);
  const [endDateDate, setEndDate] = useState(campaign.endDateDate);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(props.campaignText);
  const [level, setLevel] = useState(campaign.level);

  const mappedQuestionnaires = questionnaires.map((questionnaire) => questionnaire.text)

  const getQuestionnaire = () => {
    return questionnaires.find((questionnaire) => questionnaire.text === selectedQuestionnaire);
  }
  // verifies if the new date is valid
  const handleStartDateUpdate = (date: Date | null) => {
    if (date != null) {
      setStartDate(date);
    }
  };

  // verifies if the new date is valid
  const handleEndDateUpdate = (date: Date | null) => {
    if (date !== null) {
      setEndDate(date);
    }
  };

  function handleInputLevel(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(e.target.value);
    if (value >= 1) {
      setLevel(value);
    }
  }

  return (

    <Container>
      <Title>{t("campaign.modal.title")}</Title>
      <GridContainer>

      <Label>{t('questionnaire')}</Label>
      <DropDown value={selectedQuestionnaire} options={mappedQuestionnaires} onChange={setSelectedQuestionnaire}/>
      
      
        <Label>{t("campaign.card.startDate")}:</Label>
        <DateTimePicker
          locale="de-DE"
          value={startDateDate}
          onChange={handleStartDateUpdate}
          disableClock={true}
          showLeadingZeros={true}
          clearIcon={null}
          />
      
      
        <Label>{t("campaign.card.endDate")}:</Label>
        <DateTimePicker
          locale="de-DE"
          value={endDateDate}
          onChange={handleEndDateUpdate}
          disableClock={true}
          showLeadingZeros={true}
          clearIcon={null}
          minDate={campaign.startDateDate}
          />
      
      
        <Label>{t('campaign.level')}</Label>
        <Input type="number" value={level} onChange={(e) => handleInputLevel(e)}/>
      
        </GridContainer>
      <ButtonContainer>
        <Button secondary onClick={onCancel}>{t("button.dismiss")}</Button>
        <Button onClick={() => {
          const questionnaire = getQuestionnaire();
          if(!questionnaire) return null;
          onEdit({
            ...campaign,
            startDateDate: startDateDate,
            endDateDate: endDateDate,
            entryId: questionnaire.entryId,
            level
          }, 
          selectedQuestionnaire)
        }}>{t("button.save")}</Button>
      </ButtonContainer>
    </Container>
  );
};

export default withTranslation()(CampaignModal);
