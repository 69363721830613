/* DateUtil.ts
   Version: 1.0.1
   Date: 2020-12-21
*/


export const DateUtil = {

    getLocalToday: (): Date => {
        const d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    },

    getLocalTomorrow: (): Date => {
        const d = DateUtil.getLocalToday();
        d.setDate(d.getDate() + 1);
        return d;
    },

    getToday: (): Date => {
        const d = new Date();
        d.setHours(0);
        d.setMinutes(0 - d.getTimezoneOffset());
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    },
    getTodayAsString: (): string => { return DateUtil.getToday().toISOString().substring(0, 10); },

    getTomorrow: (): Date => {
        const d = DateUtil.getToday();
        d.setDate(d.getDate() + 1);
        return d;
    },
    getTomorrowAsString: (): string => { return DateUtil.getTomorrow().toISOString().substring(0, 10); },

    getYesterday: (): Date => {
        const d = DateUtil.getToday();
        d.setDate(d.getDate() - 1);
        return d;
    },
    getYesterdayAsString: (): string => { return DateUtil.getYesterday().toISOString().substring(0, 10); },

    getThisMonthBegin: (): Date => {
        const d = DateUtil.getToday();
        d.setDate(1);
        return d;
    },
    getThisMonthBeginAsString: (): string => { return DateUtil.getThisMonthBegin().toISOString().substring(0, 10); },

    getThisMonthEnd: (): Date => {
        const d = DateUtil.getThisMonthBegin();
        d.setMonth(d.getMonth() + 1);
        d.setDate(d.getDate() - 1);
        return d;
    },
    getThisMonthEndAsString: (): string => { return DateUtil.getThisMonthEnd().toISOString().substring(0, 10); },

    getDateAsString: (date: Date): string => { return date.toISOString().substring(0, 10); },

}