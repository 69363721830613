import styled from "styled-components";

const ConditionContainer = styled.div`
display: flex;
flex-direction: row;
width: 100%;
align-items: center;
justify-content: center;
margin: 32px 0;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    max-width: 500px;
    margin: 0 16px;
    padding: 16px 32px;
    border-radius: 8px;
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.black};
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 48px;
    > * {
        &:last-child {
            margin-left: 16px;
        }
    }
`

export {
    Container,
    ConditionContainer,
    ButtonContainer
}