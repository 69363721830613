import React, { useContext, useState} from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { Page } from "../types";
import { ReactComponent as Logo } from '../assets/icons/Surveybot_CMYK.svg';
import { AppContext } from "../AppContext";
import Icon from "../components/Icon";
import { Link } from "react-router-dom";

const BackgroundGreeting = styled.div`
    box-sizing: border-box;
    height: inherit;
    width: inherit;
    background-color: ${props => props.theme.colors.backgroundMain};
    padding: 2em;
    display: flex;
    flex-direction: column;
`

const GreetingTop = styled.div`
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
`

const GreetingLogoRow = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
`


const GreetingLogo = styled.div`
    flex: 0 0 auto;
`

const GreetingTitle = styled.div`
    flex: 1 1 auto;
    display: flex;

    align-items: center;
    justify-content: center;
`

const GreetingBottom = styled.div`
    flex: 1 1 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
`

const Space = styled.div`
    flex: 1 1 auto;
`

const Title = styled.div`
    flex: 0 0 auto;
    font-size: 80px;
    font-weight: bold;
`

interface ErrorObject {
    field: string,      // username, password, login, ...
    message: string,
}


// eslint-disable-next-line @typescript-eslint/no-empty-interface

type Props = {
    pageHandler: (page: Page) => void;
    firstPageHandler: (page: Page) => void;
    messageHandler: (message: string, onOk: () => void) => void;
} & WithTranslation;

function Greeting(props: Props): JSX.Element {

    const { t } = props;

    return (
        <BackgroundGreeting>

            <GreetingTop>
                <GreetingLogoRow>
                    <Space />
                    <GreetingLogo>
                        <Logo />
                    </GreetingLogo>
                </GreetingLogoRow>
                <GreetingTitle>
                    <Title>{t("greeting.welcome")}</Title>
                </GreetingTitle>
            </GreetingTop>

            <GreetingBottom>

                <Link to = {'login'}>login</Link>

            </GreetingBottom>

        </BackgroundGreeting>
    )

}

export default withTranslation()(Greeting);