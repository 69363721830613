import styled from 'styled-components'

type Props = {
    columns: number,
    gap: number
}

const GridContainer = styled.div<Props>`
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    gap: ${props => props.gap? props.gap : 10}px;
    max-width: 1350px;
    min-height: 10px;
    overflow-y: auto;
` 

export default GridContainer;