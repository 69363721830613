import React, { useState, useEffect } from "react";
import { CatalogEntry } from "../../communication/interface";
import {
  DropDownListContainer,
  DropDownList,
  ListItem,
} from "../DropDown/components";
import { Label } from "../LabeledInput/components";
import { Input } from "../baseStyles";
import { Container, Wrapper } from "./components";

type Props = {
  questions: CatalogEntry[];
  label: string;
  value: string;
  entryId: number;
  onChange: (input: string) => void;
  updateFunction: (question: CatalogEntry, entryId: number) => void;
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  maxResults?: number;
};

const ComboBox = (props: Props) => {
  const {
    questions,
    label,
    value,
    onChange,
    updateFunction,
    onKeyPress,
    entryId,
    maxResults,
  } = props;
  const [input, setInput] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const [questionsEmpty, setQuestionsEmpty] = useState(false);

  // const maxCount = maxResults || 15;

  useEffect(() => {
    if(questions.filter((question) => question.text.toLowerCase().match(input.toLowerCase())).length === 0) {
      setQuestionsEmpty(true);
    }
    else {
      setQuestionsEmpty(false);
    }

    if(input === "") {
      setIsOpen(false);
    }
    else {
      setIsOpen(true);
    }
  }, [input])

  const InputWrapperFunction = (input: string) => {
    setInput(input);
    onChange(input);
    setIsOpen(true);
  };

  const handleItemClick = (question: CatalogEntry) => {
    updateFunction(question, entryId);
    setInput(question.text);
    setIsOpen(false);
  };

  function handleUnfocus(): void {
    setIsOpen(false);
  }
  function handleFocus(): void {
    setIsOpen(true);
  }
  return (
    <Container>
      <Wrapper>
        <Label htmlFor={`${label}-label`}>{label}</Label>{" "}
        <Input
          id = {`${label}-input`}
          value = {input}
          onChange = {(event) => InputWrapperFunction(event.target.value)}
          onKeyDown = {onKeyPress}
          onBlur = {handleUnfocus}
          onFocus = {handleFocus}
          autoComplete="off"
        />
      </Wrapper>
      {isOpen && !questionsEmpty && (
        <DropDownListContainer>
          <DropDownList hide = {questionsEmpty}>
            {questions.filter((question) => question.text.toLowerCase().match(input.toLowerCase())).map((question) =>
              <ListItem
                key = {JSON.stringify(question)}
                onMouseDown = {() => handleItemClick(question)}
              >
                {question.text}
              </ListItem>
            )}
          </DropDownList>
        </DropDownListContainer>
      )}
    </Container>
  );
};

export default ComboBox;
