import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import Icon from "../Icon";


const ServiceColumn = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    min-width: 260px;

`

const ServiceHeadingBlock = styled.div`
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    
`

const ServiceHeading = styled.div`
    font-size: 22px;
`
// const ServiceSubHeading = styled.div` 
//     font-size: 18px;
// `
// const ServiceHighlightedTitle = styled.div` 
//     padding: 20px 0;
//     font-size: 18px;
//     color: ${props => props.theme.colors.main};
// `

const InfoColumn = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: start;
    padding: 0 0 40px 0;
`

const InfoListRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 5px 0;
`

const ListIcon = styled(Icon)`
    padding: 0 10px;
`

const ListText = styled.div`
    
`

type Props = {
    type: string;
    phoneNumbers: number;
    phoneLines: number;
    interviews: number;
    amountInterview: number;
} & WithTranslation;
function AccountPlan(props: Props): JSX.Element {


    const { t } = props;

    return (
        <ServiceColumn>
            <ServiceHeadingBlock>
                <ServiceHeading>{t("services." + props.type + ".title") + " Account"}</ServiceHeading>
                {/* <ServiceSubHeading>{t("services.advanced.subTitle")}</ServiceSubHeading> */}
            </ServiceHeadingBlock>
            <InfoColumn>
                <InfoListRow/>
                <InfoListRow>
                    <ListIcon>add</ListIcon>
                    <ListText>{props.phoneNumbers + " " + (props.phoneNumbers === 1 ? t("services.phoneNumber") : t("services.phoneNumbers"))}</ListText>
                </InfoListRow>
                <InfoListRow>
                    <ListIcon>add</ListIcon>
                    <ListText>{props.phoneLines + " " + t("services.channels")}</ListText>
                </InfoListRow>
                <InfoListRow>
                    <ListIcon>add</ListIcon>
                    <ListText>{props.interviews + " " + (props.type === "advanced" ? t("services.feature3.Interview") : t("services.feature3")) + " " + props.amountInterview.toString() + " " + t("upgrade.euro")}</ListText>
                </InfoListRow>
                <InfoListRow>
                    <ListIcon>add</ListIcon>
                    <ListText>{t("services." + props.type + ".feature4")}</ListText>
                </InfoListRow>
                <InfoListRow>
                    <ListIcon>add</ListIcon>
                    <ListText>{t("services." + props.type + ".feature5")}</ListText>
                </InfoListRow>
                {
                    t("services." + props.type + ".feature6") === ("services." + props.type + ".feature6") ? null :
                    <InfoListRow>
                        <ListIcon>add</ListIcon>
                        <ListText>{t("services." + props.type + ".feature6")}</ListText>
                    </InfoListRow>
                }
            </InfoColumn>
        </ServiceColumn>
    )
}



export default withTranslation()(AccountPlan)