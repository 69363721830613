import React, { useContext } from 'react'
import { AppContext } from '../AppContext'
import { useLocation, Navigate } from 'react-router-dom'

export default function ProtectedRoute( {children} : {children: React.ReactNode} ) {

    const ctx = useContext(AppContext)
    const location = useLocation()

    if(!ctx.user.isLoggedIn()) {
        return <Navigate to = {'/SurveyBot/login'} replace state = {{ from: location }}/>
    }
    else {
        localStorage.setItem('lastLocation', location.pathname)
    }

    return(
        <>
            {children}
        </>
    )
}