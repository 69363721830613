import { WithTranslation, withTranslation } from "react-i18next"
import React from "react"
import BackgroundPrimary from "../containers/BackgroundPrimary";
import ViewContainer from "../containers/ViewContainer";
import CompanyHeader from "../components/CompanyHeader";
import styled from "styled-components";
import ShadowContainer from "../components/Container/ShadowContainer";


const InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 40px 20px;
`

const InfoElement = styled.div`
    padding: 10px;
    color: ${props => props.theme.colors.darkGrey};
`
const InfoLink = styled.a`
    color: ${props => props.theme.colors.main};
`

const TitleRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
`

const Title = styled.div`
    font-size: 36px;
    font-weight: bold;
    color: black;
    padding: 20px 0 5px 0;
`




type Props = {
    children?: React.ReactNode;
} & WithTranslation
function About(props: Props): JSX.Element {

    const { t } = props;
    return (
        <BackgroundPrimary>
            <CompanyHeader/>

            <InfoColumn>
                <Title>{t("about.title")}</Title>
                <ShadowContainer>
                    <InfoElement>SurveyBot 1.0</InfoElement>
                    <InfoElement>
                        <InfoLink href="https://www.surveybot.de/">surveybot.de</InfoLink>
                    </InfoElement>
                </ShadowContainer>
            </InfoColumn>
        </BackgroundPrimary>
    )
}


export default withTranslation()(About)