import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: space-around;
    margin: 10px 0;
`
const Label = styled.label`
    margin-right: 7px;
`;

const Input = styled.input`
    margin: 3px 7px 0 7px;
`

const ErrorMessage = styled.div`
align-self: flex-end;
margin-right: 6px;
    color: ${props => props.theme.colors.errorRed}
`

export {
    Container,
    Wrapper,
    Label,
    Input,
    ErrorMessage,
}