import React, { Component } from "react";
import { CatalogEntry, EntryType } from "../../communication/interface";
import DropDown from "../DropDown/DropDown";
import { getEntryType, validEntryTypes } from '../../data/validEntryTypes';
import Button from "../Button";
import { PlusIcon } from "../QuestionnaireTable/ActionIcon";
import {Container, ButtonElementContainer, Label} from './components';
import { WithTranslation, withTranslation } from "react-i18next";
import { Input } from "../baseStyles";

type Props = {
    onAdd: (question: CatalogEntry) => void,
    clientId: number,
} & WithTranslation

type State = {
    input: string,
    entryType: string
}

class QuestionInput extends Component<Props, State>{
    constructor(props: Props) {
        super(props);
        const { t } = this.props;
        this.state= {
            input: '',
            entryType: t("questionType." + EntryType.COMMENT) 
        }
    }

    private createNewQuestion = async() => {
        const {clientId, onAdd} = this.props;
        const {input, entryType} = this.state;
        const newQuestion: CatalogEntry = {
            clientId,
            catalogId: 0,
            text: input,
            entryType: getEntryType(entryType)
        }
        await onAdd(newQuestion);

        this.setState({input: '', entryType: EntryType.COMMENT})
    }

    render() {
        const {input, entryType} = this.state;
        const {t} = this.props;
        const questionTypes = validEntryTypes.map<string>( (entry) => t("questionType." + entry) );

        return (
            <Container>
                <Input
                        id={`input`}
                        value={input}
                        onChange={(event) => this.setState({input: event.target.value})}
                        />
                <DropDown openUpwards value={entryType} options={questionTypes} onChange={(value) => this.setState({entryType: value})}/>
                <Button disabled={input === ''} onClick={() => input === '' ? null: this.createNewQuestion()}>
                    <ButtonElementContainer>
                    <PlusIcon onClick={() => null} />
                    <Label>{t('button.create')}</Label>
                    </ButtonElementContainer>
                </Button>
            </Container>
        )
    }
}

export default withTranslation()(QuestionInput);