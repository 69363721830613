import styled from "styled-components"
import React from "react"
import { VideoData } from "../communication/interface"
import { useNavigate } from "react-router-dom";


interface VideoListFrameProps {
    wide: boolean;
}
const VideoListFrame = styled.div<VideoListFrameProps>`
    flex: ${props => props.wide ? '1' : '0'} 1 auto;
    width: min-content;
    min-width: 600px;
    height: 100%;
    overflow-y: auto;
    padding: 0 20px;
`

const VideoListColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 10px 0;
    max-width: 1900px;
    min-height: 10px;
`

interface VideoEntryProps {
    first?: boolean;
}
const VideoEntry = styled.div<VideoEntryProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-top: solid ${props => props.first ? '0' : '1'}px ${props => props.theme.colors.lightGrey};
    color: ${props => props.theme.colors.darkGrey};

    cursor: pointer;
    &:hover {
        color: ${props => props.theme.colors.black};
        background-color: ${props => props.theme.colors.light};
    }
`

const VideoEntryInfo = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-left: 20px;
`


interface VideoEntryTitleProps {
    current: boolean;
}
const VideoEntryTitle = styled.div<VideoEntryTitleProps>`
    font-size: 24px;
    font-weight: bold;
    text-decoration: ${props => props.current ? 'underline' : 'none'};
`

const VideoEntryDescription = styled.div`
    font-size: 16px;
`

const VideoEntryThumbnail = styled.img`
    width: 160px;
    height: 90px;
    padding-right: 20px;
`



interface Props {
    videos: VideoData[],
    currentId?: string,
    videoDisplayed: boolean,
}
export default function VideoList(props: Props): JSX.Element {

    const navigate = useNavigate()
    const currentHost = window.location.host

    return(
        <VideoListFrame wide = {!props.videoDisplayed}>
            <VideoListColumn>
                {props.videos.map((video: VideoData, index: number) => {

                    return(
                        <VideoEntry first = {index === 0} onClick = {() => handleVideoClick(video.videoId)}>
                            <VideoEntryThumbnail src = {"https://" + currentHost + "/SurveyBot/img/" + video.videoId}/>
                            <VideoEntryInfo>
                                <VideoEntryTitle current = {props.currentId === video.videoId}>{video.title}</VideoEntryTitle>
                                <VideoEntryDescription>{video.description}</VideoEntryDescription>
                            </VideoEntryInfo>
                        </VideoEntry>
                    )
                })

                }
            </VideoListColumn>
        </VideoListFrame>
    )

    function handleVideoClick(videoId: string): void {
        if(videoId === props.currentId) return
        navigate(`../videos/` + videoId)
    }
}