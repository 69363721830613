import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 550px;
  height: 400px;
  border-radius: 8px;
  margin: 0 15px;
  padding: 0 15px;
  align-items: flex-start;
  justify-content: space-around;
  background-color: ${(props) => props.theme.colors.white};
  top: 33%;
  left: 40%;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  width: 100%;
  margin-top: 24px;
  > * {
    &:last-child{
      margin-left: 16px;
    }
  }
`;


const Label = styled.div`
  padding: 0 5px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 20px;
  grid-row-gap: 25px;
  width: 100%;
  align-items: center;
`
export { Container, ButtonContainer, GridContainer, Label };
