import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { AppContext } from "../AppContext";
import BackgroundPrimary from "../containers/BackgroundPrimary";
import ViewContainer from "../containers/ViewContainer";
import {
    MainHeadline,
    Modal,
    ModalContainer,
    Title,
} from "../components/baseStyles";
import CampaignCard from "../components/CampaignCard/CampaignCard";
import CampaignModal from "../components/CampaignModal/CampaignModal";
import DeletePromptModal from "../components/DeletePromptModal/DeletePromptModal";
import FlexContainer from "../components/FlexContainer";
import { PlusIcon } from "../components/QuestionnaireTable/ActionIcon";
import {
    Campaign,
    Campaigns,
    CampaignCampaignEntriesResponse,
    Questionnaire,
    QuestionnaireOverviewRequest,
    QuestionnaireOverviewResponse,
    CampaignCampaignEntriesRequest,
    CampaignSaveResponse,
    CampaignSaveRequest,
    CampaignDeleteRequest,
    CampaignCampaignIdResponse,
    CampaignCampaignIdRequest,
} from "../communication/interface";
import { Permission } from "../data/User";

import styled from "styled-components";
import Spacer from "../containers/Spacer";
import CompanyHeader from "../components/CompanyHeader";
import { DateUtil } from "../util/DateUtil";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: "row";
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #aba7af;
  margin-bottom: 24px;
`;

type Props = WithTranslation;

type State = {
    campaigns: Campaigns[] | null;
    questionnaires: Questionnaire[] | null;
    selectedCampaign: Campaign | null;
    selectedCampaigns: Campaign[] | null;
    showEditModal: boolean;
    showDeletePrompt: boolean;
    deleteErrorMessage: string;
};

class CampaignPage extends Component<Props, State> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: Props) {

        super(props);

        // set state
        this.state = {
            campaigns: null,
            questionnaires: null,
            selectedCampaign: null,
            selectedCampaigns: null,
            showEditModal: false,
            showDeletePrompt: false,
            deleteErrorMessage: "",
        };

    }

    componentDidMount = async () => {

        // get campaigns
        try {

            const permissions = this.context.user.getPermissionList();
            const mappedPermission = permissions.find(
                (permission: Permission) => permission.permissionType === "clientId"
            );
            if (mappedPermission && mappedPermission.permissionValue) {

                // get questionnaires
                const result = await this.getQuestionareOverview( +mappedPermission.permissionValue );

                // get campaigns
                const campaignResponse = await this.getCampaigns( +mappedPermission.permissionValue );

                // set startDateDate and endDateDate
                for (const campaigns of campaignResponse.campaignEntries) {
                    for (const campaign of campaigns.campaigns) {
                        campaign.startDateDate = new Date();
                        campaign.startDateDate.setTime(campaign.startDate);
                        campaign.endDateDate = new Date();
                        campaign.endDateDate.setTime(campaign.endDate);
                    }
                }

                this.setState({
                    questionnaires: result.questionnaires,
                    campaigns: campaignResponse.campaignEntries.sort( (a, b): number => { return a.phoneNumber - b.phoneNumber } ),
                });
            }

        } catch (error) {
            console.error(error);
            this.setState({ campaigns: null });
        }

    };

    // creates a template campaign to be edited by the user
    private createNewCampaign = async (phoneNumber: number) => {
        const defaultCampaign = this.state.campaigns?.find(
            (campaign) => campaign.phoneNumber === phoneNumber
        );

        if (!defaultCampaign) return;

        const response = await this.postCampaignCampaignId(defaultCampaign.clientId);
        const newCampaignId = response.campaignId;
        const startDate = DateUtil.getLocalToday();
        const endDate = DateUtil.getLocalTomorrow();
        const newCampaign: Campaign = {
            clientId: defaultCampaign.clientId,
            campaignId: newCampaignId,
            telephone: defaultCampaign.phoneNumber.toString(),
            startDate: startDate.getTime(),
            endDate: endDate.getTime(),
            timezone: 'Europe/Berlin',
            level: 1,
            entryId: 0,
            startDateDate: startDate,
            endDateDate: endDate,
        };
        this.setState({ showEditModal: true, selectedCampaign: newCampaign });
    };

    // find questionnaire text based on entryId
    private findQuestionnaireText = (entryId: number): string => {

        let retVal = '';

        if (this.state.questionnaires) {
            for (const questionnaire of this.state.questionnaires) {
                if (entryId === questionnaire.entryId) {
                    retVal = questionnaire.text;
                }
            }
        }

        return retVal;

    }

    // find entryId based on questionnaire text
    private findQuestionnaireEntryId = (questionnaireText: string): number => {

        let retVal = 0;

        if (this.state.questionnaires) {
            for (const questioannaire of this.state.questionnaires) {
                if (questionnaireText === questioannaire.text) {
                    retVal = questioannaire.entryId;
                }
            }
        }

        return retVal;

    }

    // HANDLERS, sanitize inputs and call requests =============================

    private handleSave = async (campaign: Campaign, campaignText: string) => {
        try {

            const result = await this.saveCampaign(campaign, campaignText);
            if (!result) throw new Error("UPDATE FAILED");

            const campaignResponse = await this.getCampaigns(campaign.clientId);

            // set startDateDate and endDateDate
            for (const campaigns of campaignResponse.campaignEntries) {
                for (const campaign of campaigns.campaigns) {
                    campaign.startDateDate = new Date();
                    campaign.startDateDate.setTime(campaign.startDate);
                    campaign.endDateDate = new Date();
                    campaign.endDateDate.setTime(campaign.endDate);
                }
            }
            
            // set state
            this.setState({ campaigns: campaignResponse.campaignEntries, showEditModal: false, selectedCampaign: null, })

        } catch (error) {
            console.error(error);
        }
    }

    private handleDelete = async (campaign: Campaign, campaigns: Campaign[]) => {
        if(campaigns.length <= 1) {
            this.setState({
                deleteErrorMessage: "campaign.deleteLastErrorMessage",
            })
            return;
        }
        try {
            
            const result = await this.putDeleteCampaign(campaign);
            if (result === false) throw new Error("DELETE FAILED");

            const campaignResponse = await this.getCampaigns(campaign.clientId);

            // set startDateDate and endDateDate
            for (const campaigns of campaignResponse.campaignEntries) {
                for (const campaign of campaigns.campaigns) {
                    campaign.startDateDate = new Date();
                    campaign.startDateDate.setTime(campaign.startDate);
                    campaign.endDateDate = new Date();
                    campaign.endDateDate.setTime(campaign.endDate);
                }
            }
            
            // set state
            this.setState({ campaigns: campaignResponse.campaignEntries, selectedCampaign: null, showDeletePrompt: false })

        } catch (error) {
            console.error(error);
        }
    }

    private handleCancel = () => {
        this.setState({
            showDeletePrompt: false,
            selectedCampaign: null,
            deleteErrorMessage: "",
        })
    }


    // REQUESTS ================================================================

    private getQuestionareOverview = (clientId: number): Promise<QuestionnaireOverviewResponse> => {

        return new Promise<QuestionnaireOverviewResponse>( (resolve, reject) => {

            // build request
            const request: QuestionnaireOverviewRequest = {
                clientId,
            };

            // post request
            this.context.restAppService.request_post(
                "questionnaire/overview",
                this.context.user.getToken(),
                "text/json",
                JSON.stringify(request),
                (data: string): void => {
                    const response = JSON.parse(data) as QuestionnaireOverviewResponse;
                    resolve(response);
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }
            );

        });

    };

    private getCampaigns = (clientId: number): Promise<CampaignCampaignEntriesResponse> => {

        return new Promise<CampaignCampaignEntriesResponse>( (resolve, reject) => {

            // build request
            const request: CampaignCampaignEntriesRequest = {
                clientId,
            };

            // post request
            this.context.restAppService.request_post("campaign/campaignEntries", this.context.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const response = JSON.parse(data) as CampaignCampaignEntriesResponse;
                    resolve(response);
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }
            );

        });

    };

    private saveCampaign = (campaign: Campaign, campaignText: string): Promise<CampaignSaveResponse> => {

        return new Promise<CampaignSaveResponse>((resolve, reject) => {

            // build request
            campaign.startDate = campaign.startDateDate.getTime();
            campaign.endDate = campaign.endDateDate.getTime();
            campaign.entryId = this.findQuestionnaireEntryId(campaignText);
            const request: CampaignSaveRequest = {
                clientId: campaign.clientId,
                campaign,
            };

            // post request
            this.context.restAppService.request_post(
                "campaign/save",
                this.context.user.getToken(),
                "text/json",
                JSON.stringify(request),
                (data: string): void => {
                    const response = JSON.parse(data) as CampaignSaveResponse;
                    resolve(response);
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }
            );

        });

    };

    private postCampaignCampaignId = (clientId: number): Promise<CampaignCampaignIdResponse> => {

        return new Promise<CampaignCampaignIdResponse>( (resolve, reject) => {

            // build request
            const request: CampaignCampaignIdRequest = {
                clientId
            };

            // post request
            this.context.restAppService.request_post('campaign/campaignId', this.context.user.getToken(), 'text/json', JSON.stringify(request),
                (data: string): void => {
                    const response = JSON.parse(data) as CampaignCampaignIdResponse;
                    resolve(response);
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }

            )

        });

    }


    private putDeleteCampaign = (campaign: Campaign): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            // build request
            const request: CampaignDeleteRequest = {
                clientId: campaign.clientId,
                campaignId: campaign.campaignId,
            };

            // post request
            this.context.restAppService.request_put(
                "campaign/delete",
                this.context.user.getToken(),
                "text/json",
                JSON.stringify(request),
                (): void => {
                    resolve(true);
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }
            );
        });
    };

    private handleDeleteCampaign = (campaignEntry: Campaign, campaigns: Campaign[]) => {
        if(campaigns.length <= 1) return;
        this.setState({
            showDeletePrompt: true,
            selectedCampaign: campaignEntry,
        })
    }


    // RENDER ==================================================================

    render(): React.ReactNode {

        const {
            campaigns,
            questionnaires,
            showEditModal,
            selectedCampaign,
            selectedCampaigns,
            showDeletePrompt,
            deleteErrorMessage,
        } = this.state;

        const { t } = this.props;
        
        return (
            <BackgroundPrimary>
                <ViewContainer>
                    <CompanyHeader/>
                    <MainHeadline>{t("campaign.headline")}</MainHeadline>
                    {campaigns?.map((campaign, idx) => (
                        <React.Fragment key={idx}>
                            <HeaderContainer>
                                <Title>
                                    {t("campaign.PhoneNumber")} 09131 {campaign.phoneNumber}
                                </Title>
                                <PlusIcon
                                    onClick={async () => await this.createNewCampaign(campaign.phoneNumber)}
                                />
                            </HeaderContainer>
                            <FlexContainer direction="row">
                                <Spacer>
                                    {campaign.campaigns.map((entry) => (
                                        <CampaignCard
                                            key={entry.campaignId}
                                            campaign={entry}
                                            campaignText={this.findQuestionnaireText(entry.entryId)}
                                            onEdit={() =>
                                                this.setState({
                                                    showEditModal: true,
                                                    selectedCampaign: entry,
                                                })
                                            }
                                            onDelete={() =>
                                                this.setState({
                                                    showDeletePrompt: true,
                                                    selectedCampaign: entry,
                                                    selectedCampaigns: campaign.campaigns,
                                                })
                                            }
                                        />
                                    ))}
                                </Spacer>
                            </FlexContainer>
                        </React.Fragment>
                    ))}
                </ViewContainer>
                {selectedCampaign && showEditModal && (
                    <ModalContainer>
                        <Modal show={showEditModal}>
                            <CampaignModal
                                campaign={selectedCampaign}
                                campaignText={this.findQuestionnaireText(selectedCampaign.entryId)}
                                questionnaires={questionnaires || []}
                                onEdit={this.handleSave}
                                onCancel={() =>
                                    this.setState({
                                        showEditModal: false,
                                        selectedCampaign: null,
                                    })
                                }
                            />
                        </Modal>
                    </ModalContainer>
                )}
                {selectedCampaign && showDeletePrompt && (
                    <ModalContainer>
                        <Modal show={showDeletePrompt}>
                            <DeletePromptModal
                                question = {(selectedCampaigns || []).length <= 1 ? t("campaign.deleteLast") : t("campaign.delete")}
                                onDelete = {() => this.handleDelete(selectedCampaign, selectedCampaigns || [])}
                                onCancel = {() => this.handleCancel()}
                                errorMessage = {String(t(deleteErrorMessage))}
                                actionNotPossible = {(selectedCampaigns || []).length <= 1}
                            />
                        </Modal>
                    </ModalContainer>
                )}
            </BackgroundPrimary>
        );
    }
}

export default withTranslation()(CampaignPage);
