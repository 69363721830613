import styled from 'styled-components'

type FlexProps = {
    direction: string;
}

const FlexContainer = styled.div<FlexProps>`
    display: flex;
    flex-direction: ${props => props.direction? props.direction : 'row'};
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`
export default FlexContainer;