/* AppContext.tsx
   Version: 1.0.0
   Date: 2020-12-10
*/

import { RestClient } from '@sympalog/communication';
import { User } from '@sympalog/user';
import React from 'react';

import { Client } from './communication/interface';

export interface AppContextData {
    client: Client,
    user: User,
    restAppService: RestClient;
    restSymDialogService: RestClient;
}

interface AppProviderProps {
    ctx: AppContextData,
    children?: React.ReactNode,
}

export const AppContext = React.createContext( {} as AppContextData );

export function AppProvider( props: AppProviderProps ): JSX.Element {

    return(
        <AppContext.Provider value={props.ctx}>
            {props.children}
        </AppContext.Provider>
    );

}

export const AppConsumer = AppContext.Consumer;