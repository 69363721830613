/* DeepCopy.ts
   Version: 0.9.0
   Date: 2020-12-20
*/

/* eslint-disable @typescript-eslint/no-explicit-any */

export const deepCopy = <T>(target: T): T => {

    // check for 'null'
    if (target === null) {
        return target;
    }

    // check for array
    if (target instanceof Array) {
        const cp = [] as any[];
        target.forEach( v => { cp.push(deepCopy(v)) });
        return cp as any;
    }

    // check for object
    if (typeof target === 'object') {
        const cp = { ...(target as { [key: string]: any }) } as { [key: string]: any };
        Object.keys(target).forEach( key => { cp[key] = deepCopy(cp[key]) }); 
        return cp as T;
    }

    return target;

}