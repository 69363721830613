import styled from 'styled-components';
import { Page } from '../../types';

type SideBarEntryProps = {
    onClick: (page: Page) => void;
    key: string;
    isActive: boolean;
}

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const Container = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;
`
const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
`

const EntryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
`

const SidePanel = styled.div`
    flex: 0 0 auto;
    width: 320px;
    background-color: ${props => props.theme.colors.white};
    border: none;
    display: flex;
    flex-direction: column;
`

const SidebarEntry = styled.div<SideBarEntryProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    text-align: center;
    height: 20px;
    width: 100%;
    padding: 16px 16px 16px 48px;
    background-color: ${props => props.isActive? props.theme.colors.lightGrey : props.theme.colors.white};
    color: ${props => props.theme.colors.black};
`

const Label = styled.div`
    padding-left: 10px;
    flex: 1 1 auto;
    text-align: start;
    height: inherit;
    cursor: pointer;
    
    &:hover {
        text-decoration: underline;
    }
`

export {Container, EntryContainer, HeaderContainer, SidebarEntry, SidePanel, MainContainer, Label}
