import { WithTranslation, withTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import React, { useContext, useMemo, useState } from "react";
import BackgroundPrimary from "../containers/BackgroundPrimary";
import styled from "styled-components";
import { ReactComponent as Logo } from '../assets/icons/Surveybot_CMYK.svg';
import LoadingIcon from "../components/loadingIcon";
import { AccountVerificationRequest, AccountVerificationResponse } from "../communication/interface";
import { AppContext } from "../AppContext";

const LogoContainer = styled.div`
    position: fixed;
    top: 2em;
    right: 2em;
    z-index: 100;
`

const Frame = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const LoginButton = styled(Link)`
    all: unset;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    margin-top: 15px;  
    margin-bottom: 5px;
    cursor: pointer;  
    
    border-radius: 100px;
    background-color: ${props => props.theme.colors.main};
    color: ${props => props.theme.colors.white};

    &:hover {
        filter: brightness(1.1);
    }

    &:focus {
        outline: solid 1px ${props => props.theme.colors.light};
    }
` 

type Props = Record<string,never> & WithTranslation;

function RegisterConfirm(props: Props): JSX.Element {

    const [verifiedState, setVerifiedState] = useState<number>(0) // 0: loading, 1: success, 2: fail

    const { confirmationID } = useParams()
    const { t } = props
    const context = useContext(AppContext)

    useMemo(() => {
        verifyConfirmationID()
    }, [])

    return(
        <BackgroundPrimary>
            <Frame>
                {verifiedState === 0 ? 
                    <LoadingIcon/>
                    :
                    <>
                        {
                            verifiedState === 1 ?
                            <>
                                <>
                                    {t("register.confirm")}
                                </>
                                <LoginButton to = {"../login"}>{t("register.toLogin")}</LoginButton>
                            </>
                            :
                            <>
                                {t("register.confirmFail")}
                                <LoginButton to = {"../login"}>{t("register.back")}</LoginButton>
                            </>
                        }
                    </>
                }
            </Frame>

            <LogoContainer>
                <Logo />
            </LogoContainer>
        </BackgroundPrimary>
    )


    function verifyConfirmationID() {
        
        // setTimeout(() => {setVerifiedState(1)}, 500)
        postAccountVerification(String(confirmationID))
            .then((result) => {
                console.log("result: " + result.message)
                setVerifiedState(1)
            })
            .catch((result) => {
                console.log("fail: " + result.message)
                setVerifiedState(2)
            })

    }


    function postAccountVerification(code: string): Promise<AccountVerificationResponse> {

        return new Promise<AccountVerificationResponse> (
            (resolve, reject): void => {

                const request: AccountVerificationRequest = {
                    confirmationId: code,
                }

                context.restAppService.request_post('account/verify', context.user.getToken(), 'text/json', JSON.stringify(request),
                    (data): void => {
                        const response = {message: data}
                        resolve(response)
                    },
                    (status, message): void => {
                        reject({
                            status: status,
                            message: message,
                        })
                    }
                )
            }
        )

    }

}



export default withTranslation()(RegisterConfirm);