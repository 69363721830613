import "./App.css";
import React, { useRef, useState } from "react";
import "./i18n";
import { ThemeProvider } from "styled-components";
import { Page, Entry } from "./types";
import theme from "./theme";
import { AppContextData, AppProvider } from "./AppContext";
import Login from "./pages/Login";
import OverviewPage from "./pages/Overview";
import PageContainer from "./containers/PageContainer";
import Catalog from "./pages/Catalog";
import Campaign from "./pages/Campaign";
import ExportPage from "./pages/Exports";
import AppContainer from "./containers/AppContainer";
import { ClientGetRequest, ClientGetResponse } from "./communication/interface";
import { Permission } from "./data/User";
import { WithTranslation, withTranslation } from "react-i18next";
import ResetPassword from "./pages/ResetPassword";
import Register from "./pages/Register";
import { Modal, ModalContainer } from "./components/baseStyles";
import MessageModal from "./components/MessageModal/MessageModal";
import Greeting from "./pages/Greeting";
import Reports from "./pages/Report";
import { RealRestClient, RestClient } from "@sympalog/communication";
import { User } from "@sympalog/user";
import Monitor from "./pages/Monitor";
import { createBrowserRouter, Outlet, RouterProvider, Navigate } from "react-router-dom";
import RegisterConfirm from "./pages/RegisterConfirm";
import ProtectedRoute from "./components/protectedRoute";
import ServiceTypes from "./pages/ServiceTypes";
import VideosPage from "./pages/Videos";
import ContactFormPage from "./pages/ContactForm";
import CancellationFormPage from "./pages/CancellationForm";
import UpgradePage from "./pages/Upgrade";
import CustomerData from "./pages/CustomerData";
import FAQ from "./pages/FAQ";
import About from "./pages/About";


// TODO move all imports to symshare from interface.ts

// eslint-disable-next-line @typescript-eslint/ban-types
type AppProps = {} & WithTranslation;

function App(props: AppProps) {

    const appName = 'SurveyBot';

    // create rest client
    let restSymDialogService: React.MutableRefObject<RestClient>;
    let restAppService: React.MutableRefObject<RestClient>;
    if(window.location.port !== '3000') {
        restAppService = useRef(new RealRestClient(window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/' + appName + '/api/'))
		restSymDialogService = useRef(new RealRestClient(window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/SymDialog/api/'))
    } 
    else {
        // restClient = new RealRestClient('https://localhost:14009/' + appName + '/api/');
		// restSymDialog = new RealRestClient('https://localhost:14009/SymDialog/api/');
        restAppService = useRef(new RealRestClient('https://rz1.sympalog.net:16009/' + appName + '/api/'))
        restSymDialogService = useRef(new RealRestClient('https://rz1.sympalog.net:16009/SymDialog/api/'))
    }

    const ctxRef = useRef<AppContextData>({
        client: { clientId: 0, name: 'n/a', email: '', telephoneList: [] }, 
        user: new User('/' + appName, restSymDialogService.current), 
        restAppService: restAppService.current, 
        restSymDialogService: restSymDialogService.current,
    })


    const [showMessage, setShowMessage] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [onOk, setOnOk] = useState<() => void>(() => {
        // Do Nothing
    })


    // REQUESTS ==================================================================

    function postClientGet(clientId: number): Promise<ClientGetResponse> {

        return new Promise<ClientGetResponse>( (resolve, reject) => {

            // build request
            const request: ClientGetRequest = { clientId };

            // post request
            ctxRef.current.restAppService.request_post("client/get", ctxRef.current.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const response = JSON.parse(data) as ClientGetResponse;
                    resolve(response);
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }
            );

        });

    }


    // RENDER ====================================================================

    const { t } = props;
    const entries: Entry[] = [
        { page: Page.CATALOG, label: t("sidebar.catalog") },
        { page: Page.OVERVIEW, label: t("sidebar.overview") },
        { page: Page.CAMPAIGNS, label: t("sidebar.campaign") },
        //{ page: Page.SURVEYS, label: "Surveys" },
        { page: Page.MONITOR, label: t("sidebar.monitor") },
        { page: Page.REPORT, label: t("sidebar.report") },
        { page: Page.EXPORTS, label: t("sidebar.exports") },
        //{ page: Page.CLIENTS, label: "Clients" },
    ];

    const router = createBrowserRouter([
        {
            path: 'SurveyBot',
            element:
                <AppContainer>
                    <Outlet/>
                </AppContainer>,
            children: [
                {
                    index: true,
                    element:
                        <Navigate to = {'./login'} replace/>
                },
                {
                    path: '',
                    element: <ProtectedRoute>
                        <Greeting
                            pageHandler = {handlePageChange}
                            firstPageHandler = {handleFirstPageChange}
                            messageHandler = {handleShowMessage}
                        />
                    </ProtectedRoute>
                },
                {
                    path: 'login',
                    element: <Login pageHandler = {handleFirstPageChange} />
                },
                {
                    path: 'overview',
                    element: <ProtectedRoute>
                        <PageContainer entries = {entries} pageHandler = {handlePageChange}>
                            <OverviewPage />
                        </PageContainer>
                    </ProtectedRoute>
                },
                {
                    path: 'catalog',
                    element: <ProtectedRoute>
                        <PageContainer entries = {entries} pageHandler = {handlePageChange}>
                            <Catalog />
                        </PageContainer>
                    </ProtectedRoute>
                },
                {
                    path: 'campaigns',
                    element: <ProtectedRoute>
                        <PageContainer entries = {entries} pageHandler = {handlePageChange}>
                            <Campaign />
                        </PageContainer>,
                    </ProtectedRoute>
                },
                {
                    path: 'monitor',
                    element: <ProtectedRoute>
                        <PageContainer entries = {entries} pageHandler = {handlePageChange}>
                            <Monitor />
                        </PageContainer>
                    </ProtectedRoute>

                },
                {
                    path: 'reports',
                    element: <ProtectedRoute>
                        <PageContainer entries = {entries} pageHandler = {handlePageChange}>
                            <Reports />
                        </PageContainer>
                    </ProtectedRoute>

                },
                {
                    path: 'exports',
                    element: <ProtectedRoute>
                        <PageContainer entries = {entries} pageHandler = {handlePageChange}>
                            <ExportPage />
                        </PageContainer>
                    </ProtectedRoute>

                },
                {
                    path: 'service-types',
                    element: <ProtectedRoute>
                        <PageContainer entries = {entries} pageHandler = {handlePageChange}>
                            <ServiceTypes/>
                        </PageContainer>
                    </ProtectedRoute>
                },
                {
                    path: 'videos/:videoId?',
                    element: <ProtectedRoute>
                        <PageContainer entries = {entries} pageHandler = {handlePageChange}>
                            <VideosPage/>
                        </PageContainer>
                    </ProtectedRoute>
                },
                {
                    path: 'contact-form',
                    element: <ProtectedRoute>
                        <PageContainer entries = {entries} pageHandler = {handlePageChange}>
                            <ContactFormPage/>
                        </PageContainer>
                    </ProtectedRoute>
                },
                {
                    path: 'cancellation',
                    element: <ProtectedRoute>
                        <PageContainer entries = {entries} pageHandler = {handlePageChange}>
                            <CancellationFormPage/>
                        </PageContainer>
                    </ProtectedRoute>
                },
                {
                    path: 'customer-data',
                    element: <ProtectedRoute>
                        <PageContainer entries = {entries} pageHandler = {handlePageChange}>
                            <CustomerData/>
                        </PageContainer>
                    </ProtectedRoute>
                },
                {
                    path: 'FAQ',
                    element: <ProtectedRoute>
                        <PageContainer entries = {entries} pageHandler = {handlePageChange}>
                            <FAQ/>
                        </PageContainer>
                    </ProtectedRoute>
                },
                {
                    path: 'about',
                    element: <ProtectedRoute>
                        <PageContainer entries = {entries} pageHandler = {handlePageChange}>
                            <About/>
                        </PageContainer>
                    </ProtectedRoute>
                },
                {
                    path: 'upgrade/:plan',
                    element: <ProtectedRoute>
                        <UpgradePage/>
                    </ProtectedRoute>
                },
                {
                    path: 'reset-password/:confirmationID',
                    element:
                        <ResetPassword 
                            pageHandler = {handlePageChange} 
                            messageHandler = {handleShowMessage}
                        />
                },
                {
                    path: 'register',
                    element:
                        <Register/>
                },
                {
                    path: 'register-confirm/:confirmationID',
                    element:
                        <RegisterConfirm/>
                }
            ]
        }
    ])

    return (
        <ThemeProvider theme={theme}>
            <AppProvider ctx={ctxRef.current}>
                <AppContainer>
                    <RouterProvider router = { router } />
                </AppContainer>
                {showMessage && (
                    <ModalContainer>
                        <Modal show = {showMessage}>
                            <MessageModal 
                                message = {message} 
                                onOk = { 
                                    (): void => {
                                        setShowMessage(false)
                                        onOk()
                                    }   
                                } />
                        </Modal>
                    </ModalContainer>
                )}
            </AppProvider>
        </ThemeProvider>
    );


    function handlePageChange(page: Page): void {

        const url = '/' + page.toLowerCase()
        // console.log("redirecting " + url)

    }

    function handleFirstPageChange(page: Page) {

        // get clientId from user settings
        const permissions = ctxRef.current.user.getPermissionList();
        const mappedPermission = permissions.find( (permission: Permission) => permission.permissionType === "clientId" );
        
        if (mappedPermission) {

            // get client
            postClientGet(Number.parseInt(mappedPermission.permissionValue))
                .then(
                    (response: ClientGetResponse): void => {
                        // console.log("got client with name: " + response.client.name)
                        ctxRef.current.client = response.client;
                        handlePageChange(page);
                    }
                )
                .catch(
                    (message: string): void => {
                        console.log(message);
                    }
                );

        }
        else if (page === Page.RESETPASSWORD) {
            handlePageChange(page);
        }
        else if (page === Page.REGISTER) {
            handlePageChange(page);
        }
        else if (page === Page.GREETING) {
            handlePageChange(page);
        }
        else {
            console.log('user has no clientId');
        }
        // setTimeout(() => {

        // }, 200)

    }

    function handleShowMessage(message: string, onOk: () => void): void {

        console.log('message ' + message);

        setShowMessage(true)
        setMessage(message)
        setOnOk(onOk)

    }

}

export default withTranslation()(App);
