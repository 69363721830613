import React, { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import BackgroundPrimary from "../containers/BackgroundPrimary";
import { MainHeadline } from "../components/baseStyles";
import FlexContainer from "../components/FlexContainer";
import ReportFilters from "../components/ReportFilters/ReportFilters";
import { LargeViewContainer } from "../containers/ViewContainer";
import ReportView from "../components/ReportView/ReportView";
import { ReportResult } from "../communication/interface";
import CompanyHeader from "../components/CompanyHeader";
import CompanyBackHeader from "../components/CompanyBackHeader";

type Props = WithTranslation;

const ReportPage = (props: Props) => {

  const { t } = props;
  const [mode, setMode] = useState<boolean>(false);
  const [result, setResult] = useState<ReportResult>();

  const setViewMode = (newMode: boolean): void => { setMode(newMode) };

  return (
    <BackgroundPrimary>
      <LargeViewContainer>
        {
          mode === false ?
            <CompanyHeader />
            :
            <CompanyBackHeader onBack = {() => setViewMode(false)}/>

        }
        <MainHeadline>{mode == false ? t("report.headline") : result!.questionaire}</MainHeadline>
        <FlexContainer direction="column">
          {
            mode == false ?
              <ReportFilters setResult={setResult} setMode={setViewMode} />
              :
              <ReportView setViewMode={setViewMode} result={result} />
            }
        </FlexContainer>
      </LargeViewContainer>
    </BackgroundPrimary>
  );
};

export default withTranslation()(ReportPage);
