import React, { Component } from "react";
import { AppContext } from "../AppContext";
import {
    Questionnaire,
    QuestionEntry,
    QuestionnaireGetRequest,
    QuestionnaireGetResponse,
    InfoPromptsResponse,
    InfoPromptsRequest,
    Prompts,
} from "../communication/interface";
import BackgroundPrimary from "../containers/BackgroundPrimary";
import {
    Modal,
    ModalContainer,
    Subline,
    Title,
} from "../components/baseStyles";
import ViewContainer from "../containers/ViewContainer";
import QuestionnaireEditView from "../components/QuestionnaireTable/QuestionnaireEditView";
import Button from "../components/Button";
import { withTranslation, WithTranslation } from "react-i18next";
import CompanyBackHeader from "../components/CompanyBackHeader";


type Props = {
    questionnaire: Questionnaire;
    backNavigation: () => void;
} & WithTranslation;

type State = {
    questionnaireData?: QuestionEntry;
    questionnaireInfo?: Prompts;
    editMode: boolean;
    showConfirmModal: boolean;
    backArrowHover: boolean;
};

class QuestionnaireView extends Component<Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: Props) {

        super(props);

        this.state = {
            questionnaireData: undefined,
            questionnaireInfo: undefined,
            editMode: false,
            showConfirmModal: false,
            backArrowHover: false,
        };

    }

    render() {

        const { backNavigation, t } = this.props;
        const { questionnaireData, questionnaireInfo, showConfirmModal } = this.state;
        return (
            <BackgroundPrimary>
                <ViewContainer>
                    <CompanyBackHeader onBack = {backNavigation}/>
                    
                    {questionnaireData && questionnaireInfo && (
                        <QuestionnaireEditView
                            questionnaire={questionnaireData}
                            info={questionnaireInfo}
                            updateQuestionnaire={(questionnaire: QuestionEntry) =>
                                this.updateOnSave(questionnaire.entryId)
                            }
                        />
                    )}

                    <Modal show={showConfirmModal}>
                        <ModalContainer>
                            <Title>{t("questionnaire.edit.modal.headline")}</Title>
                            <Subline>{t("questionnaire.edit.modal.subline")}</Subline>
                            <Button onClick={() => this.handleModalSave()}>
                                {t("questionnaire.edit.modal.save")}
                            </Button>
                            <Button onClick={() => this.handleModalAbortSave()}>
                                {t("questionnaire.edit.modal.notSave")}
                            </Button>
                            <Button onClick={() => this.setState({ editMode: true, showConfirmModal: false }) } >
                                {t("questionnaire.edit.modal.cancel")}
                            </Button>
                        </ModalContainer>
                    </Modal>

                </ViewContainer>
            </BackgroundPrimary>
        );
    }

    // fetch questions
    componentDidMount = async () => {
        const {
            questionnaire: { clientId, entryId },
        } = this.props;
        try {
            const result1 = await this.getQuestionnaireData(clientId, entryId);
            const result2 = await this.getQuestionnaireInfo(clientId, entryId);
            this.setState({ questionnaireData: result1.questionnaire, questionnaireInfo: result2.prompts });
        } catch (error) {
            console.error(error);
        }
    };

    toggleEditMode = () => {
        const { editMode } = this.state;

        if (!editMode) return this.setState({ editMode: true });
        this.setState({ showConfirmModal: true });
    };

    handleModalSave = async () => {
        //fire request to set modal from state
        this.setState({ editMode: false, showConfirmModal: false });
    };

    handleModalAbortSave = async () => {
        // reset state
        const {
            questionnaire: { clientId, entryId },
        } = this.props;

        const result = await this.getQuestionnaireData(clientId, entryId);

        if (result)
            this.setState({
                questionnaireData: result.questionnaire,
                editMode: false,
                showConfirmModal: false,
            });
    };

    private updateOnSave = async (entryId: number) => {
        const {
            questionnaire: { clientId },
        } = this.props;
        try {
            const result1 = await this.getQuestionnaireData(clientId, entryId);
            const result2 = await this.getQuestionnaireInfo(clientId, entryId);
            this.setState({ questionnaireData: result1.questionnaire, questionnaireInfo: result2.prompts });
        } catch (error) {
            console.error(error);
        }
    };

    // REQUESTS
    private getQuestionnaireData = async (clientId: number, entryId: number): Promise<QuestionnaireGetResponse> => {

        return new Promise<QuestionnaireGetResponse>((resolve, reject) => {

            // build request
            const request: QuestionnaireGetRequest = {
                clientId,
                entryId,
            };

            // post request
            this.context.restAppService.request_post(
                "questionnaire/get",
                this.context.user.getToken(),
                "text/json",
                JSON.stringify(request),
                (data: string): void => {
                    const response = JSON.parse(data) as QuestionnaireGetResponse;
                    resolve(response);
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }
            );

        });

    };

    private getQuestionnaireInfo = async (clientId: number, entryId: number): Promise<InfoPromptsResponse> => {

        return new Promise<InfoPromptsResponse>((resolve, reject) => {

            // build request
            const request: InfoPromptsRequest = {
                clientId,
                entryId,
            };

            // post request
            this.context.restAppService.request_post(
                "info/prompts",
                this.context.user.getToken(),
                "text/json",
                JSON.stringify(request),
                (data: string): void => {
                    const response = JSON.parse(data) as InfoPromptsResponse;
                    resolve(response);
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }
            );

        });

    };
    
}

export default withTranslation()(QuestionnaireView);
