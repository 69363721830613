import React, { useContext, useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import styled from "styled-components";
import { AppContext } from "../AppContext";
import { AccountResetPasswordRequest, AccountVerifyResetCode } from "../communication/interface";
import BackgroundPrimary from "../containers/BackgroundPrimary";
import { Page } from "../types";
import { ReactComponent as Logo } from '../assets/icons/Surveybot_CMYK.svg';
import { useNavigate, useParams } from "react-router-dom";
import Icon from "../components/Icon";
import LoadingIcon from "../components/loadingIcon";
import { resolve } from "path";


const LogoContainer = styled.div`
    position: fixed;
    top: 2em;
    left: 2em;
    z-index: 100;

`

const MainFrame = styled.div`
    flex: 1 1 auto;
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`

const Title = styled.div`
    color: ${props => props.theme.colors.main};
    font-size: 24px;
    padding: 20px 0;
`

const ResetPasswordFrame = styled.div`
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    
    padding: 20px;
    border-radius: 20px;
    background-color: ${props => props.theme.colors.backgroundGrey};

`

const PasswordRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const PasswordColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 10px 0;
`

const PaddingRow = styled.div`
    padding: 5px 10px 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const CenteredRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const SubmitButton = styled.button`
    all: unset;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    margin-top: 15px;  
    margin-bottom: 5px;
    cursor: pointer;  
    
    border-radius: 100px;
    background-color: ${props => props.theme.colors.main};
    color: ${props => props.theme.colors.white};

    &:hover {
        filter: brightness(1.1);
    }

    &:focus {
        outline: solid 1px ${props => props.theme.colors.light};
    }
` 

interface InputProps {
    empty: boolean;
    error?: boolean;
}
const TextInput = styled.input<InputProps>`

    all: unset;
    flex: 1 1 auto;
    color: ${props => props.empty ? props.error ? props.theme.colors.errorLightRed : props.theme.colors.grey : props.theme.colors.black};
    padding: 0 5px;

`

interface ErrorMessageFrameProps {
    hide?: boolean;
    accept: boolean;
    showError: boolean;
}
const ErrorMessageFrame = styled.div<ErrorMessageFrameProps>`
    left: 100%;
    color: ${props => props.accept ? props.theme.colors.acceptGreen : props.showError ? props.theme.colors.errorRed : props.theme.colors.grey};
    display: ${props => props.hide ? 'none' : 'flex'};
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: max-content;
    height: 100%;
`

const ErrorMessageText = styled.div`
    flex: 1 1 auto;
`
const ErrorMessageIcon = styled(Icon)<ErrorMessageFrameProps>`
    color: ${props => props.accept ? props.theme.colors.acceptGreen : props.showError ? props.theme.colors.errorRed : props.theme.colors.grey};
    padding: 0 5px; 
    cursor: default;
    &:hover {
        color: ${props => props.accept ? props.theme.colors.acceptGreen : props.showError ? props.theme.colors.errorRed : props.theme.colors.grey};
    }
`

const SeeIcon = styled(Icon)`
    color: ${props => props.theme.colors.grey};
    padding: 0 5px;
    cursor: pointer;
    &:hover {
        color: ${props => props.theme.colors.main};
    }
`

interface InputBorderProps {
    error?: boolean;
    focused: boolean;
}
const InputBorder = styled.div<InputBorderProps>`
    
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    background-color: ${props => props.theme.colors.backgroundMain};
    border: solid 1px ${props => !props.focused ? props.theme.colors.lightGrey : props.error ? props.theme.colors.errorLightRed : props.theme.colors.main};
    border-radius: 10px;
    padding: 5px 0 5px 15px; 
    margin: 5px 0; 

    ${ props => props.focused ? "box-shadow: 0 0 3px rgba(0,0,0,0.3)" : ""};

`

type Props = {
    pageHandler: (page: Page) => void;
    messageHandler: (message: string, onOk: () => void) => void;
} & WithTranslation;


function ResetPassword(props: Props): JSX.Element {

    const context = useContext(AppContext)
    const { confirmationID } = useParams()

    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')

    const [passwordHasCaptialChar, setPasswordHasCapitalChar] = useState<boolean>(false) 
    const [passwordHasLength, setPasswordHasLength] = useState<boolean>(false)
    const [passwordHasSpecialChar, setPasswordHasSpecialChar] = useState<boolean>(false)
    const [confirmPasswordEqual, setConfirmPasswordEqual] = useState<boolean>(false)

    const [showError, setShowError] = useState<boolean>(false)
    const [pageState, setPageState] = useState<string>('loading') // loading, input, success, failure, incorrectCode
    const [submitErrorMessage, setSubmitErrorMessage] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [focusedInput, setFocusedInput] = useState<string>('none') // none, password, confirmPassword
    const [codeChecked, setCodeChecked] = useState<boolean>(false)

    useEffect(() => {
        
        if(codeChecked) return
        checkCode()
        setCodeChecked(true)

    }, [])

    const navigate = useNavigate()

    const { t } = props;
    
    return (
        <BackgroundPrimary>

            <LogoContainer>
                <Logo />
            </LogoContainer>


            <MainFrame>
                {
                    pageState === 'input' ?
                    <>
                        <Title>{t("login.resetSubmit")}</Title>
                        <ResetPasswordFrame>
                            <PasswordRow>
                                <PasswordColumn>
                                    <PaddingRow>
                                        <InputBorder focused = {focusedInput === 'password'}>
                                            <TextInput 
                                                name = "password"
                                                type = {showPassword ? "text" : "password"}
                                                empty = {password === ''}
                                                placeholder = {String(t("register.password"))}
                                                value = {password}
                                                onChange = {handleInput}
                                                onFocus = {() => handleFocus('password')}
                                                onBlur = {() => handleFocus('none')}
                                            />
                                            <SeeIcon onClick = {handleShowPassword}>
                                                {showPassword ? "visibility_off" : "visibility"}
                                            </SeeIcon>
                                        </InputBorder>
                                    </PaddingRow>
                                    <PaddingRow>
                                        <InputBorder focused = {focusedInput === 'confirmPassword'}>
                                            <TextInput 
                                                name = "confirmPassword"
                                                type = {showPassword ? "text" : "password"}
                                                empty = {confirmPassword === ''}
                                                placeholder = {String(t("register.confirmPassword"))}
                                                value = {confirmPassword}
                                                onChange = {handleInput}
                                                onFocus = {() => handleFocus('confirmPassword')}
                                                onBlur = {() => handleFocus('none')}
                                            />
                                        </InputBorder>
                                    </PaddingRow>
                                </PasswordColumn>
                                <PasswordColumn>
                                    <ErrorMessageFrame accept = {passwordHasCaptialChar} showError = {showError}>
                                        <ErrorMessageIcon accept = {passwordHasCaptialChar} showError = {showError}>
                                            {passwordHasCaptialChar ? 'done' : 'error'}
                                        </ErrorMessageIcon>
                                        <ErrorMessageText>{t("register.largeChar")}</ErrorMessageText>
                                    </ErrorMessageFrame>
                                    <ErrorMessageFrame accept = {passwordHasSpecialChar} showError = {showError}>
                                        <ErrorMessageIcon accept = {passwordHasSpecialChar} showError = {showError}>
                                            {passwordHasSpecialChar ? 'done' : 'error'}
                                        </ErrorMessageIcon>
                                        <ErrorMessageText>{t("register.specialChar")}</ErrorMessageText>
                                    </ErrorMessageFrame>
                                    <ErrorMessageFrame accept = {passwordHasLength} showError = {showError}>
                                        <ErrorMessageIcon accept = {passwordHasLength} showError = {showError}>
                                            {passwordHasLength ? 'done' : 'error'}
                                        </ErrorMessageIcon>
                                        <ErrorMessageText>{t("register.minLength")}</ErrorMessageText>
                                    </ErrorMessageFrame>
                                    <ErrorMessageFrame accept = {confirmPasswordEqual} showError = {showError}>
                                        <ErrorMessageIcon accept = {confirmPasswordEqual} showError = {showError}>
                                            {confirmPasswordEqual ? 'done' : 'error'}
                                        </ErrorMessageIcon>
                                        <ErrorMessageText>{confirmPasswordEqual ? t("register.identPasswords") : t("register.notIdentPasswords")}</ErrorMessageText>
                                    </ErrorMessageFrame>
                                </PasswordColumn>
                            </PasswordRow>

                            <CenteredRow>
                                <SubmitButton onClick = {handleClick}>
                                    {t("register.submit")}
                                </SubmitButton>
                            </CenteredRow>
                        </ResetPasswordFrame>
                    </>
                    :
                    pageState === 'success' ?
                    <>
                        <PasswordRow>
                            {t("resetPassword.success")}
                        </PasswordRow>
                        <CenteredRow>
                            <SubmitButton onClick = {handleNavigateLogin}>
                                {t("register.toLogin")}
                            </SubmitButton>
                        </CenteredRow>
                    </>
                    :
                    pageState === 'failure' ?
                    <>
                        <PasswordRow>
                            {submitErrorMessage}
                        </PasswordRow>
                    </>
                    :
                    pageState === 'incorrectCode' ?
                    <>
                        <PasswordRow>
                            {t("resetPassword.invalidCode")}
                        </PasswordRow>
                    </>
                    :
                    pageState === 'loading' ?
                    <>
                        <LoadingIcon/>
                    </>
                    :
                    null
                }


            </MainFrame>
            
        </BackgroundPrimary>
    );

    function checkCode(): void {

        if(confirmationID === undefined) setPageState('incorrectCode')
        else {
            putAccountVerifyResetCode(confirmationID)
                .then((value: boolean) => {
                    if(value) setPageState('input')
                    else setPageState('incorrectCode')
                })
                .catch(() => {
                    setPageState('incorrectCode')
                })
            
        }
    }

    function handleFocus(field: string): void {
        setFocusedInput(field)
    }

    function handleShowPassword(): void {
        setShowPassword(!showPassword)
    }

    function handleInput(event: React.ChangeEvent<HTMLInputElement>): void {

        const target = event.currentTarget;
        const name = target.name;
        const value = target.value;

        switch (name) {
            case 'password':  
                setPassword(value)
                checkPassword(value)
                break;
            case 'confirmPassword': 
                setConfirmPassword(value)
                checkConfirmPassword(value)
                break;
        }

    }

    function handleClick(): void {

        if(
            passwordHasCaptialChar &&
            passwordHasLength &&
            passwordHasSpecialChar &&
            confirmPasswordEqual
        ) {
            handleSubmit()            
        }
        else {
            setShowError(true)
        }

    }

    function handleNavigateLogin(): void {

        navigate("../login")

    }

    function handleSubmit(): void {

        putResetPassword(confirmationID || '', password)
            .then((value: boolean) => {
                if(value) {
                    setPageState('success')
                }
                else {
                    setSubmitErrorMessage(String(t("resetPassword.failure")))
                    setPageState('failure')
                }
            })
            .catch((response) => {
                if(response === 'failure 405/invalid code') {
                    setSubmitErrorMessage(String(t("resetPassword.invalidCode")))
                } 
                else {
                    setSubmitErrorMessage(String(t("resetPassword.failure")))
                } 
                setPageState('failure')
            })
        
    }

    function checkPassword(currentPassword: string): void {

        if(currentPassword.length >= 8) {
            setPasswordHasLength(true)
        }
        else {
            setPasswordHasLength(false)
        }


        const specialCharacters = ['#','!','"','§','$','%','&','/','(',')','=','?',';',':','-','_','*','+','{','[',']','}','<','>','|']
        let includesSpecialCharacter = false
        specialCharacters.forEach((character) => {
            if(currentPassword.includes(character)) includesSpecialCharacter = true
        })
        setPasswordHasSpecialChar(includesSpecialCharacter)

        let includesCapitalLetter = false
        for(let i = 0; i < currentPassword.length; i++) {
            const charCode = currentPassword.charCodeAt(i)
            if(charCode >= 65 && charCode <= 90) includesCapitalLetter = true
        }
        setPasswordHasCapitalChar(includesCapitalLetter)

    }

    function checkConfirmPassword(currentConfirmPassword: string): void {
        if(password === currentConfirmPassword) {
            setConfirmPasswordEqual(true)
        }
        else {
            setConfirmPasswordEqual(false)
        }
    }

    function putResetPassword(code: string, pass: string): Promise<boolean> {

        return new Promise<boolean>(
            (resolve, reject): void => {

                // create request
                const request: AccountResetPasswordRequest = { code, pass }

                // call
                context.restAppService.request_put('account/resetPassword', context.user.getToken(), 'text/json', JSON.stringify(request),
                    (): void => {
                        resolve(true);
                    },
                    (status, text): void => {
                        reject('failure ' + status + '/' + text);
                    }
                )

            }
        )

    }
    
    function putAccountVerifyResetCode(code: string): Promise<boolean> {

        return new Promise<boolean>(
            (resolve, reject): void => {

                // create request
                const request: AccountVerifyResetCode = { code }

                // call
                context.restAppService.request_put('account/verifyResetCode', context.user.getToken(), 'text/json', JSON.stringify(request),
                    (): void => {
                        resolve(true);
                    },
                    (status, text): void => {
                        reject('failure ' + status + '/' + text);
                    }
                )

            }
        )

    }


}

export default withTranslation()(ResetPassword);