import React, { PropsWithChildren } from "react";
import styled, { useTheme } from "styled-components";

type ButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  borderless?: boolean;
};

interface StyledButtonProps {
  borderless?: boolean;
}
const StyledButton = styled.button<StyledButtonProps>`
  background: ${(props) =>
  props.disabled ? props.theme.colors.disabledButton : props.theme.colors.main};
  border-radius: 4px;
  border: ${(props) => props.borderless ? 'none' : ""};
  color: #ffffff;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.5px;
  text-align: center;
  align-self: flex-end;
  padding: 0px 24px;
  height: 48px;
  cursor: pointer;
`;

const SecondaryButton = styled.button<StyledButtonProps>`
  background: ${(props) =>
    props.disabled ? props.theme.colors.darkGrey : props.theme.colors.white};
  border-radius: 4px;
  border: ${(props) => props.borderless ? 'none' : ""};
  color: ${(props) => props.theme.colors.main};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.5px;
  text-align: center;
  align-self: flex-end;
  padding: 0px 24px;
  height: 48px;
  cursor: pointer;
`;

const TertiaryButton = styled.button<StyledButtonProps>`
  background: ${(props) =>
    props.disabled ? props.theme.colors.darkGrey : props.theme.colors.main};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.white};
  border: ${(props) => props.borderless ? 'none' : ""};
  border-color: ${(props) => props.theme.colors.main};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.5px;
  text-align: center;
  align-self: flex-end;
  padding: 0px 24px;
  height: 48px;
  cursor: pointer;
`;

const ButtonWithIcon = styled.div`
  height: 20px;
  width: 50px;
  padding: 15px 0;
  color: #fff;
  display: flex;
  flex-direction: row;
  :hover {
    background-color: ${(props) => props.theme.colors.medium};
  }
`;

const ButtonWithLogo = styled.div`
  padding: 15px 0;
  color: #fff;
  display: flex;
  flex-direction: row;
`;

const CardButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  border: ${(props) => props.theme.borderLight};
  background-color: ${(props) => props.theme.colors.white};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom: none;
  border-left: none;
  border-right: none;
  > * {
    &:first-child {
      border-bottom-left-radius: 8px;
    }
    &:last-child {
      border-bottom-right-radius: 8px;
      border-right: none;
    }
  }
`;

const CardButton = styled.button`
  height: 60px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.main};
  background-color: ${(props) => props.theme.colors.white};
  border: none;
  border-right: ${(props) => props.theme.borderLight};
  cursor: pointer;
`;

const Button = (props: PropsWithChildren<ButtonProps>) => {
  const theme = useTheme();

  if (props.secondary)
    return (
      <SecondaryButton
        disabled={props.disabled}
        theme={theme}
        onClick={props.onClick}
        borderless = {props.borderless ? props.borderless : false}
      >
        {props.children}
      </SecondaryButton>
    );

  if (props.tertiary)
    return (
      <TertiaryButton
        disabled={props.disabled}
        theme={theme}
        onClick={props.onClick}
        borderless = {props.borderless ? props.borderless : false}
      >
        {props.children}
      </TertiaryButton>
    );
  return (
    <StyledButton
      disabled={props.disabled}
      theme={theme}
      onClick={props.onClick}
      borderless = {props.borderless ? props.borderless : false}
    >
      {props.children}
    </StyledButton>
  );
};

const ClickableIcon = (props: PropsWithChildren<ButtonProps>) => {
  const theme = useTheme();

  return (
    <ButtonWithIcon theme={theme} onClick={props.onClick}>
      {props.children}
    </ButtonWithIcon>
  );
};

const ClickableLogo = (props: PropsWithChildren<ButtonProps>) => {
  const theme = useTheme();

  return (
    <ButtonWithLogo theme={theme} onClick={props.onClick}>
      {props.children}
    </ButtonWithLogo>
  );
};

export default Button;

export {
  ClickableIcon,
  ClickableLogo,
  CardButtonContainer,
  CardButton,
};
