import React from "react"
import styled from "styled-components"


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    position: relative;

    padding: 20px;
    background-color: ${props => props.theme.colors.white};
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
`

interface Props {
    children: React.ReactNode;
}

export default function ShadowContainer(props: Props): JSX.Element {

    return(
        <Container>
            {props.children}
        </Container>
    )
}