import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const HeaderContainer = styled.div`
  height: 44px;
  font-size: 14px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  border: ${(props) => props.theme.borderLight};
  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.white};
  padding: 0 5px;
  padding-left: 12px;
  font-weight: bold;
  cursor: pointer;
`;

const DropDownContainer = styled("div")`
  width: 100%;
  position: relative;
`;

const DropDownListContainer = styled("div")`
  width: 100%;

`;

interface DropDownListProps {
  hide?: boolean;
  openUpwards?: boolean;
}
const DropDownList = styled.ul<DropDownListProps>`
  display: ${props => props.hide ? 'none' : 'flex'};
  flex-direction: ${props => props.openUpwards ? 'column-reverse' : 'column'};
  position: absolute;
  bottom: ${props => props.openUpwards ? '100%' : ''};
  z-index: 1;
  width: calc(100% - 2px);
  max-height: 250px;
  overflow-y: auto;
  border: ${(props) => props.theme.borderLight};
  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.white};
  margin: 0;
  padding: 0;
  border-radius: 2px;
  text-align: center;
  :first-child {
    border-top: none;
  }

  box-shadow: 0 3px 5px rgba(0,0,0,0.5);
`;
const ListItem = styled("li")`
  list-style: none;
  font-size: 14px;
  padding: 12px;
  margin: 0;
  font-weight: bold;
  border-bottom: 1px solid #E5E0EB;
  text-align: left;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.colors.main};
    color: ${(props) => props.theme.colors.white} 
  }

  :last-child {
    border-bottom: none;
  }
`;

export {
  Container,
  HeaderContainer,
  DropDownContainer,
  DropDownListContainer,
  DropDownList,
  ListItem,
};
