import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Title } from "../baseStyles";
import Button from "../Button";
import { Container, ButtonContainer, ErrorMessage } from './component';

type Props = {
    question: string,
    onDelete: () => void;
    onCancel: () => void;
    errorMessage?: string;
    actionNotPossible?: boolean;
} & WithTranslation

const DeletePromptModal = (props: Props) => {
    const {question, onDelete, onCancel, t} = props;
    return (
    <Container>
        <Title>{question}</Title>
        {
            props.actionNotPossible ?
            <ButtonContainer>
                <Button tertiary onClick={onCancel}>{t('button.ok')}</Button>
            </ButtonContainer>
            :
            <ButtonContainer>
                <Button secondary onClick={onCancel}>{t('button.cancel')}</Button>
                <Button tertiary onClick={onDelete}>{t('button.delete')}</Button>
            </ButtonContainer>
        }
        <ButtonContainer>
        </ButtonContainer>
        <ErrorMessage show = {props.errorMessage !== ''}>{props.errorMessage}</ErrorMessage>
    </Container>
    )
};

export default withTranslation()(DeletePromptModal);