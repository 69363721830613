import React, { useContext, useEffect, useState } from "react"
import BackgroundPrimary from "../containers/BackgroundPrimary"
import CompanyHeader from "../components/CompanyHeader"
import { MainHeadline } from "../components/baseStyles"
import { WithTranslation, withTranslation } from "react-i18next"
import { LargeViewContainer } from "../containers/ViewContainer"
import styled from "styled-components"
import ShadowContainer from "../components/Container/ShadowContainer"
import { deepCopy } from "../util/DeepCopy"
import { ContractCancelRequest, ContractGetRequest, ContractResponse } from "../communication/interface"
import { AppContext } from "../AppContext"



const MainFrame = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const HorizontalFrame = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
`

const SubHeadline1 = styled.h2`
    padding: 5px 0;
    margin: 0;
    font-weight: 500;
`

const SubHeadline2 = styled.h3`
    padding: 5px 0;
    margin: 0;
    margin-bottom: 20px;
    font-weight: 500;
`

interface TextInputProps {
    empty: boolean,
    error: boolean,
}
const TextInput = styled.input<TextInputProps>`
    all: unset;

    flex: 1 1 auto;
    padding: 10px 20px;
    border: solid 1px ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.lightGrey};
    border-radius: 10px;
    color: ${props => props.empty ? props.theme.colors.grey : props.theme.colors.dark};

    &:focus {
        border: solid 1px ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.main};
    }

    margin: 5px 0;
    min-width: 400px;
`

const TextBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 7px 20px;
`

const TextBlockLabel = styled.div`
    color: ${props => props.theme.colors.grey};
    padding: 0 20px 0 0;
`
const TextBlockText = styled.div`
    color: ${props => props.theme.colors.darkGrey};
`

const TextArea = styled.textarea<TextInputProps>`
    all: unset;

    flex: 1 1 auto;
    padding: 10px 20px;
    border: solid 1px ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.lightGrey};
    border-radius: 10px;
    color: ${props => props.empty ? props.theme.colors.grey : props.theme.colors.dark};

    &:focus {
        border: solid 1px ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.main};
    }

    word-break: break-word;
    margin: 5px 0;
`

const BottomRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
    padding-top: 10px;
`

const Button = styled.div`
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: ${props => props.theme.colors.main};
    color: ${props => props.theme.colors.white};
    font-weight: 600;

    cursor: pointer;

    &:hover {
        filter: opacity(0.9);
    }

    &:active {
        filter: opacity(0.8);
    }
`

interface InfoTextProps {
    error?: boolean,
    hide?: boolean,
    padding?: boolean,
}
const InfoText = styled.div<InfoTextProps>`
    flex: 0 1 auto;
    display: ${props => props.hide ? "none" : "flex"};
    color: ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.grey};
    font-weight: 400;
    padding: ${props => props.padding ? "0 0 15px 0" : "0"};
`

const ResponseText = styled(InfoText)`
    padding: 10px 0;
    color: ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.acceptGreen};
`

const InputLabel = styled.div`
    padding: 15px 10px 0 10px;
    color: ${props => props.theme.colors.lightGrey};
`

type Props = {
    variable?: string,
} & WithTranslation

function CancellationFormPage(props: Props): JSX.Element {


    const [name, setName] = useState<string>("")
    const [company, setCompany] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [date, setDate] = useState<Date>(new Date())
    const [message, setMessage] = useState<string>("")
    const [missingFields, setMissingFields] = useState<string[]>([])
    const [showError, setShowError] = useState<boolean>(false)
    const [pageResponse, setPageResponse] = useState<string>("none") // none, success, error

    const context = useContext(AppContext)
    const { t } = props

    const [loadContextIdCounter, setLoadContextIdCounter] = useState(0)
    useEffect(() => {

        if(loadContextIdCounter >= 0) {
            setTimeout(() => {
                
                if(context.client.clientId !== 0) {
                    getContractData(context.client.clientId)
                    setLoadContextIdCounter(-1)
                } 
                else {
                    setLoadContextIdCounter(loadContextIdCounter + 1)
                }
    
            }, 200)
        }
    
    }, [loadContextIdCounter])

    return(
        <BackgroundPrimary>
            <LargeViewContainer>
                <CompanyHeader />

                        <MainHeadline>{t("cancellationForm.title")}</MainHeadline>
                <MainFrame>
                    <HorizontalFrame>
                        <SubHeadline1>{t("cancellationForm.subTitle1")}</SubHeadline1>
                        <SubHeadline2>{t("cancellationForm.subTitle2")}</SubHeadline2>

                        <ShadowContainer>
                            <TextBlock>
                                <TextBlockLabel>{t("cancellationForm.company") + ":"}</TextBlockLabel>
                                <TextBlockText>{company}</TextBlockText>
                            </TextBlock>
                            <TextBlock>
                                <TextBlockLabel>{t("cancellationForm.name") + ":"}</TextBlockLabel>
                                <TextBlockText>{name}</TextBlockText>
                            </TextBlock>
                            <TextBlock>
                                <TextBlockLabel>{t("cancellationForm.email") + ":"}</TextBlockLabel>
                                <TextBlockText>{email}</TextBlockText>
                            </TextBlock>
                            <InputLabel>{t("cancellationForm.date") + ' *'}</InputLabel>
                            <TextInput
                                name = "date"
                                type = "date"
                                empty = {false}
                                value = {date.toISOString().split("T")[0]}
                                onChange = {handleInput}
                                error = {false}
                            />
                            <TextArea
                                name = "message"
                                rows = {7}
                                placeholder = {String(t("cancellationForm.yourMessage")) + " *"}
                                empty = {message ===  ""}
                                value = {message}
                                onChange = {handleInput}
                                error = {showError && missingFields.includes("message")}
                            />
                            <InfoText padding>{t("cancellationForm.infoText")}</InfoText>
                            <BottomRow>
                                <Button
                                    onClick = {handleSubmit}
                                >{t("cancellationForm.submit")}</Button>
                                <InfoText error = {showError && missingFields.length > 0}>{t("cancellationForm.mandatoryField")}</InfoText>
                            </BottomRow>
                        </ShadowContainer>

                    </HorizontalFrame>
                    <ResponseText padding error = {pageResponse === "error"} hide = {pageResponse === "none"}>
                        {pageResponse === "error" ? t("cancellationForm.error") : ""}
                        {pageResponse === "success" ? t("cancellationForm.success") : ""}
                    </ResponseText>
                </MainFrame>

            </LargeViewContainer>
        </BackgroundPrimary>
    )


    function handleSubmit(): void {
        if(checkFormCompleteness()) {
            putContractCancel(context.client.clientId,message).then(
                (success: boolean) => {
                    if(success) {
                        setName("")
                        setCompany("")
                        setEmail("")
                        setMessage("")
                        setPageResponse("success")
                    }
                })
                .catch(
                    () => {
                        setPageResponse("error")
                    }
            )
        } else {
            setShowError(true)
        }
    }

    function checkFormCompleteness(): boolean {

        const completenessCondition = (message !== "")

        if(completenessCondition) {
            return true
        } else {
            showMissingFields()
            setShowError(true)
            return false
        }

    }

    function showMissingFields(): void {
            
        const localMissingFields: string[] = []

        if(message === "") {
            localMissingFields.push("message")
        }

        setMissingFields(localMissingFields)

    }

    function handleInput(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {

        const target = event.target
        const value = target.value
        const name = target.name

        switch(name) {
            case "date":
                setDate(new Date(value))
                break;
            case "message":
                if(value == " ") {
                    setMessage("")
                }
                else {
                    setMessage(value)
                }
                break;
        }


        const localMissingFields = deepCopy(missingFields)
        
        if(localMissingFields.includes(name)) {
            localMissingFields.splice(localMissingFields.indexOf(name), 1)
            setMissingFields(localMissingFields)
        }

    }

    // function calculateNextPossibleDate(): Date {

    //     const today = new Date()
    //     const month = today.getMonth()
    //     let year = today.getFullYear()

    //     let monthAfterNext = month + 2
        
    //     if(monthAfterNext > 11) {
    //         monthAfterNext = monthAfterNext % 12
    //         year++
    //     }

    //     return new Date(year, monthAfterNext, 1)
    // }


    function putContractCancel(
        clientId: number, 
        cancelReason: string
        ): Promise<boolean> {

        return new Promise<boolean> (
            (resolve, reject) => {

                const request: ContractCancelRequest = {
                    clientId: clientId,
                    cancelReason: cancelReason,
                }

                context.restAppService.request_put('contract/cancel', context.user.getToken(), 'text/json', JSON.stringify(request),
                    (): void => {
                        resolve(true)
                    },
                    (status, text): void => {
                        console.log('failure ' + status + '/' + text)
                        reject(status)
                    } 
                )

            }
        )
      
    }

    function getContractData(clientId: number): void {
        postContractGet(clientId)
            .then((response) => {
                
                console.log("setting loaded contract data")

                setName(response.contract.contact.preName + " " + response.contract.contact.lastName)
                setCompany(response.contract.company.company)
                setEmail(response.contract.contact.email)
            })
            .catch((error: string) => {
                console.log(error)
            })
    }

    function postContractGet(clientId: number): Promise<ContractResponse> {

        return new Promise<ContractResponse>((resolve, reject) => {
                
            const request: ContractGetRequest = {
                clientId: clientId,
            }

            context.restAppService.request_post("contract/get", context.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const result: ContractResponse = JSON.parse(data) as ContractResponse
                    resolve(result)
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text)
                }
            )
            
        })
    }

}


export default withTranslation()(CancellationFormPage)