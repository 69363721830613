import { EntryType } from "../communication/interface";

const validEntryTypes = [
    EntryType.GRADE5,
    EntryType.GRADE6,
    EntryType.COMMENT,
    EntryType.NPS,
    EntryType.YESNO
];

const getEntryType = (entry: string): EntryType => {

    switch (entry) {
        case "Note 5": return EntryType.GRADE5;
        case "Note 6": return EntryType.GRADE6;
        case "Kommentar": return EntryType.COMMENT;
        case "NPS": return EntryType.NPS;
        case "Ja/Nein": return EntryType.YESNO;
    }

    // default
    return EntryType.GRADE6;

}

export { validEntryTypes, getEntryType }