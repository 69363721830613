import React, { useState } from "react";
import {
  Container,
  DropDownContainer,
  DropDownList,
  DropDownListContainer,
  HeaderContainer,
  ListItem,
} from "./componentsRestyled";
import {ReactComponent as ChevronUp} from '../../assets/icons/chevron-up.svg';
import {ReactComponent as ChevronDown} from '../../assets/icons/chevron-down.svg';

type Props<T> = {
  value: T;
  options: T[];
  onChange: (option: T) => void;
};

const DropDown = <T extends string>(props: Props<T>) => {
  const { value, options, onChange } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleItemClick = (option: T) => {
    setIsOpen(false);
    onChange(option);
  };
  return (
    <Container>
      <DropDownContainer>
        <HeaderContainer 
          onClick={() => setIsOpen(!isOpen)}
          open = {isOpen}
        >
          {value}
          {isOpen ? <ChevronUp fill="#000"/> : <ChevronDown fill="#000"/>}
        </HeaderContainer>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {options.map((option) => (
                <ListItem key={JSON.stringify(option)} onClick={() => handleItemClick(option)}>
                  {option}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </Container>
  );
};

export default DropDown;
