export enum EntryType {
	QUESTIONNAIRE="Questionnaire",
	GROUP="Group",
	YESNO="YesNo",
	GRADE5="Grade5",
	GRADE6="Grade6",
	COMMENT="Comment",
	NPS="NPS"
}

export interface Client {
	clientId: number;
	name: string;
	email: string;
	telephoneList: string[];
}

export interface Condition {
	LValue: string;
	Cond: string;
	RValue: string;
}

export interface Questionnaire {
	clientId: number;
	entryId: number;
	text: string;
	description: string;
	editable: boolean;
}

export interface CatalogEntry {
	clientId: number;
	catalogId: number;
	text: string;
	entryType: EntryType;
}

export interface QuestionEntry {
	clientId: number;
	entryId: number;
	parentId: number;
	sequence: number;
	condition: Condition;
	text: string;
	description: string;
	entryType: EntryType;
	entries?: QuestionEntry[];
}

export interface Campaigns {
	clientId: number,
	phoneNumber: number,
	campaigns: Campaign[]
}

export interface Campaign {
	clientId: number,
	campaignId: number,
	telephone: string,
	startDate: number,
	endDate: number,
	timezone: string,
	level: number,
	entryId: number,
	startDateDate: Date,
	endDateDate: Date,
}

export interface Prompts {
	greeting: string;
	nothingSaid: string;
	nothingHeard: string;
	goodBye: string;
}

export interface MonitorFilter {
	telephone: string,
	startDate: string,
	endDate: string,
	timezone: string,
}

export interface MonitorEntry {
	telephone: string;
	started: number;
	answered: number;
	escalation: number;
	finished: number;
	cancelled: number;
}

export interface ReportFilter {
	telephone: string,
	startDate: string,
	endDate: string,
	timezone: string,
	content: string,
	fileFormat: string
}

export interface ReportQuestion {
	questionText: string,
	questionType: string,
	numberQuestions: number,
	numberAnswers: number,
	answers: number[] | string[],
}

export interface ReportResult {
	questionaire: string,
	questions: ReportQuestion[],
}

export interface VideoData {
	// id the video file is located at (e.g. SurveyBot/video/12345)
	videoId: string,
	title: string,
	description: string,
}

export interface ContractCompany {
	company: string;
	street: string;
	postCode: string;
	city: string;
	country: string;
}

export interface ContractContact {
	lastName: string;
	preName: string;
	department: string;
	telephone: string;
	email: string;
	orderNumber: string;
	useBillingEmail: boolean;
	billingEmail: string;
}

export interface ContractBilling {
	contractBilling: string;
	amountPeriod: number;
	amountInterview: number;
	maxAmountPeriod: number;
	invoicePeriod: string; // monthly, yearly
	lastInvoiceDate: string;
}

export interface ContractData {
	contractType: string;
	telephoneNumbers: number;
	telephoneLines: number;
	interviews: number;
	limitInterviews: boolean;
	created: string;
	cancelled: string;
	validUntil: string;
}

export interface Contract {
	clientId: number;
	company: ContractCompany;
	contact: ContractContact;
	billing: ContractBilling;
	data: ContractData;
}

export interface ServiceCondition {
	contractType: string;
	telephoneNumbers: number;
	telephoneLines: number;
	amountPeriod: number;
	amountInterview: number;
	interviews: number;
	limitInterviews: boolean;
	maxAmountPeriod: number;
}


// === client ==================================================================
// POST client/get
//
// get info of client
export interface ClientGetRequest {
	clientId: number;
}

export interface ClientGetResponse {
	client: Client;
}

// === questionnaire ===========================================================
// POST questionnaire/overview
//
// get list of questionnaires for a client
export interface QuestionnaireOverviewRequest {
	clientId: number;	
}

export interface QuestionnaireOverviewResponse {
	questionnaires: Questionnaire[];
}

// POST questionnaire/get
//
// get a questionnaire for a client
export interface QuestionnaireGetRequest {
	clientId: number;
	entryId: number;	
}

export interface QuestionnaireGetResponse {
	questionnaire: QuestionEntry;	
}

// POST questionnaire/create
//
// copy a questionnaire for editing
export interface QuestionnaireCreateRequest {
	clientId: number;
}

export interface QuestionnaireCreateResponse {
	questionnaire: QuestionEntry;	
}

// POST questionnaire/copy
//
// copy a questionnaire for editing
export interface QuestionnaireCopyRequest {
	clientId: number;
	entryId: number;	
}

export interface QuestionnaireCopyResponse {
	questionnaire: QuestionEntry;	
}

// POST questionnaire/save
//
// save a questionnaire
export interface QuestionnaireSaveRequest {
	clientId: number;
	questionnaire: QuestionEntry;	
}

export interface QuestionnaireSaveResponse {
	questionnaire: QuestionEntry;	
}

// POST questionnaire/entryId
//
// get a new entryId
export interface QuestionnaireEntryIdRequest {
	clientId: number;
}

export interface QuestionnaireEntryIdResponse {
	entryId: number;
}

// PUT questionnaire/delete
//
// delete a questionnaire
export interface QuestionnaireDeleteRequest {
	clientId: number;
	entryId: number;	
}

// === catalog =================================================================
// POST catalog/catalogEntries
//
// get list of catalog questions
export interface CatalogCatalogEntriesRequest {
	clientId: number;
}

export interface CatalogCatalogEntriesResponse {
    catalogEntries: CatalogEntry[];
}

// POST catalog/save
//
// save an entry. A new entry is created by providing catalogId = 0
export interface CatalogSaveRequest {
	clientId: number;
	catalogEntry: CatalogEntry;
}

export interface CatalogSaveResponse {
    catalogEntries: CatalogEntry[];
}

// POST catalog/delete
//
// deletes a catalog entry
export interface CatalogDeleteRequest {
	clientId: number;
	catalogId: number;
}

export interface CatalogDeleteResponse {
    success: boolean;
}

// === campaign ================================================================
// POST campaign/campaignEntries
//
// get list of current and future campaigns for a given clientId
export interface CampaignCampaignEntriesRequest {
	clientId: number;
}

export interface CampaignCampaignEntriesResponse {
    campaignEntries: Campaigns[]
}

// POST campaign/save
//
// saves a new campaign, campaignId = 0 marks a new entry
export interface CampaignSaveRequest {
	clientId: number;
	campaign: Campaign
}

export interface CampaignSaveResponse {
    campaign: Campaign
}

// POST campaign/campaignId
//
// get a new campaignId
export interface CampaignCampaignIdRequest {
	clientId: number;
}

export interface CampaignCampaignIdResponse {
	campaignId: number;
}

// PUT campaign/delete
//
// deletes a campaign by campaignId
export interface CampaignDeleteRequest {
	clientId: number,
	campaignId: number,
}

// === monitor =================================================================
// POST monitor/data
//
// get monitor entries
export interface MonitorDataRequest {
	clientId: number;
	monitorFilter: MonitorFilter;
}

export interface MonitorDataResponse {
	monitorEntries: MonitorEntry[];
}

// === report ==================================================================
// POST report/export
//
// get an export of survey data
export interface ReportExportRequest {
	clientId: number,
	entryId: number,
	reportFilter: ReportFilter,
}

export interface ReportExportResponse {
	download: string;
}

// get an report of survey data
export interface ReportReportRequest {
	clientId: number,
	entryId: number,
	reportFilter: ReportFilter,
}

export interface ReportReportResponse {
	report: ReportResult;
}

// === info ====================================================================
// POST info/prompts
//
// get standard prompts
export interface InfoPromptsRequest {
	clientId: number;
	entryId: number;
}

export interface InfoPromptsResponse {
	prompts: Prompts;
}

// PUT info/promptsUpdate
//
// update standard prompts
export interface InfoPromptsUpdateRequest {
	clientId: number;
	entryId: number;
	prompts: Prompts;
}


// === admin ===================================================================
//
// get userlist
// get permissions
// get user
// add user
// update user
// delete user
// change password

// === account =================================================================

//
// PUT account/requestPassword
// 400: email not found
//
export interface AccountRequestPasswordRequest {
	user: string;
}

//
// PUT account/resetPassword
//
export interface AccountResetPasswordRequest {
	code: string;
	pass: string;
}

//
// PUT account/verifyResetCode
//
export interface AccountVerifyResetCode {
	code: string;
}



// POST register
// export interface AccountRegisterRequest {
// 	user: string;
// 	username: string;
// }

// export interface AccountRegisterResponse {
// 	status: number;
// 	message: string;
// }

// POST account/registration
export interface AccountRegistrationRequest {
    company: string,
    salutation: string,
    firstName: string,
    lastName: string,
    email: string,
    password: string,
}

export interface AccountRegistrationResponse {
	message: string;
}

export interface AccountRegistrationErrorResponse {
	message: string;
	status: number;
}

//
// POST account/login
//
export interface AccountLoginRequest {
	email: string;
	password: string;
}
export interface AccountLoginResponse {
	token: string;
	status: number;
	message: string;
}

//
// POST account/verification
//
export interface AccountVerificationRequest {
	confirmationId: string;
}
export interface AccountVerificationResponse {
	message: string;
}


//
// POST account/getPlanData
//
export interface AccountGetPlanDataRequest {
	clientId: string,
}
export interface AccountGetPlanDataResponse {
	phoneNumbers: string[],
	plan: string,
}

// === video ===================================================================

//
// GET video/list
//
export interface VideoListResponse {
	videoList: VideoData[],
}

// === form ====================================================================

//
// PUT form/contact
//
export interface ContactFormRequest {
	company: string,
	name: string,
	mail: string,
	phone?: string,
	message: string,
}


// //
// // POST account/resetpassword/verification
// //
// export interface AccountResetPasswordRequest {
// 	email: string,
// }
// export interface AccountResetPasswordResponse {
// 	message: string;
// }

// //
// // POST account/resetpassword/confirmation
// //
// export interface AccountResetPasswordConfirmationRequest {
// 	confirmationId: string,
// 	password: string,
// }
// export interface AccountResetPasswordConfirmationResponse {
// 	message: string;
// }

// === contract ================================================================

// PUT contract/cancel
export interface ContractCancelRequest {
	clientId: number;
	cancelReason: string;
}

// POST contract/get
export interface ContractGetRequest {
	clientId: number;
}

export interface ContractResponse {
	contract: Contract;
}

// POST contract/update
export interface ContractUpdateRequest {
	clientId: number;
	contractCompany: ContractCompany;
	contractContact: ContractContact;
}
export interface ContractUpdateResponse {
	contract: Contract;
}

// POST contract/upgrade
export interface ContractUpgradeRequest {
	clientId: number;
	contractType: string;
	invoicePeriod: string; // yearly, monthly
}

export interface ContractUpgradeResponse {
	contract: Contract;
}

// GET contract/conditions
export interface ContractConditionsResponse {
	conditions: ServiceCondition[];
}