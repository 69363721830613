import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import BackgroundPrimary from "../containers/BackgroundPrimary";
import { MainHeadline } from "../components/baseStyles";
import ViewContainer from "../containers/ViewContainer";
import { MonitorDataRequest, MonitorDataResponse, MonitorEntry } from "../communication/interface";
import { AppContext } from "../AppContext";
import styled from "styled-components";
import Button from "../components/Button";
import { Permission } from "../data/User";
import CompanyHeader from "../components/CompanyHeader";

const MonitorContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const TimeSelect = styled.div`
	flex: 0 0 auto;
	width: inherit;
	display: flex;
	flex-direction: row;
	justify-items: center;
	margin-bottom: 2em;
`

const ButtonContainer = styled.div`
	flex: 0 0 auto;
	margin: 0 5px;
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);

	transition: box-shadow 100ms;
	&:hover {
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
	}
`

const MonitorEntryBox = styled.div`
	flex: 1 1 auto;
`

const MonitorEntryContainer = styled.div`
	display: grid;
	grid-template-columns: 100px 160px 160px 160px 160px;
	grid-gap: 0.3em;
	row-gap: 0.2em;
`

const MonitorEntryField = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
`
const MonitorEntryHeader = styled.div`
	background-color: #d0d0d0;
	font-weight: bold;
	display: flex;
	flex-direction: column;
	align-items: center;
`
const MonitorEntryTelephone = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const MonitorEntrySum = styled.div`
	background-color: white;
	font-weight: bold;
	display: flex;
	flex-direction: column;
	align-items: center;
`

enum TimeFrame {
	TODAY = "export.today",
	YESTERDAY = "export.yesterday",
	THISWEEK = "export.thisWeek",
	LASTWEEK = "export.lastWeek",
	THISMONTH = "export.thisMonth",
	LASTMONTH = "export.lastMonth",
	CUSTOM = "export.custom",
}


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MonitorPageProps extends WithTranslation {

}

interface MonitorPageState {

	selectedTimeFrame: TimeFrame,
	selectedStartDate: Date;
	selectedEndDate: Date;
	selectedPhoneNumber: string;

	monitorData?: MonitorEntry[];
}

class MonitorPage extends React.Component<MonitorPageProps, MonitorPageState> {

	static contextType = AppContext;
	context!: React.ContextType<typeof AppContext>;

	private sumStarted = 0;
	private sumCancelled = 0;
	private sumAnswered = 0;
	private sumFinished = 0;

	constructor(props: MonitorPageProps) {

		super(props);

		this.state = {
			selectedTimeFrame: TimeFrame.CUSTOM,
			selectedStartDate: new Date(new Date().setHours(0, 0, 0, 0)),
			selectedEndDate: new Date(new Date().setHours(0, 0, 0, 0)),
			selectedPhoneNumber: '',
			monitorData: undefined,
		}

	}

	public render = (): JSX.Element => {

		const { t } = this.props;

		let box: JSX.Element = <div>loading...</div>

		if (this.state.monitorData) {

			box =
				<MonitorEntryBox>
					<MonitorEntryContainer>
						<MonitorEntryHeader>Telefon</MonitorEntryHeader>
						<MonitorEntryHeader>aktiv</MonitorEntryHeader>
						<MonitorEntryHeader>nicht beantwortet</MonitorEntryHeader>
						<MonitorEntryHeader>unvollständig</MonitorEntryHeader>
						<MonitorEntryHeader>abgeschlossen</MonitorEntryHeader>
						{this.state.monitorData.map( (me) => {
							return(
								<>
									<MonitorEntryTelephone>{me.telephone}</MonitorEntryTelephone>
									<MonitorEntryField>{me.started}</MonitorEntryField>
									<MonitorEntryField>{me.cancelled}</MonitorEntryField>
									<MonitorEntryField>{me.answered}</MonitorEntryField>
									<MonitorEntryField>{me.finished}</MonitorEntryField>
								</>
							)
						})}
						<MonitorEntrySum />
						<MonitorEntrySum>{this.sumStarted}</MonitorEntrySum>
						<MonitorEntrySum>{this.sumCancelled}</MonitorEntrySum>
						<MonitorEntrySum>{this.sumAnswered}</MonitorEntrySum>
						<MonitorEntrySum>{this.sumFinished}</MonitorEntrySum>
					</MonitorEntryContainer>
				</MonitorEntryBox>

		}


		return (
			<BackgroundPrimary>
				<ViewContainer>
					<CompanyHeader/>
					<MainHeadline>{t("monitor.headline")}</MainHeadline>
					<MonitorContainer>
						<TimeSelect>
							<ButtonContainer><Button secondary={this.state.selectedTimeFrame === TimeFrame.LASTMONTH ? false : true} onClick={ () => { this.handleClick(TimeFrame.LASTMONTH); } } borderless>{t(TimeFrame.LASTMONTH)}</Button></ButtonContainer>
							<ButtonContainer><Button secondary={this.state.selectedTimeFrame === TimeFrame.THISMONTH ? false : true} onClick={ () => { this.handleClick(TimeFrame.THISMONTH); } } borderless>{t(TimeFrame.THISMONTH)}</Button></ButtonContainer>
							<ButtonContainer><Button secondary={this.state.selectedTimeFrame === TimeFrame.LASTWEEK  ? false : true} onClick={ () => { this.handleClick(TimeFrame.LASTWEEK ); } } borderless>{t(TimeFrame.LASTWEEK)}</Button></ButtonContainer>
							<ButtonContainer><Button secondary={this.state.selectedTimeFrame === TimeFrame.THISWEEK  ? false : true} onClick={ () => { this.handleClick(TimeFrame.THISWEEK ); } } borderless>{t(TimeFrame.THISWEEK)}</Button></ButtonContainer>
							<ButtonContainer><Button secondary={this.state.selectedTimeFrame === TimeFrame.YESTERDAY ? false : true} onClick={ () => { this.handleClick(TimeFrame.YESTERDAY); } } borderless>{t(TimeFrame.YESTERDAY)}</Button></ButtonContainer>
							<ButtonContainer><Button secondary={this.state.selectedTimeFrame === TimeFrame.TODAY     ? false : true} onClick={ () => { this.handleClick(TimeFrame.TODAY);     } } borderless>{t(TimeFrame.TODAY)}</Button></ButtonContainer>
						</TimeSelect>
						{box}
					</MonitorContainer>
				</ViewContainer>
			</BackgroundPrimary>
		);

	}

	public componentDidMount = (): void => {


		// set timeframe
		this.handleClick(TimeFrame.TODAY);
		
	}

	private handleClick = (timeFrame: TimeFrame): void => {

		// return immediately if the timeframe is already selected
		if (timeFrame === this.state.selectedTimeFrame) {
			return;
		}

		// get clientId
		const permissions = this.context.user.getPermissionList();
		const mappedPermission = permissions.find(
			(permission: Permission) => permission.permissionType === "clientId"
		);
		let clientId = 0;
		if (mappedPermission?.permissionValue) {
			clientId = +mappedPermission.permissionValue;
		}


		// set new timeframe
		this.setState({ selectedTimeFrame: timeFrame, monitorData: undefined}, (): void => {

			// request data
			this.postMonitorData(clientId).then(
				(response): void => {

					// build sum
					this.sumStarted = this.sumCancelled = this.sumAnswered = this.sumFinished = 0;
					response.monitorEntries.forEach( (me) => {
						this.sumStarted += me.started;
						this.sumCancelled += me.cancelled;
						this.sumAnswered += me.answered;
						this.sumFinished += me.finished;
					});

					// sort entries and set data
					this.setState({ monitorData: response.monitorEntries.sort( (a, b) => { return a.telephone.localeCompare(b.telephone); } ) });

				},
				(message): void => {
					console.log(message);
				}
			)

		});

	}

	private postMonitorData = (clientId: number): Promise<MonitorDataResponse> => {

		return new Promise<MonitorDataResponse>(
			(resolve, reject): void => {

				// build request
				const {startDate, endDate} = this.matchTimeFrameToDate();
				const request: MonitorDataRequest = {
					clientId: clientId,
					monitorFilter:
					{
						startDate: startDate.toISOString(),
						endDate: endDate.toISOString(),
						telephone: this.state.selectedPhoneNumber,
						timezone: '',
					}
				}

				// send request
				this.context.restAppService.request_post('monitor/data', this.context.user.getToken(), 'text/json', JSON.stringify(request),
					(data): void => {
						const response = JSON.parse(data) as MonitorDataResponse;
						resolve(response);
					},
					(status, text): void => {
						reject("failure " + status + "/" + text);
					}
				);
			}
		);

	}

	private matchTimeFrameToDate = (): { startDate: Date, endDate: Date } => {

		const { selectedTimeFrame } = this.state;

		const startDate = new Date();
		startDate.setHours(0, 0, 0, 0);

		const endDate = new Date();
		endDate.setTime(startDate.getTime());

		const prevMonday = new Date();
		prevMonday.setHours(0, 0, 0, 0);
		prevMonday.setDate( startDate.getDate() - ((startDate.getDay() + 6) % 7) );

		switch (selectedTimeFrame) {

			case TimeFrame.TODAY:
			default:
				endDate.setDate(endDate.getDate() + 1);
				return {
					startDate: startDate,
					endDate: endDate,
				};

			case TimeFrame.YESTERDAY:
				startDate.setDate(startDate.getDate() - 1);
				return {
					startDate,
					endDate,
				};

			case TimeFrame.THISWEEK:
				endDate.setDate(endDate.getDate() + 1);
				return {
					startDate: prevMonday,
					endDate: endDate,
				};

			case TimeFrame.LASTWEEK:
				prevMonday.setDate(prevMonday.getDate() - 7);
				endDate.setDate(prevMonday.getDate() + 7);
				return {
					startDate: prevMonday,
					endDate,
				};

			case TimeFrame.THISMONTH:
				startDate.setDate(1);
				endDate.setDate(endDate.getDate() + 1);
				return {
					startDate,
					endDate,
				};
			case TimeFrame.LASTMONTH:
				startDate.setDate(1);
				startDate.setMonth(startDate.getMonth() - 1);
				endDate.setDate(1);
				return {
					startDate,
					endDate,
				};

		}
	};

}
export default withTranslation()(MonitorPage);
