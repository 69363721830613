import styled from "styled-components";

const Container = styled.div`
    margin-top: -3px;
    display: grid;
    grid-template-columns: 10fr 1fr 1fr;
    column-gap: 16px;
    background-color: ${props => props.theme.colors.white};
    padding: 0 15px 25px 15px;
    color: ${props=> props.theme.colors.black};
`

const ButtonElementContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const Label = styled.div`
`

export {
    Container,
    ButtonElementContainer,
    Label
}