import React, { useContext, useEffect, useState } from "react"
import BackgroundPrimary from "../containers/BackgroundPrimary"
import CompanyHeader from "../components/CompanyHeader"
import { MainHeadline } from "../components/baseStyles"
import { WithTranslation, withTranslation } from "react-i18next"
import { LargeViewContainer } from "../containers/ViewContainer"
import styled from "styled-components"
import ShadowContainer from "../components/Container/ShadowContainer"
import { verifyMail, verifyPhoneNumber } from "../helpers/inputValidatior"
import { deepCopy } from "../util/DeepCopy"
import Icon from "../components/Icon"
import { ContactFormRequest, ContractGetRequest, ContractResponse } from "../communication/interface"
import { AppContext } from "../AppContext"



const MainFrame = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const HorizontalFrame = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
`

const SubHeadline1 = styled.h2`
    padding: 5px 0;
    margin: 0;
    font-weight: 500;
`

const SubHeadline2 = styled.h3`
    padding: 5px 0;
    margin: 0;
    margin-bottom: 20px;
    font-weight: 500;
`

interface TextInputProps {
    empty: boolean,
    error: boolean,
}
const TextInput = styled.input<TextInputProps>`
    all: unset;

    flex: 1 1 auto;
    padding: 10px 20px;
    border: solid 1px ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.lightGrey};
    border-radius: 10px;
    color: ${props => props.empty ? props.theme.colors.grey : props.theme.colors.dark};

    &:focus {
        border: solid 1px ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.main};
    }

    margin: 5px 0;
    min-width: 400px;
`

const TextInputPlaceholderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 10px 20px;

`

const TextInputPlaceholder = styled.div`
    padding: 0 10px;
    color: ${props => props.theme.colors.darkGrey};
    font-weight: 400;
`

const TextArea = styled.textarea<TextInputProps>`
    all: unset;

    flex: 1 1 auto;
    padding: 10px 20px;
    border: solid 1px ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.lightGrey};
    border-radius: 10px;
    color: ${props => props.empty ? props.theme.colors.grey : props.theme.colors.dark};

    &:focus {
        border: solid 1px ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.main};
    }

    word-break: break-word;
    margin: 5px 0;
`

const BottomRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
    padding-top: 10px;
`

const Button = styled.div`
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: ${props => props.theme.colors.main};
    color: ${props => props.theme.colors.white};
    font-weight: 600;

    cursor: pointer;

    &:hover {
        filter: opacity(0.9);
    }

    &:active {
        filter: opacity(0.8);
    }
`

interface InfoTextProps {
    error?: boolean,
    hide?: boolean,
    padding?: boolean,
}
const InfoText = styled.div<InfoTextProps>`
    flex: 0 1 auto;
    display: ${props => props.hide ? "none" : "flex"};
    color: ${props => props.error ? props.theme.colors.errorLightRed : props.theme.colors.grey};
    font-weight: 400;
    padding: ${props => props.padding ? "15px 0" : "0"};
`

interface ErrorMessageFrameProps {
    show: boolean,
}
const ErrorMessageFrame = styled.div<ErrorMessageFrameProps>`
    display: ${props => props.show ? "flex" : "none"};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 0 1 auto;
    padding-bottom: 10px;
`

const ErrorText = styled(InfoText)`
    flex: 1 1 auto;
    padding-left: 10px;
    color: ${props => props.theme.colors.errorLightRed};
`

const ErrorIcon = styled(Icon)`
    color: ${props => props.theme.colors.errorLightRed};
`

type Props = {
    variable?: string,
} & WithTranslation

function ContactFormPage(props: Props): JSX.Element {

    const [name, setName] = useState<string>("")
    const [company, setCompany] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [phone, setPhone] = useState<string>("")
    const [message, setMessage] = useState<string>("")
    const [missingFields, setMissingFields] = useState<string[]>([])
    const [mailError, setMailError] = useState<boolean>(false)
    const [phoneError, setPhoneError] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)

    const [pageResponse, setPageResponse] = useState<string>("none") // none, success, error

    const context = useContext(AppContext)
    const { t } = props

    
    const [loadContextIdCounter, setLoadContextIdCounter] = useState(0)
    useEffect(() => {

        if(loadContextIdCounter >= 0) {
            setTimeout(() => {
                
                if(context.client.clientId !== 0) {
                    getContractData(context.client.clientId)
                    setLoadContextIdCounter(-1)
                } 
                else {
                    setLoadContextIdCounter(loadContextIdCounter + 1)
                }
    
            }, 200)
        }
    
    }, [loadContextIdCounter])

    return(
        <BackgroundPrimary>
            <LargeViewContainer>
                <CompanyHeader />

                <MainHeadline>{t("contactForm.title")}</MainHeadline>
                <MainFrame>
                    <HorizontalFrame>
                        <SubHeadline1>{t("contactForm.subTitle1")}</SubHeadline1>
                        <SubHeadline2>{t("contactForm.subTitle2")}</SubHeadline2>

                        <ShadowContainer>
                            {
                                company === "" ? null : 
                                <TextInputPlaceholderRow>
                                    <InfoText>{t("contactForm.company") + ": "}</InfoText>
                                    <TextInputPlaceholder>{company}</TextInputPlaceholder>
                                </TextInputPlaceholderRow>
                            }
                            <TextInput
                                name = "name"
                                type = "text"
                                placeholder = {String(t("contactForm.name")) + " *"}
                                empty = {name ===  ""}
                                value = {name}
                                onChange = {handleInput}
                                error = {showError && missingFields.includes("name")}
                            />
                            <TextInput
                                name = "email"
                                type = "text"
                                placeholder = {String(t("contactForm.email")) + " *"}
                                empty = {email ===  ""}
                                value = {email}
                                onChange = {handleInput}
                                error = {showError && (missingFields.includes("email") || mailError)}
                            />
                            <ErrorMessageFrame show = {showError && (missingFields.includes("email") || mailError)}>
                                <ErrorIcon>error</ErrorIcon>
                                <ErrorText>{t("contactForm.errorMail")}</ErrorText>
                            </ErrorMessageFrame>
                            <TextInput
                                name = "phone"
                                type = "text"
                                placeholder = {String(t("contactForm.phone"))}
                                empty = {phone ===  ""}
                                value = {phone}
                                onChange = {handleInput}
                                error = {showError && phoneError && phone !== ""}
                            />
                            <ErrorMessageFrame show = {showError && phoneError && phone !== ""}>
                                <ErrorIcon>error</ErrorIcon>
                                <ErrorText>{t("contactForm.errorPhone")}</ErrorText>
                            </ErrorMessageFrame>
                            <TextArea
                                name = "message"
                                rows = {7}
                                placeholder = {String(t("contactForm.yourMessage")) + " *"}
                                empty = {message ===  ""}
                                value = {message}
                                onChange = {handleInput}
                                error = {showError && missingFields.includes("message")}
                            />
                            <BottomRow>
                                <Button
                                    onClick = {handleSubmit}
                                >{t("contactForm.submit")}</Button>
                                <InfoText error = {showError && missingFields.length > 0}>{t("contactForm.mandatoryField")}</InfoText>
                            </BottomRow>
                        </ShadowContainer>


                    </HorizontalFrame>
                    <InfoText padding error = {pageResponse === "error"} hide = {pageResponse === "none"}>
                        {pageResponse === "error" ? t("contactForm.error") : ""}
                        {pageResponse === "success" ? t("contactForm.success") : ""}
                    </InfoText>
                </MainFrame>

            </LargeViewContainer>
        </BackgroundPrimary>
    )


    function handleSubmit(): void {
        if(checkFormCompleteness()) {
            putContactForm(company, name, email, message, phone === "" ? undefined : phone).then(
                (success: boolean) => {
                    if(success) {
                        setName("")
                        setCompany("")
                        setEmail("")
                        setPhone("")
                        setMessage("")
                        setPageResponse("success")
                    }
                })
                .catch(
                    () => {
                        setPageResponse("error")
                    }
            )
        } else {
            setShowError(true)
        }
    }

    function checkFormCompleteness(): boolean {

        const completenessCondition = (company !== "" && name !== "" && email !== "" && message !== "")

        if(completenessCondition) {
            const mailCorrect = verifyMail(email)
            const numberCorrect = verifyPhoneNumber(phone) || phone === ""
            if(mailCorrect && numberCorrect) {
                return true
            } 
            else {
                setMailError(!mailCorrect)
                setPhoneError(!numberCorrect)
                return false
            }
        } else {
            showMissingFields()
            setShowError(true)
            return false
        }

    }

    function showMissingFields(): void {
            
        const localMissingFields: string[] = []

        if(name === "") {
            localMissingFields.push("name")
        }
        if(email === "") {
            localMissingFields.push("email")
        }
        if(message === "") {
            localMissingFields.push("message")
        }

        setMissingFields(localMissingFields)

    }

    function handleInput(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {

        const target = event.target
        const value = target.value
        const name = target.name

        switch(name) {
            case "name":
                if(value == " ") {
                    setName("")
                }
                else {
                    setName(value)
                }
                break;
            case "email":
                if(value == " ") {
                    setEmail("")
                }
                else {
                    setEmail(value)
                    setMailError(!verifyMail(value))
                }
                break;
            case "phone":
                if(value == " ") {
                    setPhone("")
                }
                else {
                    setPhone(value)
                    setPhoneError(!verifyPhoneNumber(value))
                }
                break;
            case "message":
                if(value == " ") {
                    setMessage("")
                }
                else {
                    setMessage(value)
                }
                break;
        }


        const localMissingFields = deepCopy(missingFields)
        
        if(localMissingFields.includes(name)) {
            localMissingFields.splice(localMissingFields.indexOf(name), 1)
            setMissingFields(localMissingFields)
        }

    }

    function getContractData(clientId: number): void {
        postContractGet(clientId)
            .then((response) => {
                
                setEmail(response.contract.contact.email)
                setName((response.contract.contact.preName + " " + response.contract.contact.lastName) !== " " ? response.contract.contact.preName + " " + response.contract.contact.lastName : "")
                setCompany(response.contract.company.company)

            })
            .catch((error: string) => {
                console.log(error)
            })
    }

    function postContractGet(clientId: number): Promise<ContractResponse> {

        return new Promise<ContractResponse>((resolve, reject) => {
                
            const request: ContractGetRequest = {
                clientId: clientId,
            }

            context.restAppService.request_post("contract/get", context.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const result: ContractResponse = JSON.parse(data) as ContractResponse
                    resolve(result)
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text)
                }
            )
            
        })
    }

    function putContactForm(
        company: string, 
        name: string, 
        mail: string, 
        message: string, 
        phone?: string
        ): Promise<boolean> {

        return new Promise<boolean> (
            (resolve, reject) => {

                const request: ContactFormRequest = {
                    company: company,
                    name: name,
                    mail: mail,
                    phone: phone,
                    message: message,
                }

                context.restAppService.request_put('form/contact', context.user.getToken(), 'text/json', JSON.stringify(request),
                    (): void => {
                        resolve(true)
                    },
                    (status, text): void => {
                        console.log('failure ' + status + '/' + text)
                        reject(status)
                    } 
                )

            }
        )
      
    }

}



export default withTranslation()(ContactFormPage)