import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import {
    Container,
    Title,
    Description,
    HeaderContainer,
    CopyContainer,
} from "./components";
import { CardButton, CardButtonContainer } from "../Button";
import Space from "../../containers/Space";
import { CopyIcon, DeleteIcon } from "../QuestionnaireTable/ActionIcon";

type Props = {
    title: string;
    descriptions: string;
    editable: boolean;
    entryId: number;
    editHandler: () => void;
    viewHandler: () => void;
    copyHandler: (entryId: number) => void;
    deleteHandler: (entryId: number) => void;
} & WithTranslation;

const OverviewCard = (props: Props) => {
    const { t } = props;
    const theme = useTheme();
    return (
        <>
            <Container theme={theme}>
                <HeaderContainer>
                    <Title>{props.title}</Title>
                    <Description>{props.descriptions}</Description>
                    <CopyContainer>
                        <Space />
                        <CopyIcon onClick={ () => { props.copyHandler(props.entryId) }} />
                        <DeleteIcon onClick= { () => { props.deleteHandler(props.entryId) }} />
                    </CopyContainer>
                </HeaderContainer>
                <CardButtonContainer>
                    <CardButton onClick={() => props.viewHandler()}>{t("overview.view")}</CardButton>
                    {props.editable ?
                        <CardButton onClick={() => props.viewHandler()}></CardButton>
                        :
                        <CardButton onClick={() => props.copyHandler(props.entryId)}>{t("overview.copy")}</CardButton>
                    }
                    
                </CardButtonContainer>
            </Container>
        </>
    );
};

export default withTranslation()(OverviewCard);
