import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    Condition,
    EntryType,
    QuestionnaireCopyRequest,
    QuestionnaireCopyResponse,
    QuestionnaireEntryIdRequest,
    QuestionnaireEntryIdResponse,
    QuestionnaireSaveRequest,
    QuestionnaireSaveResponse,
    QuestionEntry,
    Prompts,
    InfoPromptsUpdateRequest,
} from "../../communication/interface";
import {
    GridTable,
    TableElement,
    Container,
    ActionContainer,
    TitleContainer,
    Title,
    EmptyPlaceholder,
} from "./components";
import moveElementInArray from "../../helpers/moveElementInArray";
import QuestionnaireEditBox from "../QuestionnaireEditBox/QuestionnaireEditBox";
import { AppContext } from "../../AppContext";
import {
    deleteQuestionEntryById,
    updateQuestionEntryById,
} from "../../helpers/questionnaireHelpers";
import { Modal, ModalContainer } from "../baseStyles";
import {
    DeleteIcon,
    DownArrow,
    EditIcon,
    UpArrow,
    PlusIcon,
} from "./ActionIcon";
import DeletePromptModal from "../DeletePromptModal/DeletePromptModal";
import { PermissionList } from "../../communication/permissions";
import EditQuestionnaireModal from "../EditQuestionnaireModal/EditQuestionnaireModal";
import Space from "../../containers/Space";
import HeaderInfo from "../EditQuestionnaireModal/HeaderInfo";

type Props = {
    questionnaire: QuestionEntry;
    info: Prompts;
    updateQuestionnaire: (questionnaire: QuestionEntry) => void;
} & WithTranslation;

type State = {
    showEditBox: boolean;
    showEditModal: boolean;
    showCreateModal: boolean;
    showDeletePrompt: boolean;
    showEditQuestionnaire: boolean;
    selectedEntry: QuestionEntry | null;
    createValues: {
        index: number;
        parentIndex: number;
    } | null;
};

// local helper function to convert conditions
const stringifyCondition = (condition: Condition) => {
    const result = `${condition.LValue} ${condition.Cond} ${condition.RValue}` 
    return result;
};

// local helper function to correct sequence fields in questionnaires
const correctSequenceValues = (questionEntry: QuestionEntry): QuestionEntry => {

    if (!questionEntry.entries) return questionEntry;

    const copiedEntries = questionEntry.entries.map((entry, index) => {
        entry = correctSequenceValues(entry);
        return { ...entry, sequence: index + 1 };
    });

    return { ...questionEntry, entries: copiedEntries };

};

class QuestionnaireEditView extends Component<Props, State> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            showEditBox: false,
            showEditModal: false,
            selectedEntry: null,
            showCreateModal: false,
            showDeletePrompt: false,
            showEditQuestionnaire: false,
            createValues: null,
        };
    }

    private openEditBox = (entry: QuestionEntry) => {
        if (!this.canEdit()) return null;
        this.setState({
            selectedEntry: entry,
            showEditModal: true,
        });
    };

    private arrayMoveFunction = async (fromIndex: number, toIndex: number, parentIndex: number) => {

        let array: QuestionEntry[];
        if (!this.canEdit()) return null;

        const { updateQuestionnaire, questionnaire } = this.props;

        let updateData: QuestionEntry;
        if (!questionnaire.entries || fromIndex < 0 || toIndex < 0 || parentIndex < 0) {
            return;
        }

        // handle case with no subentries
        if (parentIndex === 0) {
            array = [...questionnaire.entries];
            if (toIndex > array.length) return;
            moveElementInArray(array, fromIndex, toIndex);
            const correctedQuestionnaire = correctSequenceValues({ ...questionnaire, entries: array });
            updateData = correctedQuestionnaire;
        } 
        else if (parentIndex > 0) {

            if (!questionnaire.entries[parentIndex].entries) return;
            //@ts-expect-error this is defined because of the check above but ts cannot seem to figure it out
            array = [...questionnaire.entries[parentIndex].entries];
            moveElementInArray(array, fromIndex, toIndex);

            const copiedQuestonaire = { ...questionnaire };
            //@ts-expect-error again... this is defined
            copiedQuestonaire.entries[parentIndex].entries = array;
            updateData = copiedQuestonaire;
        }
        // @ts-expect-error this error is exactly what the next line checks
        if (!updateData) return;

        try {
            const result = await this.saveQuestionnaire(updateData);
            if (!result) throw new Error("SERVER RETURNED UNDEFINED");

            updateQuestionnaire(result.questionnaire);
        } catch (error) {
            console.error(error);
        }

    };

    private onInfoSave = async (updateData: QuestionEntry, headerInfo: HeaderInfo) => {

        if (!this.canEdit()) return null;

        updateData.text = headerInfo.title;
        updateData.description = headerInfo.description;

        const prompts: Prompts = { ...headerInfo };
        await this.onPromptsSave(prompts);

        await this.onEditSave(updateData);

    }

    private onPromptsSave = async (prompts: Prompts) => {

        if (!this.canEdit()) return null;

        // update standard prompts
        await this.putInfoPromptsUpdate(this.props.questionnaire.clientId, this.props.questionnaire.entryId, prompts);

    }

    private onEditSave = async (updateData: QuestionEntry) => {

        if (!this.canEdit()) return null;

        try {

            // find and update question entry
            const updatedQuestionnaire = updateQuestionEntryById(this.props.questionnaire, updateData);
            if (!updatedQuestionnaire || !updatedQuestionnaire.entries) return;

            // save new questionniare
            await this.saveQuestionnaire(correctSequenceValues(updatedQuestionnaire));

            // close edit box
            this.setState({ selectedEntry: null, showEditQuestionnaire: false, showEditModal: false })

            // and update view
            this.props.updateQuestionnaire(updatedQuestionnaire);


        } 
        catch (error) {
            console.error(error);
        }

    };

    private onCreateSave = async (newQuestionEntry: QuestionEntry, index: number, parentIndex?: number, isGroup = false) => {

        if (!this.canEdit()) return null;

        try {

            const questionnaire = this.props.questionnaire;
            const entries = questionnaire.entries;

            // get new entryId for new entry
            const newEntryId = await this.getNewEntryId(this.props.questionnaire.clientId);

            // set new id
            newQuestionEntry.parentId = questionnaire.entryId;
            newQuestionEntry.entryId = newEntryId.entryId;

            if (!entries) return;

            // handle case for new subQuestion
            if (parentIndex && parentIndex !== 0 && entries[parentIndex].entries) {
                //@ts-expect-error this is checked the line above
                entries[parentIndex].entries.splice(index + 1, 0, { ...newQuestionEntry });
            } 

            // handle case for new group question
            else if (isGroup) {

                const newSubEntryId = await this.getNewEntryId(this.props.questionnaire.clientId);
                const newSubQuestion: QuestionEntry = {
                    clientId: newQuestionEntry.clientId,
                    entryId: newSubEntryId.entryId,
                    parentId: newEntryId.entryId,
                    sequence: 0,
                    text: "",
                    description: "",
                    entryType: EntryType.GRADE6,
                    condition: {
                        LValue: "",
                        Cond: "*",
                        RValue: "",
                    },
                };
                entries.splice(index + 1, 0, { ...newQuestionEntry, entryType: EntryType.GROUP, entries: [newSubQuestion], });

            }

            // as default: handle as new question
            else {
                entries.splice(index + 1, 0, {...newQuestionEntry });
            }

            // save new questionnaire
            const result = await this.saveQuestionnaire(correctSequenceValues( questionnaire ));

            //TODO: after requests have been fixed: move this below updateQuestionnaire
            if (isGroup) {
                this.setState({
                    showEditModal: true,
                    showCreateModal: false,
                    // @ts-expect-error TODO
                    selectedEntry: entries[index + 1].entries[0],
                });
            }
            else {
                this.setState({ selectedEntry: null, showCreateModal: false })
            }

            if (!result?.questionnaire)
                throw new Error("UPDATE FAILED, SAVEQUESTIONNAIRE RETURNED UNDEFINED");

            this.props.updateQuestionnaire(result.questionnaire);

        } catch (error) {
            console.error(error);
        }
    };

    private deleteQuestion = async (entryId: number) => {

        if (!this.canEdit()) return null;

        const updatedQuestionnaire = deleteQuestionEntryById(this.props.questionnaire, entryId);
        if (!updatedQuestionnaire) return;

        const result = await this.saveQuestionnaire(correctSequenceValues( updatedQuestionnaire ) );

        // close dialog box
        this.setState({ showDeletePrompt: false, selectedEntry: null, })

        if (!result?.questionnaire) {
            throw new Error("UPDATE FAILED, SAVEQUESTIONNAIRE RETURNED UNDEFINED");
        }

        // update view
        this.props.updateQuestionnaire(result.questionnaire);

    };

    private createQuestion = (index: number, parentIndex = 0) => {
        const newQuestion: QuestionEntry = {
            clientId: this.props.questionnaire.clientId,
            entryId: 0,
            parentId: 0,
            sequence: 0,
            condition: { LValue: "", Cond: "*", RValue: "", },
            text: "",
            description: "",
            entryType: EntryType.GRADE6,
        };

        this.setState({
            showCreateModal: true,
            selectedEntry: newQuestion,
            createValues: {
                index,
                parentIndex,
            },
        });
    };

    // PERMISSIONS

    private canEdit = (): boolean => {
        const permissions: PermissionList = this.context.user.getPermissionList();

        let hasPermission = false;
        permissions.forEach((permission) => {
            if (
                permission.permissionType === "editQuestionnaire" &&
                permission.permissionValue === ".*"
            )
                hasPermission = true;
        });
        return hasPermission;
    };

    // REQUESTS
    private getQuestionnaireCopy = async (clientId: number, entryId: number) => {
        return new Promise<QuestionnaireCopyResponse>((resolve, reject) => {

            // build request
            const request: QuestionnaireCopyRequest = {
                clientId,
                entryId,
            };

            // post request
            this.context.restAppService.request_post("questionnaire/copy", this.context.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const response = JSON.parse(data) as QuestionnaireCopyResponse;
                    resolve(response);
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }
            );

        });
    };

    private saveQuestionnaireRequest = async (questionnaire: QuestionEntry) => {

        return new Promise<QuestionnaireSaveResponse>((resolve, reject) => {

            // build request
            const request: QuestionnaireSaveRequest = {
                clientId: questionnaire.clientId,
                questionnaire: questionnaire,
            };

            // post request
            this.context.restAppService.request_post("questionnaire/save", this.context.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const response = JSON.parse(data) as QuestionnaireSaveResponse;
                    resolve(response);
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }
            );

        });

    };

    private saveQuestionnaire = async (questionnaire: QuestionEntry) => {

        try {
            return await this.saveQuestionnaireRequest(questionnaire);
        } catch (error) {
            console.error(error);
        }

    };

    private getNewEntryId = async (clientId: number) => {
        return new Promise<QuestionnaireEntryIdResponse>((resolve, reject) => {

            // build request
            const request: QuestionnaireEntryIdRequest = { clientId };

            // post request
            this.context.restAppService.request_post("questionnaire/entryId", this.context.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const response = JSON.parse(data) as QuestionnaireEntryIdResponse;
                    resolve(response);
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }
            );
            
        });
    };

    private putInfoPromptsUpdate = async(clientId: number, entryId: number, prompts: Prompts): Promise<void> => {

        return new Promise<void>( (resolve, reject) => {

            // build request
            const request: InfoPromptsUpdateRequest = {
                clientId,
                entryId,
                prompts
            }

            // post request
            this.context.restAppService.request_put("info/promptsUpdate", this.context.user.getToken(), "text/json", JSON.stringify(request),
                (): void => {
                    resolve();
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }
            );

        });

    }

    // render functions
    private renderTableEntry = (entry: QuestionEntry, openEditBoxFunction: (entry: QuestionEntry, index: number, parentIndex: number) => void,
    index: number, moveFunction: (fromIndex: number, toIndex: number, parentIndex: number ) => void, isFirst = false, isLast = false, parentIndex = 0) => {
        
        const { t } = this.props;
        return(
            <React.Fragment key={entry.entryId + "ID"} >
                <TableElement>{entry.entryId}</TableElement>
                <ActionContainer>
                    <UpArrow onClick={ isFirst ? () => null : () => moveFunction(index, index - 1, parentIndex) } />
                    <DownArrow onClick={ isLast ? () => null : () => moveFunction(index, index + 1, parentIndex) } />
                </ActionContainer>
                <TableElement>{entry.condition.Cond !== "*" ? stringifyCondition(entry.condition) : "---"}</TableElement>
                <TableElement key={entry.entryId + "TEXT"} isText onClick={() => openEditBoxFunction(entry, index, parentIndex)} >
                    {entry.text === "" ? 
                        <EmptyPlaceholder>{t("table.emptyQuestion")}</EmptyPlaceholder> 
                        : entry.text
                    }
                </TableElement>
                <TableElement key={entry.entryId + "ENTRYTYPE"}>{t("questionType." + entry.entryType)}</TableElement>
                <TableElement>
                    <ActionContainer>
                        <PlusIcon onClick={() => this.createQuestion(index, parentIndex)} />
                        <EditIcon onClick={() => openEditBoxFunction(entry, index, parentIndex)} />
                        <DeleteIcon onClick={() => this.setState({ showDeletePrompt: true, selectedEntry: entry }) } />
                    </ActionContainer>
                </TableElement>
            </ React.Fragment>
        )
    };

    private renderSubTableWithEntries = (entry: QuestionEntry, openEditBoxFunction: (entry: QuestionEntry, index: number, parentIndex: number) => void,
                                            index: number, groupLabel: EntryType, parentIndex: number, moveFunction: (fromIndex: number, toIndex: number, parentIndex: number) => void) => {

        if (!entry.entries) return undefined;

        const { t } = this.props;
        return (
            <React.Fragment key={entry.entryId + "ID"}>
                <TableElement>{entry.entryId}</TableElement>
                <TableElement overlined>
                    <ActionContainer>
                        <UpArrow
                            onClick={() => moveFunction(parentIndex, parentIndex - 1, 0)}
                        />
                        <DownArrow
                            onClick={() => moveFunction(parentIndex, parentIndex + 1, 0)}
                        />
                    </ActionContainer>
                </TableElement>
                <TableElement overlined>
                    {stringifyCondition(entry.condition)}
                </TableElement>
                <GridTable hasSubEntries isSubTable>
                    <TableElement overlined>{t("table.id")}</TableElement>
                    <TableElement overlined />
                    <TableElement overlined>{t("table.condition")}</TableElement>
                    <TableElement overlined isText>{t("table.question")}</TableElement>
                    <TableElement overlined>{t("table.entryType")}</TableElement>
                    <TableElement overlined>{t("table.actions")}</TableElement>
                    {entry.entries.map((subEntry, index) => {
                        return this.renderTableEntry(
                            subEntry,
                            () => openEditBoxFunction(subEntry, index, parentIndex),
                            index,
                            moveFunction,
                            entry.entries ? index === 0 : false,
                            entry.entries ? index === entry.entries.length - 1 : false,
                            parentIndex
                        );
                    })}
                </GridTable>
                <TableElement overlined>{groupLabel}</TableElement>
                <TableElement overlined>
                    <ActionContainer>
                        <PlusIcon title = {String(t("questionnaire.addBeneath"))} onClick={() => this.createQuestion(index, parentIndex)} />
                        <EditIcon onClick={() => openEditBoxFunction(entry, index, parentIndex)} />
                        <DeleteIcon onClick={() => this.setState({ showDeletePrompt: true, selectedEntry: entry }) } />
                    </ActionContainer>
                </TableElement>
            </React.Fragment>
        );

    };

    render() {
        const { t, questionnaire } = this.props;
        const {
            showEditModal,
            selectedEntry,
            showCreateModal,
            showDeletePrompt,
            showEditQuestionnaire,
            createValues,
        } = this.state;

        if (!questionnaire.entries) return undefined;

        return (
            <Container>

                <TitleContainer>
                    <Title>{questionnaire.text}</Title>
                    <Space />
                    <EditIcon title = {String(t("questionnaire.edit"))} onClick={ () => this.setState({ showEditQuestionnaire: true, selectedEntry: questionnaire }) } />
                </TitleContainer>

                <GridTable hasSubEntries>
                    <TableElement isHeader>{t("table.id")}</TableElement>
                    <TableElement isHeader />
                    <TableElement isHeader>{t("table.condition")}</TableElement>
                    <TableElement isHeader isText>
                        {t("table.question")}
                    </TableElement>
                    <TableElement isHeader>{t("table.entryType")}</TableElement>
                    <TableElement isHeader>{t("table.actions")}</TableElement>
                    {questionnaire.entries.map((entry, index) => {
                        if (entry.entries) {
                            // indent questions
                            return this.renderSubTableWithEntries(
                                entry,
                                this.openEditBox,
                                index,
                                entry.entryType,
                                index,
                                this.arrayMoveFunction
                            );
                        }

                        return this.renderTableEntry(
                            entry,
                            this.openEditBox,
                            index,
                            this.arrayMoveFunction,
                            index === 0,
                            questionnaire.entries
                                ? index === questionnaire.entries.length - 1
                                : false
                        );
                    })}
                </GridTable>

                {selectedEntry && (
                    <Modal show={showEditModal}>
                        <ModalContainer>
                            <QuestionnaireEditBox
                                key={selectedEntry.entryId}
                                question={selectedEntry}
                                onSave={this.onEditSave}
                                onCancel={() =>
                                    this.setState({ selectedEntry: null, showEditModal: false })
                                }
                                isCreateBox={false}
                                index={0}
                                parentIndex={0}
                            />
                        </ModalContainer>
                    </Modal>
                )}

                {selectedEntry && createValues && (
                    <Modal show={showCreateModal}>
                        <ModalContainer>
                            <QuestionnaireEditBox
                                key={selectedEntry.entryId}
                                question={selectedEntry}
                                // @ts-expect-error TODO
                                onSave={this.onCreateSave}
                                onCancel={() =>
                                    this.setState({ selectedEntry: null, showCreateModal: false })
                                }
                                isCreateBox={true}
                                index={createValues.index}
                                parentIndex={createValues.parentIndex}
                            />
                        </ModalContainer>
                    </Modal>
                )}

                {selectedEntry && (
                    <Modal show={showDeletePrompt}>
                        <ModalContainer>
                            <DeletePromptModal
                                question={t("questionnaire.delete")}
                                onCancel={() => this.setState({ showDeletePrompt: false, selectedEntry: null, }) }
                                onDelete={() => this.deleteQuestion(selectedEntry.entryId)}
                            />
                        </ModalContainer>
                    </Modal>
                )}
                
                {selectedEntry &&
                    <Modal show={showEditQuestionnaire}>
                        <ModalContainer>
                            <EditQuestionnaireModal
                                question={selectedEntry}
                                info={ { 
                                    // TODO: change this on Backend instead
                                    title: selectedEntry.text === '<title>' ? 'Titel' : selectedEntry.text, 
                                    description: selectedEntry.description, 
                                    greeting: this.props.info.greeting, 
                                    nothingSaid: this.props.info.nothingSaid, 
                                    nothingHeard: this.props.info.nothingHeard, 
                                    goodBye: this.props.info.goodBye } }
                                onCancel={() => this.setState({ showEditQuestionnaire: false, selectedEntry: null, }) }
                                onSave={ this.onInfoSave }
                            />
                        </ModalContainer>
                    </Modal>
                }
                
            </Container>
        );
    }
}

export default withTranslation()(QuestionnaireEditView);
