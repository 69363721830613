import React from "react";
import styled from "styled-components";
import {ReactComponent as Logout} from '../../assets/icons/Logout.svg';
import {ReactComponent as ArrowUp} from '../../assets/icons/up arrow.svg';
import {ReactComponent as ArrowDown} from '../../assets/icons/down arrow.svg';
import {ReactComponent as ArrowBack} from '../../assets/icons/left arrow.svg';
import {ReactComponent as Edit} from '../../assets/icons/Edit.svg';
import {ReactComponent as Trash} from '../../assets/icons/Trash.svg';
import {ReactComponent as Plus} from '../../assets/icons/plus.svg';
import {ReactComponent as PlusDark} from '../../assets/icons/plus2.svg';
import {ReactComponent as UpChevron} from '../../assets/icons/chevron-up.svg';
import {ReactComponent as DownChevron} from '../../assets/icons/chevron-down.svg';
import {ReactComponent as Copy} from '../../assets/icons/copy-svgrepo-com.svg'

type Props = {
  onClick: () => void;
  title?: string;
};

const ActionIcon = styled.button<{minimize?: boolean}>`
  background: none;
  border: none;
  padding: 0;
  color: ${props => props.theme.colors.icon};
  width: ${props => props.minimize? 15 : 35}px;
  height: 25px;
  cursor: pointer;
  :hover {
    color: ${props => props.theme.colors.main}
  }
`;

const UpArrow = (props: Props) => {
  const { onClick } = props;

  return <ActionIcon minimize onClick={onClick}>
    <ArrowUp />
    </ActionIcon>;
};
const DownArrow = (props: Props) => {
  const { onClick } = props;

  return <ActionIcon minimize onClick={onClick}>
    <ArrowDown />
    </ActionIcon>;
};
const BackArrow = (props: Props) => {
  const { onClick } = props;

  return <ActionIcon onClick={onClick}>
    <ArrowBack />
    </ActionIcon>;
};

const EditIcon = (props: Props) => {
  const { onClick } = props;

  return <ActionIcon onClick={onClick}>
    <Edit title = {props.title} />
    </ActionIcon>;
  };
  const DeleteIcon = (props: Props) => {
    const { onClick } = props;
  
    return <ActionIcon onClick={onClick}>
      <Trash />
      </ActionIcon>;
  };

  const PlusIcon = (props: Props) => {
    const { onClick } = props;
  
    return <ActionIcon title={props.title}  onClick={onClick}>
      <Plus/>
      </ActionIcon>;
  };

  const PlusIconDark = (props: Props) => {
    const { onClick } = props;
  
    return <ActionIcon onClick={onClick}>
      <PlusDark onClick={onClick}/>
      </ActionIcon>;
  };

  const CopyIcon = (props: Props) => {
    const { onClick } = props;
  
    return <ActionIcon onClick={onClick}>
      <Copy />
      </ActionIcon>;
  };

  const ChevronUp = (props: Props) => {
    const { onClick } = props;
  
    return <ActionIcon onClick={onClick}>
      <UpChevron onClick={onClick}/>
      </ActionIcon>;
  };
  const ChevronDown = (props: Props) => {
    const { onClick } = props;
  
    return <ActionIcon onClick={onClick}>
      <DownChevron onClick={onClick}/>
      </ActionIcon>;
  };
  const LogoutIcon = (props: Props) => {
    const { onClick } = props;
  
    return <ActionIcon onClick={onClick}>
      <Logout />
      </ActionIcon>;
  }
export {
  UpArrow,
  DownArrow,
  EditIcon,
  DeleteIcon,
  PlusIcon,
  LogoutIcon,
  PlusIconDark,
  BackArrow,
  ChevronUp,
  ChevronDown,
  CopyIcon
}
