import React, { GetDerivedStateFromProps } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { AppContext } from "../../AppContext";
import {
    QuestionEntry,
    EntryType,
    CatalogEntry,
    CatalogCatalogEntriesResponse,
    CatalogCatalogEntriesRequest,
} from "../../communication/interface";
import { Title } from "../baseStyles";
import Button from "../Button";
import ComboBox from "../ComboBox/ComboBox";
import DropDown from "../DropDown/DropDown";
import LabeledCheckbox from "../LabeledCheckbox/LabeledCheckbox";
import LabeledInput from "../LabeledInput/LabeledInput";
import { Container, ConditionContainer, ButtonContainer } from "./components";

const options = [">", ">=", "<", "<=", "=="];
const validEntryTypes = [
    EntryType.GRADE5,
    EntryType.GRADE6,
    EntryType.COMMENT,
    EntryType.NPS,
    EntryType.YESNO,
];

type Props = {
    question: QuestionEntry;
    onSave: (questionnaire: QuestionEntry, index?: number, parentIndex?: number, checked?: boolean) => void;
    onCancel: () => void;
    isCreateBox: boolean;
    index: number;
    parentIndex: number;
} & WithTranslation;

type State = {
    questions?: CatalogEntry[];
    questionEntry: QuestionEntry;
    checked: boolean;
};

class QuestionnaireEditBox extends React.Component<Props, State> {
    
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    static getDerivedStateFromProps:GetDerivedStateFromProps<Props, State> = (props: Props, state: State): Partial<State> | null => {
        if (props.question.entryId !== state.questionEntry.entryId)
            return {
                questionEntry: props.question,
            };
        else return null;
    }

    constructor(props: Props) {
        super(props);

        this.state = {
            questionEntry: this.props.question,
            checked: false,
        };
    }

    componentDidMount = async () => {
        const result = await this.getQuestions();
        this.setState({ questions: result.catalogEntries });
    };

    private handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
            this.props.onSave(this.state.questionEntry);
        }
    };
    private handleQuestionChange = (input: string) => {
        this.setState({
            questionEntry: { ...this.state.questionEntry, text: input },
        });
    };

    private handleQuestionSelection = (
        catalogQuestion: CatalogEntry,
    ) => {
        this.setState({
            questionEntry: {
                ...this.state.questionEntry,
                text: catalogQuestion.text,
                entryType: catalogQuestion.entryType,
            },
        });
    };

    // REQUESTS
    private getQuestions = (): Promise<CatalogCatalogEntriesResponse> => {

        return new Promise<CatalogCatalogEntriesResponse>((resolve, reject) => {

            // build request
            const request: CatalogCatalogEntriesRequest = {
                clientId: this.props.question.clientId,
            };

            // post request
            this.context.restAppService.request_post( "catalog/catalogEntries", this.context.user.getToken(), "text/json", JSON.stringify(request),
                (data: string): void => {
                    const response = JSON.parse(data) as CatalogCatalogEntriesResponse;
                    resolve(response);
                },
                (status: number, text: string): void => {
                    reject("failure " + status + "/" + text);
                }
            );

        });
    };

    render() {
        const {
            questionEntry: { text, condition, entryType, entryId },
            questions,
            checked,
        } = this.state;
        const { t, onSave, onCancel, isCreateBox, index, parentIndex } = this.props;
        return (
            <Container>
                <Title>
                    {isCreateBox
                        ? t("questionnaire.create.title")
                        : t("questionnaire.edit.title", { number: entryId })}
                </Title>
                {isCreateBox ? (
                    <LabeledCheckbox
                        label = {t("questionnaire.edit.group")}
                        checked={checked}
                        onChangeHandler={() => this.setState({ checked: !checked })}
                        onKeyPress={(): void => { return; }}
                    />
                ) : null}
                {!checked ? (
                    <ComboBox
                        label={t("questionnaire.edit.question")}
                        entryId={entryId}
                        value={text}
                        questions={questions ? questions : []}
                        onChange={(input: string) => this.handleQuestionChange(input)}
                        updateFunction={(e) =>
                            this.handleQuestionSelection(e)
                        }
                        onKeyPress={this.handleKeyPress}
                        maxResults={10}
                    />
                ) : null}
                <ConditionContainer>
                    <LabeledInput
                        label={t("questionnaire.edit.condition.label.lValue")}
                        value={condition.LValue}
                        errorMessage=""
                        onChangeHandler={(input) =>
                            this.setState({
                                questionEntry: {
                                    ...this.state.questionEntry,
                                    condition: { ...condition, LValue: input },
                                },
                            })
                        }
                        onKeyPress={(): void => { return; }}
                    />
                    <DropDown
                        value={condition.Cond}
                        options={options}
                        onChange={(option: string) =>
                            this.setState({
                                questionEntry: {
                                    ...this.state.questionEntry,
                                    condition: { ...condition, Cond: option },
                                },
                            })
                        }
                    />
                    <LabeledInput
                        label = {t("questionnaires.value")}
                        value={condition.RValue}
                        errorMessage=""
                        onChangeHandler={(input) =>
                            this.setState({
                                questionEntry: {
                                    ...this.state.questionEntry,
                                    condition: { ...condition, RValue: input },
                                },
                            })
                        }
                        onKeyPress={(): void => { return; }}
                    />
                </ConditionContainer>
                {!checked ? (
                    <DropDown
                        value = {entryType}
                        options = {validEntryTypes}
                        onChange = {(option: string) =>
                            this.setState({
                                questionEntry: {
                                    ...this.state.questionEntry,
                                    entryType: option as EntryType,
                                },
                            })
                        }
                        translate = "questionType."
                    />
                ) : undefined}
                <ButtonContainer>
                    <Button secondary onClick={onCancel}>{t("button.dismiss")}</Button>
                    <Button
                        disabled={this.state.questionEntry.text === "" && !checked}
                        onClick={() =>
                            this.state.questionEntry.text === "" && !checked
                                ? null
                                : onSave(this.state.questionEntry, index, parentIndex, checked)
                        }
                    >
                        {this.props.isCreateBox ? t("button.create") : t("button.save")}
                    </Button>
                </ButtonContainer>
            </Container>
        );
    }
}

export default withTranslation()(QuestionnaireEditBox);
