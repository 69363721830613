import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Campaign } from "../../communication/interface";
import { Title } from "../baseStyles";
import { CardButtonContainer, CardButton } from "../Button";
import {
  Container,
  DateDisplay,
  Label,
  ContentContainer,
  DateContainer,
  DateLabel,
} from "./components";

type Props = {
  campaign: Campaign;
  campaignText: string;
  onDelete: () => void;
  onEdit: () => void;
} & WithTranslation;

const CampaignCard = (props: Props) => {
  const { campaign, onDelete, onEdit, t } = props;
  return (
    <Container>
      <ContentContainer>
        <Title>{props.campaignText}</Title>
        <DateContainer>
          <DateLabel>{t("campaign.card.startDate")}:</DateLabel>
          <DateDisplay>
            {campaign.startDateDate.toLocaleString("de-de")}
          </DateDisplay>
        </DateContainer>
        <DateContainer>
          <DateLabel>{t("campaign.card.endDate")}:</DateLabel>
          <DateDisplay>{campaign.endDateDate.toLocaleString("de-de")}</DateDisplay>
        </DateContainer>

        <Label>{t('campaign.level')}: {campaign.level}</Label>
      </ContentContainer>
      <CardButtonContainer>
        <CardButton onClick={onEdit}>{t("button.edit")}</CardButton>
        <CardButton onClick={onDelete}>{t("button.delete")}</CardButton>
      </CardButtonContainer>
    </Container>
  );
};

export default withTranslation()(CampaignCard);
